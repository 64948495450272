import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getUserInfo } from '../helpers/auth';

export function CheckAuthorization({ children, shouldNavigate, path, roles }) {
  const navigate = useNavigate();

  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    if (roles.includes(getUserInfo()?.user?.role?.code)) {
      setIsAuthorized(true);
    } else {
      setIsAuthorized(false);
      if (shouldNavigate) {
        navigate(path || '/login');
      }
    }
  }, [shouldNavigate, navigate, roles, path]);

  return <>{isAuthorized ? children : undefined}</>;
}
