import Icon from '../Icon';

function BreadcrumbItem({ option, active, onClick }) {
  return (
    <li className='breadcrumb-item'>
      <button
        type='button'
        className={`${active ? 'bread-crumb-active' : ''}`}
        onClick={() => {
          onClick(option);
        }}
      >
        {option?.label ? option.label : '---'}
      </button>
      <Icon
        className={`${
          active ? 'bread-crumb-icon-active' : ''
        } bread-crumb-icon-right`}
        name='arrow-right'
      />
    </li>
  );
}

export default BreadcrumbItem;
