/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable prettier/prettier */
import '../styles/RatingPointsOverviewFillout.css';

import { useEffect, useState } from 'react';
import { IoIosAdd } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import {
  ApiClient,
  CheckAuthorization,
  CheckIfLoggedIn,
  Input,
  LoadingIndicator,
  Row,
  Table
} from '../components';
import USERROLES from '../constants/UserRoles';

export function AssessmentPointOverview({ setBreadcrumbLinks }) {
  const { addToast } = useToasts();
  const [assessmentpoints, setAssessmentpoints] = useState();
  const [inputTextTitle, setInputTitleText] = useState('');
  const [inputTextDescription, setInputDescriptionText] = useState('');
  const [assessmentCategories, setAssessmentCategories] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    setBreadcrumbLinks([
      {
        id: 1,
        label: 'Beurteilungspunkte anzeigen',
        path: '/assessment_points/overview'
      }
    ]);
    ApiClient.get('assessment_points')
      .then(({ assessment_points }) => {
        setAssessmentCategories([
          ...new Set(
            assessment_points.map(
              assessmentPoint => assessmentPoint.category.name
            )
          )
        ]);
        setAssessmentpoints(
          assessment_points.sort((a, b) =>
            a.category.name
              .toLowerCase()
              .localeCompare(b.category.name.toLowerCase())
              ? -1
              : 1
          )
        );
      })
      .catch(() =>
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
      );
  }, [addToast, setBreadcrumbLinks]);

  return (
    <CheckIfLoggedIn>
      <CheckAuthorization
        roles={[
          USERROLES.ADMIN,
          USERROLES.APPRENTICE_LEADER,
          USERROLES.INSTRUCTOR
        ]}
        shouldNavigate={true}
        path='/403'
      >
        <div className='subjectOverview container'>
          <div className='content'>
            <h1 className='title'>
              Alle Beurteilungspunkte
              <CheckAuthorization
                roles={[
                  USERROLES.ADMIN,
                  USERROLES.APPRENTICE_LEADER,
                  USERROLES.INSTRUCTOR
                ]}
              >
                <IoIosAdd
                  onClick={() => navigate('/assessment_points/create')}
                  className='title-icon'
                />
              </CheckAuthorization>
            </h1>
            <Table leftAlign>
              <Row
                key='thead'
                data={[
                  <Input
                    key='title'
                    type='text'
                    id='title'
                    name='title'
                    placeholder='Titel'
                    value={inputTextTitle}
                    onChange={event => setInputTitleText(event.target.value)}
                  />,
                  <Input
                    key='description'
                    type='text'
                    id='description'
                    name='description'
                    placeholder='Description'
                    value={inputTextDescription}
                    onChange={event =>
                      setInputDescriptionText(event.target.value)
                    }
                  />
                ]}
              />

              {assessmentCategories && assessmentpoints ? (
                <>
                  {assessmentCategories.map(category => (
                    <>
                      <Row
                        key={category}
                        className='category-title-overview'
                        data={[category, '']}
                      />
                      {assessmentpoints
                        .filter(point => point.category.name === category)
                        .filter(point =>
                          point.title
                            .toLowerCase()
                            .includes(inputTextTitle.toLowerCase())
                        )
                        .filter(point =>
                          point.description
                            .toLowerCase()
                            .includes(inputTextDescription.toLowerCase())
                        )
                        .map(assessmentpoint => (
                          <Row
                            key={assessmentpoint.id}
                            data={[
                              assessmentpoint.title,
                              assessmentpoint.description
                            ]}
                          />
                        ))}
                    </>
                  ))}
                </>
              ) : (
                <LoadingIndicator height={25} width={25} mode='darker' />
              )}
            </Table>
          </div>
        </div>
      </CheckAuthorization>
    </CheckIfLoggedIn>
  );
}
