import '../styles/Dropdown.css';

import { useEffect, useState } from 'react';
import { IoIosAdd } from 'react-icons/io';
import { useNavigate, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import {
  ApiClient,
  CheckAuthorization,
  CheckIfLoggedIn,
  LoadingIndicator,
  Row,
  Table
} from '../components';
import USERROLES from '../constants/UserRoles';

export function SubjectsInSemesterOverview({
  setBreadcrumbLinks,
  setHideBreadcrumb
}) {
  const { addToast } = useToasts();
  const [user, setUser] = useState();
  const [subjects, setSubjects] = useState();
  const [semester, setSemester] = useState();
  const { userId, semesterId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setHideBreadcrumb(false);
    Promise.all([
      ApiClient.get(`users/${userId}`),
      ApiClient.get(`users/${userId}/semesters`),
      ApiClient.get(`users/${userId}/semesters/${semesterId}/subjects`)
    ])
      .then(([user, { semesters }, { subjects }]) => {
        const semester = semesters.find(semester => semester.id == semesterId);
        setBreadcrumbLinks([
          {
            id: 1,
            label: `${user?.firstname} ${user?.lastname}`,
            path: `/users/${userId}/semesters`
          },
          {
            id: 2,
            label: 'Alle Semester',
            path: `/users/${userId}/semesters`
          },
          {
            id: 3,
            label: semester?.index,
            path: `/users/${userId}/semesters/${semesterId}/subjects`
          },
          {
            id: 4,
            label: 'Fächer',
            path: `/users/${userId}/semesters/${semesterId}/subjects`
          }
        ]);
        setSemester(semester);
        setSubjects(subjects);
      })
      .catch(() =>
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
      );
  }, [addToast, semesterId, setBreadcrumbLinks, setHideBreadcrumb, userId]);

  useEffect(() => {
    ApiClient.get(`users/${userId}`)
      .then(user => setUser(user))
      .catch(() =>
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
      );
  }, [addToast, userId]);

  return (
    <CheckIfLoggedIn>
      <div className='subjectOverview container'>
        <div className='content'>
          <h1 className='title'>
            {semester
              ? `Fächer für ${user?.firstname} 
              ${user?.lastname} in Semester ${semester.index}`
              : 'Fächer'}
            <CheckAuthorization
              roles={[
                USERROLES.ADMIN,
                USERROLES.APPRENTICE_LEADER,
                USERROLES.INSTRUCTOR,
                USERROLES.APPRENTICE
              ]}
              shouldNavigate={false}
            >
              <IoIosAdd
                onClick={() =>
                  navigate(
                    `/users/${userId}/semesters/${semesterId}/subjects/edit`
                  )
                }
                className='title-icon'
              />
            </CheckAuthorization>
          </h1>
          {subjects ? (
            Array.isArray(subjects) && subjects.length > 0 ? (
              <Table
                leftAlign
                thead={
                  subjects.map(subject => !!subject.average).includes(true)
                    ? ['Fach', 'Durchschnitt']
                    : undefined
                }
              >
                {subjects.map(subject => (
                  <Row
                    key={subject.id}
                    data={[
                      subject.subject?.name,
                      subject.average
                        ? (Math.round(subject.average * 1000) / 1000).toFixed(3)
                        : undefined
                    ]}
                    onClick={() => navigate(`${subject.id}/grades`)}
                  />
                ))}
              </Table>
            ) : (
              <h2>Diesem Semester sind keine Fächer zugewiesen.</h2>
            )
          ) : (
            <LoadingIndicator height={25} width={25} mode='darker' />
          )}
        </div>
      </div>
    </CheckIfLoggedIn>
  );
}
