import UserRoles from '../constants/UserRoles';
import { getUserInfo } from './auth';

export function redirectUserWhenOnClick(
  role,
  latestSemesterId,
  userId,
  navigate
) {
  if (role === UserRoles.APPRENTICE)
    if (getUserInfo()?.user?.role?.code === UserRoles.TRAINING_OFFICER) {
      navigate(`/users/${userId}/semesters/${latestSemesterId}/weekly_reports`);
    } else {
      if (latestSemesterId)
        navigate(`/users/${userId}/semesters/${latestSemesterId}/subjects`);
      else navigate(`/users/${userId}/semesters`);
    }
}
