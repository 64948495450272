import '../styles/LoadingIndicator.css';

export function LoadingIndicator({ mode, width, height, disabled }) {
  return (
    <div
      style={{
        height,
        width,
        minWidth: width,
        backgroundColor: !disabled ? '' : '#e6ecf0'
      }}
      className={`lds-ring ${mode}`}
    >
      <div />
      <div />
      <div />
    </div>
  );
}
