import '../styles/SidePopUp.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { useState } from 'react';
import { DateRange } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';

import USERROLES from '../constants/UserRoles';
import { getUserInfo } from '../helpers/auth';
import { Button } from './Button';
import { CheckAuthorization } from './CheckAuthorization';

export function SidePopUp({
  selectedDepartment,
  handleClose,
  isSidePopUpVisible,
  loading,
  startDate,
  endDate,
  disabled,
  handleSubmit
}) {
  const [range, setRange] = useState([
    {
      startDate: new Date(selectedDepartment?.startDate),
      endDate: new Date(selectedDepartment?.endDate),
      key: 'selection',
      color: '#009EE3'
    }
  ]);
  const [isDisabled, setIsDisabled] = useState(disabled);

  return (
    <div className='sidePopUp-container'>
      <div className={`sidePopUp-body${isSidePopUpVisible ? '' : ' sclose'}`}>
        <div className='sidePopUp-header'>
          <label>Abteilungsinformationen</label>
        </div>
        <div className='sidePopUp-content'>
          <div className='non-form'>
            <label>Name</label>
            <p>{selectedDepartment?.name}</p>
          </div>
          <div className='non-form'>
            <label>Themen</label>
            <p>{selectedDepartment?.subjects}</p>
          </div>
          <div className='non-form'>
            <label>Ausbildungsbeauftragter</label>
            <span>{selectedDepartment?.training_officer.name}</span>
          </div>
          <div className='non-form'>
            <label>Kontaktperson</label>
            <span>{selectedDepartment?.contact_person.name}</span>
          </div>
          <div className='non-form'>
            <label>Hintergrundfarbe</label>
            <span>
              <div
                style={{
                  height: 30,
                  width: 30,
                  backgroundColor: selectedDepartment?.background_color
                }}
              />{' '}
              {selectedDepartment?.background_color}
            </span>
          </div>
          <div className='non-form'>
            <label>Programmiersprachen</label>
            <div className='departmentView-programmingLanguages'>
              {selectedDepartment?.programming_languages.map(
                programmingLanguage => (
                  <div key={`ProgrammingLang-${programmingLanguage.id}`}>
                    {programmingLanguage.programming_language.name}
                  </div>
                )
              )}
            </div>
          </div>
          <div className='non-form'>
            <label>Aufenthaltsdauer</label>
            <span>{selectedDepartment?.duration} Tage</span>
          </div>
          <div className='non-form'>
            <label>Start- und Enddatum</label>
            <DateRange
              locale={locales['de']}
              months={2}
              minDate={new Date(startDate)}
              maxDate={new Date(endDate)}
              preventSnapRefocus={true}
              onChange={item => {
                if (
                  getUserInfo()?.user?.role?.code !== USERROLES.APPRENTICE &&
                  getUserInfo()?.user?.role?.code !== USERROLES.TRAINING_OFFICER
                ) {
                  setRange([item.selection]);
                  setIsDisabled(false);
                }
              }}
              shownDate={range[0]?.startDate}
              ranges={range}
            />
          </div>
        </div>
        <div className='sidePopUp-footer'>
          <div className='button-group'>
            <CheckAuthorization
              roles={[
                USERROLES.ADMIN,
                USERROLES.APPRENTICE_LEADER,
                USERROLES.INSTRUCTOR,
                USERROLES.HR
              ]}
            >
              <Button
                text='Speichern'
                disabled={isDisabled && range[0].startDate && range[0].endDate}
                loading={loading}
                className='hero'
                onClick={() => handleSubmit(range[0])}
              />
            </CheckAuthorization>
            <Button
              text='Abbrechen'
              disabled={false}
              loading={false}
              onClick={handleClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
