import '../styles/Input.css';

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import Select from 'react-select';
import CreateSelect from 'react-select/creatable';

export function Input({
  placeholder,
  value,
  onChange,
  onEnter,
  type,
  error,
  required,
  select,
  isMulti,
  maxLength,
  options,
  accept,
  selectPlaceholder,
  creatable,
  createText,
  textarea,
  onFocusLeave,
  icon,
  max,
  min,
  onCreate,
  color,
  checked
}) {
  const [errorState, setErrorState] = useState();
  const [validationEnabled, setValidationEnabled] = useState(false);

  useEffect(() => {
    setErrorState(error);
    if (validationEnabled && required && !value) {
      setErrorState(`Bitte ${placeholder} angeben!`);
    }
    setValidationEnabled(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, value, required, placeholder]);

  return (
    <>
      <label>
        {placeholder}
        {required ? '*' : ''}
      </label>
      {select ? (
        creatable ? (
          <CreateSelect
            isLoading={!Array.isArray(options)}
            onChange={onChange}
            className='input-select'
            classNamePrefix='input-select'
            options={options && options.length > 0 ? options : undefined}
            isMulti={isMulti}
            placeholder={selectPlaceholder}
            formatCreateLabel={input => `${createText} '${input}' erstellen`}
            onCreateOption={onCreate}
            defaultValue={value}
            value={value}
          />
        ) : (
          <Select
            defaultValue={value}
            value={value}
            isLoading={!Array.isArray(options)}
            onChange={onChange}
            className='input-select'
            classNamePrefix='input-select'
            options={options && options.length > 0 ? options : undefined}
            isMulti={isMulti}
            placeholder={selectPlaceholder}
          />
        )
      ) : textarea ? (
        <textarea
          placeholder={placeholder}
          className={`textarea ${errorState ? 'hasError' : ''}`}
          onChange={onChange}
          maxLength={maxLength}
          value={value}
          onBlur={onFocusLeave}
          required
        />
      ) : (
        <div className='input-container'>
          {color && (
            <div
              className='input-color'
              style={{ backgroundColor: color || '#fff' }}
            />
          )}
          <input
            type={type}
            placeholder={placeholder}
            className={`input ${errorState ? 'hasError' : ''}`}
            onChange={onChange}
            maxLength={maxLength}
            max={max}
            min={min}
            onKeyPress={event =>
              event.key === 'Enter' && onEnter ? onEnter() : undefined
            }
            value={value}
            required
            accept={accept}
            onBlur={onFocusLeave}
            checked={checked}
          />
          {icon ? icon : undefined}
        </div>
      )}
      {errorState && (
        <span className='input-error'>
          <FaInfoCircle fill='#d50c2f' className='input-error-icon' />
          {errorState}
        </span>
      )}
    </>
  );
}

Input.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  onEnter: PropTypes.func,
  select: PropTypes.bool,
  isMulti: PropTypes.bool,
  maxLength: PropTypes.number,
  accept: PropTypes.string,
  selectPlaceholder: PropTypes.string,
  creatable: PropTypes.bool,
  textarea: PropTypes.bool,
  onFocusLeave: PropTypes.func
};
