/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import {
  ApiClient,
  Button,
  CheckAuthorization,
  CheckIfLoggedIn
} from '../components';
import USERROLES from '../constants/UserRoles';

export function PlanRedirect({ setBreadcrumbLinks }) {
  const { userId } = useParams();
  const { addToast } = useToasts();
  const navigate = useNavigate();

  const [isRedirecting, setIsRedirecting] = useState(true);
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    ApiClient.get(`users/${userId}`)
      .then(user => {
        setBreadcrumbLinks([
          {
            id: 1,
            label: `${user?.firstname} ${user?.lastname}`,
            path: `/users/${userId}/semesters`
          },
          {
            id: 2,
            label: 'Ausbildungsplan',
            path: `/users/${userId}/plan`
          }
        ]);
        if (user.plan_id) navigate(`${user.plan_id}`, { replace: true });
        else {
          setUser(user);
          setIsRedirecting(false);
        }
      })
      .catch(error => {
        console.log(error);
        addToast('Etwas ist schiefgelaufen!', { appearance: 'error' });
      });
  }, [addToast, navigate, setBreadcrumbLinks, userId]);

  function handleCreatePlan() {
    setIsLoading(true);
    ApiClient.post(`users/${userId}/plan`)
      .then(planId => {
        addToast(
          `Ausbildungsplan für ${user.firstname} ${user.lastname} erfolgreich erstellt!`,
          { appearance: 'success' }
        );
        navigate(`/plan/${planId}`, { replace: true });
      })
      .catch(error => {
        console.log(error);
        addToast('Etwas ist schiefgelaufen!', { appearance: 'error' });
      });
  }

  return (
    <CheckIfLoggedIn>
      <div className='container'>
        <div className='content'>
          {isRedirecting ? (
            <label>Redirecting...</label>
          ) : (
            <div>
              <h1>
                Es konnte leider kein Ausbildungsplan für {user.firstname}{' '}
                {user.lastname} gefunden werden.
              </h1>
              <CheckAuthorization
                roles={[
                  USERROLES.ADMIN,
                  USERROLES.APPRENTICE_LEADER,
                  USERROLES.INSTRUCTOR
                ]}
              >
                <Button
                  text='+ Erstellen'
                  disabled={isLoading}
                  loading={isLoading}
                  className='hero'
                  onClick={handleCreatePlan}
                />
              </CheckAuthorization>
            </div>
          )}
        </div>
      </div>
    </CheckIfLoggedIn>
  );
}
