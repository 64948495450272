import '../styles/CookieBanner.css';

import { useEffect, useState } from 'react';

import { Button } from './Button';

export function CookieBanner() {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (localStorage.getItem('accepted_cookies')) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  }, [isVisible]);

  function handleAccept() {
    localStorage.setItem('accepted_cookies', true);
    setIsVisible(false);
  }

  return (
    <>
      {isVisible ? (
        <div className='cookie-banner'>
          <p className='cookie-banner-text'>
            Diese Website verwendet Cookies, um Ihnen einen besseren Service
            bieten zu können.
            <a
              href='https://netilion.endress.com/legal/privacy-policy'
              target='_blank'
              className='cookie-banner-link'
              rel='noreferrer'
            >
              Weitere Informationen
            </a>
          </p>
          <Button
            onClick={handleAccept}
            text='Alles klar'
            className='hero cookie-banner-button'
            disabled={false}
            loading={false}
          />
        </div>
      ) : undefined}
    </>
  );
}
