import PropTypes from 'prop-types';

export function Row({ data, onClick, className }) {
  return (
    <tr
      onClick={onClick}
      className={(className || '') + (onClick ? ' clickable' : '')}
    >
      {data.map((value, index) => (
        <td key={index}>{value}</td>
      ))}
    </tr>
  );
}

Row.propTypes = {
  data: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string
};
