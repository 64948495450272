import jwtDecode from 'jwt-decode';

export function getUserInfo() {
  return localStorage.getItem('access_token')
    ? jwtDecode(localStorage.getItem('access_token'))
    : undefined;
}

export function isTokenValid(token) {
  try {
    const tokenDecoded = jwtDecode(token);
    return Math.floor(Date.now() / 1000) <= tokenDecoded.exp;
  } catch {
    return false;
  }
}
