/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable unicorn/no-null */
import '../styles/SubjectCreate.css';
import '../styles/SubjectProfessionAssign.css';

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { ApiClient, Button, CheckIfLoggedIn, Input } from '../components';

export function SubjectSemesterAssign({ setBreadcrumbLinks }) {
  let { addToast } = useToasts();
  const navigate = useNavigate();
  const { userId, semesterId } = useParams();

  const [subjects, setSubjects] = useState(null);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [error, setError] = useState('');
  const [semester, setSemester] = useState(null);
  const [associations, setAssociations] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    Promise.all([
      ApiClient.get(`users/${userId}/semesters`),
      ApiClient.get(`users/${userId}`)
    ])
      .then(([{ semesters }, user]) => {
        const semester = semesters?.find(semester => semester.id == semesterId);
        setSemester(semester);

        setBreadcrumbLinks([
          {
            id: 1,
            label: `${user?.firstname} ${user?.lastname}`,
            path: `/users/${userId}/semesters`
          },
          {
            id: 2,
            label: 'Alle Semester',
            path: `/users/${userId}/semesters`
          },
          {
            id: 3,
            label: semester?.index,
            path: `/users/${userId}/semesters/${semesterId}/subjects`
          },
          {
            id: 4,
            label: 'Fächer',
            path: `/users/${userId}/semesters/${semesterId}/subjects`
          },
          {
            id: 5,
            label: 'Bearbeiten',
            path: `/users/${userId}/semesters/${semesterId}/subjects/edit`
          }
        ]);
        ApiClient.get(`professions/${user?.profession_id}/subjects`)
          .then(({ subjects }) => setSubjects(subjects))
          .catch(() =>
            addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
          );
      })
      .catch(() =>
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
      );
    updateAssociations();
  }, []);

  useEffect(() => {
    setError('');
  }, [selectedSubjects]);

  function updateAssociations() {
    ApiClient.get(`users/${userId}/semesters/${semesterId}/subjects`)
      .then(({ subjects }) => setAssociations(subjects))
      .catch(() =>
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
      );
  }

  function handleSubmitClicked() {
    if (Array.isArray(selectedSubjects) && selectedSubjects.length === 0) {
      return setError('Bitte mindestens ein Fach zum Hinzufügen auswählen!');
    }

    setLoading(true);
    ApiClient.post(`users/${userId}/semesters/${semesterId}/subjects`, {
      subjects: selectedSubjects.map(subject => subject.value)
    })
      .then(() => {
        addToast(
          (selectedSubjects.length === 1
            ? selectedSubjects[0].label
            : 'Fächer') + ' erfolgreich hinzugefügt!',
          {
            appearance: 'success'
          }
        );
        setSelectedSubjects([]);
        updateAssociations();
      })
      .catch(() =>
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
      )
      .finally(() => setLoading(false));
  }

  let options = subjects
    ?.filter(
      subject =>
        !associations.some(association => association.subject.id === subject.id)
    )
    .map(subject => ({
      value: subject.id,
      label: subject.name
    }));

  return (
    <CheckIfLoggedIn>
      <div className='create-subject container'>
        <div className='content'>
          <h1>Fächer {semester ? `in Semester ${semester?.index}` : ''}</h1>
          {associations.length > 0 ? (
            associations.map(associatedSubject => (
              <div
                key={associatedSubject.id}
                className='association-subject-item'
              >
                <label>{associatedSubject.subject.name}</label>
              </div>
            ))
          ) : (
            <p>Es wurden noch keine Fächer zugewiesen.</p>
          )}
          <h2>Fächer hinzufügen</h2>
          <div className='create-subject-form'>
            <Input
              error={error}
              placeholder='Fächer'
              onChange={setSelectedSubjects}
              select
              required
              value={selectedSubjects}
              options={options}
              isMulti
              key={options?.length}
            />
          </div>
          <div className='button-group'>
            <Button
              text='Hinzufügen'
              disabled={loading}
              loading={loading}
              className='hero'
              onClick={handleSubmitClicked}
            />
            <Button
              text='Zurück'
              disabled={false}
              loading={false}
              className=''
              onClick={() =>
                navigate(`/users/${userId}/semesters/${semesterId}/subjects`)
              }
            />
          </div>
        </div>
      </div>
    </CheckIfLoggedIn>
  );
}
