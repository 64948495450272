export const DEFAULT_DURATION = 50;
export const DAY_HEIGHT = 4;
export const COLORS = [
  '#AFD3E8',
  '#009EE3',
  '#007CAA',
  '#00597A',
  '#506671',
  '#8FA2AC',
  '#C3CED5',
  '#FFE596',
  '#FBCDAB'
];
