import Tippy from '@tippyjs/react';

export default function BreadcrumbActionMenu({
  id,
  className,
  style,
  actions
}) {
  return (
    <div
      id={id}
      className={[className, 'list-item-action-menu'].filter(c => c).join(' ')}
      style={style}
    >
      <Tippy
        className={['menu', 'list-item-action-menu-popover']
          .filter(c => c)
          .join(' ')}
        placement='bottom'
        trigger='click'
        content={actions}
        offset={[-40, 10]}
        interactive
      >
        <button type='button'>
          <span>...</span>
        </button>
      </Tippy>
    </div>
  );
}
