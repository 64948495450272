/* eslint-disable indent */
import '../styles/WeeklyReportOverview.css';

import { useEffect, useState } from 'react';
import { BiPencil } from 'react-icons/bi';
import { IoIosAdd } from 'react-icons/io';
import { useNavigate, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import {
  ApiClient,
  CheckAuthorization,
  CheckIfLoggedIn,
  Input,
  LoadingIndicator,
  Row,
  Table
} from '../components';
import USERROLES from '../constants/UserRoles';

export function WeeklyReportOverview({ setBreadcrumbLinks }) {
  const navigate = useNavigate();

  const { addToast } = useToasts();
  const [inputKW, setInputKW] = useState('');
  const [inputWochenFazit, setInputWochenFazit] = useState('');
  const [weeklyReports, setWeeklyReports] = useState('');
  const [user, setUser] = useState();
  const [semester, setSemester] = useState();

  let { userId, semesterId } = useParams();

  useEffect(() => {
    Promise.all([
      ApiClient.get(`/users/${userId}/semesters/${semesterId}/weekly_reports/`),
      ApiClient.get(`users/${userId}`),
      ApiClient.get(`users/${userId}/semesters`)
    ])
      .then(([weeklyReports, user, { semesters }]) => {
        const semester = semesters.find(semester => semester.id == semesterId);
        setBreadcrumbLinks([
          {
            id: 1,
            label: `${user?.firstname} ${user?.lastname}`,
            path: `/users/${userId}/semesters`
          },
          {
            id: 2,
            label: 'Alle Semester',
            path: `/users/${userId}/semesters`
          },
          {
            id: 3,
            label: semester?.index,
            path: `/users/${userId}/semesters/${semester.id}/subjects`
          },
          {
            id: 4,
            label: 'Wochenberichte',
            path: `/users/${userId}/semesters/${semester.id}/weekly_reports`
          }
        ]);
        setWeeklyReports(weeklyReports.weeklyUserReports);
        setUser(user);
        setSemester(semester);
      })
      .catch(() =>
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
      );
  }, [addToast, semesterId, setBreadcrumbLinks, userId]);

  function createWeeklyReport() {
    let KW = calculateKW();

    ApiClient.post(
      `/users/${userId}/semesters/${semesterId}/weekly_reports/create`,
      {
        calendar_week: KW,
        semester_id: semesterId
      }
    )
      .then(response => {
        addToast(`Wochenbericht für die Woche ${KW} erfolgreich hinzugefügt`, {
          appearance: 'success'
        });
        setWeeklyReports([...weeklyReports, response.weeklyReport]);
      })
      .catch(error => {
        addToast(
          error?.status === 409
            ? 'Ein Wochenbericht für diese Kalenderwoche existiert bereits'
            : 'Etwas ist schief gelaufen!',
          {
            appearance: 'error'
          }
        );
      });
  }

  function calculateKW() {
    let currentDate = new Date();
    let startDate = new Date(currentDate.getFullYear(), 0, 1);
    let days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
    let weekNumber = Math.ceil(days / 7);

    return weekNumber;
  }

  let rowcon = weeklyReport => {
    return [
      <p
        key={weeklyReport.id + 'spanKW'}
        className='spanKW'
        onClick={() => navigate(`${weeklyReport.id}/daily_reports`)}
      >
        {weeklyReport.calendar_week}
      </p>,

      <p key={weeklyReport.id + 'spanConclusion'} className='spanConclusion'>
        {weeklyReport?.conclusion}
      </p>
    ];
  };

  return (
    <CheckIfLoggedIn>
      <div className='subjectOverview container'>
        <div className='content'>
          <h1 className='title'>
            Alle Wochenberichte für {user?.firstname} {user?.lastname} in
            Semester {semester?.index}
            <CheckAuthorization
              roles={[
                USERROLES.ADMIN,
                USERROLES.APPRENTICE,
                USERROLES.APPRENTICE_LEADER,
                USERROLES.INSTRUCTOR
              ]}
            >
              <IoIosAdd onClick={createWeeklyReport} className='title-icon' />
            </CheckAuthorization>
          </h1>
          <Table leftAlign>
            {weeklyReports ? (
              Array.isArray(weeklyReports) && weeklyReports.length > 0 ? (
                <Table leftAlign>
                  <Row
                    key='thead'
                    data={[
                      <Input
                        key='kw'
                        type='text'
                        id='inputText'
                        name='inputText'
                        placeholder='KW'
                        value={inputKW}
                        onChange={event => setInputKW(event.target.value)}
                      />,
                      <Input
                        key='Wochenfazit'
                        type='text'
                        id='inputText'
                        name='inputTextW'
                        placeholder='Wochenfazit'
                        value={inputWochenFazit}
                        onChange={event =>
                          setInputWochenFazit(event.target.value)
                        }
                      />,
                      ''
                    ]}
                  />
                  {weeklyReports
                    .filter(report =>
                      report.calendar_week.toString().includes(inputKW)
                    )
                    .filter(report =>
                      inputWochenFazit
                        ? report.conclusion
                            ?.toLowerCase()
                            .includes(inputWochenFazit)
                        : report
                    )
                    .sort((a, b) => a.calendar_week - b.calendar_week)
                    .map((weeklyReport, index) => (
                      <Row
                        key={weeklyReport.id}
                        data={[
                          ...rowcon(weeklyReport, index),
                          <div key='' className='stylePen'>
                            <BiPencil
                              className='penEditConc'
                              key='pen'
                              onClick={() => {
                                navigate(`${weeklyReport.id}/daily_reports`);
                              }}
                            />
                          </div>
                        ]}
                      />
                    ))}
                </Table>
              ) : (
                <h2>Keine Wochenberichte vorhanden!</h2>
              )
            ) : (
              <LoadingIndicator height={25} width={25} mode='darker' />
            )}
          </Table>
        </div>
      </div>
    </CheckIfLoggedIn>
  );
}
