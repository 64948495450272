export function validateEmail(email) {
  return String(email)
    .toLowerCase()
    .match(
      // eslint-disable-next-line max-len
      // eslint-disable-next-line unicorn/better-regex, security/detect-unsafe-regex
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

export function isValidDate(d) {
  return !Number.isNaN(new Date(d).getTime());
}

export function isValidNumber(number) {
  return !Number.isNaN(number);
}
