import Clickable from './Clickable';

function ListItemAction({
  id,
  className,
  style,
  onClick,
  target,
  children,
  disabled
}) {
  return (
    <div
      id={id}
      className={[className, 'list-item-action', disabled && 'disabled']
        .filter(c => c)
        .join(' ')}
      style={style}
    >
      <Clickable onClick={onClick} target={target} disabled={disabled}>
        {children}
      </Clickable>
    </div>
  );
}

export default ListItemAction;
