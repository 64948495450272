import '../styles/ProfileEdit.css';

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import {
  ApiClient,
  Button,
  CheckAuthorization,
  CheckIfLoggedIn,
  Input,
  LoadingIndicator
} from '../components';
import USERROLES from '../constants/UserRoles';
import { getUserInfo } from '../helpers/auth';
import { isValidDate, validateEmail } from '../helpers/validator';
import blankProfilePicture from '../res/blank_profile_picture.jpg';

export function ProfileEdit({ setBreadcrumbLinks }) {
  const { addToast } = useToasts();
  const [user, setUser] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [profilePictureLoading, setProfilePictureLoading] = useState(false);
  const [unsavedProfilePicture, setUnsavedProfilePicture] = useState();
  const { userId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    ApiClient.get(`users/${userId}`)
      .then(user => {
        setBreadcrumbLinks([
          {
            id: 1,
            label: `${user?.firstname} ${user?.lastname}`,
            path: `/users/${userId}/edit`
          },
          {
            id: 2,
            label: 'Bearbeiten',
            path: `/users/${userId}/edit`
          }
        ]);
        setUser(user);
      })
      .catch(() =>
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
      );
  }, [addToast, setBreadcrumbLinks, userId]);

  function onInputChange(field, value) {
    setUser({ ...user, [field]: value });
    setError({ ...error, [field]: '' });
  }

  const validateInputFields = user => {
    if (user.role.code === USERROLES.APPRENTICE) {
      return user.firstname &&
        user.lastname &&
        user.email &&
        (user.password || user.oldPassword || user.passwordRepeated)
        ? user.password && user.oldPassword && user.passwordRepeated
        : true;
    } else {
      return user.firstname &&
        user.lastname &&
        user.email &&
        user.apprenticeship_start &&
        (user.password || user.oldPassword || user.passwordRepeated)
        ? user.password && user.oldPassword && user.passwordRepeated
        : true;
    }
  };

  function handleSubmitClicked() {
    setUser({
      ...user,
      firstname: user.firstname.trim(),
      lastname: user.lastname.trim(),
      email: user.email.trim()
    });

    if (!validateInputFields(user)) {
      return setError({
        ...error,
        firstname: !user.firstname ? 'Bitte Vorname eingeben!' : '',
        lastname: !user.lastname ? 'Bitte Nachname eingeben!' : '',
        email: !user.email ? 'Bitte Email eingeben!' : '',
        apprenticeship_start: !user.apprenticeship_start
          ? 'Bitte Ausbildungsstart eingeben!'
          : '',
        oldPassword: !user.oldPassword ? 'Bitte altes Passwort eingeben!' : '',
        password: !user.password ? 'Bitte neues Passwort eingeben!' : '',
        passwordRepeated: !user.passwordRepeated
          ? 'Bitte neues Passwort wiederholen!'
          : ''
      });
    }

    if (!validateEmail(user.email)) {
      return setError({
        ...error,
        email: 'Bitte geben Sie eine gültige Email an!'
      });
    }

    if (
      (user.password || user.oldPassword || user.passwordRepeated) &&
      user.password !== user.passwordRepeated
    ) {
      return setError({
        ...error,
        passwordRepeated: 'Die Passwörter stimmen nicht überein!',
        password: 'Die Passwörter stimmen nicht überein!'
      });
    } else {
      setError({
        ...error,
        oldPassword: '',
        passwordRepeated: '',
        password: ''
      });
    }

    if (
      getUserInfo().user.role.code !== USERROLES.APPRENTICE &&
      user.role.code === USERROLES.APPRENTICE &&
      !isValidDate(user.apprenticeship_start)
    ) {
      return setError({
        ...error,
        apprenticeshipStart:
          'Bitte geben Sie einen gültigen Ausbildungsstart an!'
      });
    }

    let patchObject = {
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      is_active: user.is_active
    };

    if (
      (user.password || user.oldPassword || user.passwordRepeated) &&
      user.password === user.passwordRepeated
    ) {
      patchObject['password'] = user.password;
      patchObject['old_password'] = user.oldPassword;
    }

    if (
      getUserInfo().user.role.code !== USERROLES.APPRENTICE &&
      user.role.code === USERROLES.APPRENTICE &&
      isValidDate(user.apprenticeship_start)
    ) {
      patchObject['apprenticeship_start'] = user.apprenticeship_start;
    }

    setLoading(true);
    ApiClient.patch(`users/${user.id}`, patchObject)
      .then(() => {
        ApiClient.post('auth/refresh', {
          refresh_token: localStorage.getItem('refresh_token')
        })
          .then(({ access_token, refresh_token }) => {
            console.log('Refreshed');
            localStorage.setItem('access_token', access_token);
            localStorage.setItem('refresh_token', refresh_token);
            addToast(
              `${
                user.id == getUserInfo().user.id ? 'Profil' : 'Benutzer'
              } erfolgreich bearbeitet`,
              {
                appearance: 'success'
              }
            );
            navigate('/');
          })
          .catch(() => {
            console.log('Refresh key invalid');
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            window.location.replace('/');
            Promise.resolve();
          });
      })
      .catch(() => {
        addToast('Etwas ist schiefgelaufen!', {
          appearance: 'error'
        });
      })
      .finally(() => setLoading(false));
  }

  function onProfileImageChange(event) {
    setUnsavedProfilePicture(event.target.files[0]);
  }

  function handleProfilePictureSubmit() {
    if (!unsavedProfilePicture) {
      return setError({
        ...error,
        profilePicture: 'Bitte wähle zuerst ein Bild aus!'
      });
    }

    setProfilePictureLoading(true);
    const formData = new FormData();
    formData.append('file', unsavedProfilePicture);
    ApiClient.patch(`users/${userId}/picture`, formData)
      .then(({ picture }) => {
        setUser({ ...user, profile_picture_name: picture });
        addToast('Profilbild erfolgreich aktualisiert', {
          appearance: 'success'
        });
      })
      .catch(() =>
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
      )
      .finally(() => setProfilePictureLoading(false));
  }

  return (
    <CheckIfLoggedIn>
      <div className='subjectOverview container'>
        <div className='content'>
          {user ? (
            <>
              <h1 className='title'>
                {user.id == getUserInfo().user.id ? 'Profil' : 'Benutzer'}{' '}
                bearbeiten
              </h1>
              <div className='form profile-picture'>
                <label>Profilbild</label>
                <div className='profile-picture-row'>
                  <div
                    className='profile-img'
                    alt='profile picture'
                    style={{
                      background: `url(${
                        user.profile_picture_name
                          ? user.profile_picture_name
                          : blankProfilePicture
                      })`
                    }}
                  />
                  <div className='input-column'>
                    <Input
                      error={error?.profilePicture}
                      placeholder='Neues Bild'
                      type='file'
                      onChange={onProfileImageChange}
                      accept='image/*'
                    />
                    <Button
                      text='Hochladen'
                      disabled={profilePictureLoading}
                      loading={profilePictureLoading}
                      className='hero profile-picture-submit'
                      onClick={handleProfilePictureSubmit}
                    />
                  </div>
                </div>
              </div>
              <div className='form'>
                <Input
                  error={error?.firstname}
                  placeholder='Vorname'
                  type='text'
                  value={user.firstname}
                  onChange={event =>
                    onInputChange('firstname', event.target.value)
                  }
                  required
                />
              </div>
              <div className='form'>
                <Input
                  error={error?.lastname}
                  placeholder='Nachname'
                  type='text'
                  value={user.lastname}
                  onChange={event =>
                    onInputChange('lastname', event.target.value)
                  }
                  required
                />
              </div>
              <div className='form'>
                <Input
                  error={error?.email}
                  placeholder='Email'
                  type='email'
                  value={user.email}
                  onChange={event => onInputChange('email', event.target.value)}
                  required
                />
              </div>
              <div className='form'>
                <Input
                  error={error?.oldPassword}
                  placeholder='Altes Passwort'
                  type='password'
                  value={user.oldPassword}
                  onChange={event =>
                    onInputChange('oldPassword', event.target.value)
                  }
                />
              </div>
              <div className='form'>
                <Input
                  error={error?.password}
                  placeholder='Passwort'
                  type='password'
                  value={user.password}
                  onChange={event =>
                    onInputChange('password', event.target.value)
                  }
                />
              </div>
              <div className='form'>
                <Input
                  error={error?.passwordRepeated}
                  placeholder='Passwort wiederholen'
                  type='password'
                  value={user.passwordRepeated}
                  onChange={event =>
                    onInputChange('passwordRepeated', event.target.value)
                  }
                />
              </div>
              <CheckAuthorization
                roles={[USERROLES.ADMIN, USERROLES.APPRENTICE_LEADER]}
                shouldNavigate={false}
              >
                <div className='form profileEdit-is_active'>
                  <Input
                    error={error?.is_active}
                    placeholder='Aktiv?'
                    type='checkbox'
                    checked={user.is_active}
                    onChange={() => onInputChange('is_active', !user.is_active)}
                  />
                </div>
              </CheckAuthorization>
              <CheckAuthorization
                roles={[
                  USERROLES.ADMIN,
                  USERROLES.APPRENTICE_LEADER,
                  USERROLES.INSTRUCTOR
                ]}
                shouldNavigate={false}
              >
                {user.role.code === USERROLES.APPRENTICE ? (
                  <div className='form'>
                    <Input
                      error={error?.apprenticeship_start}
                      placeholder='Ausbildungsstart'
                      type='date'
                      value={user.apprenticeship_start}
                      onChange={event =>
                        onInputChange(
                          'apprenticeship_start',
                          event.target.value
                        )
                      }
                      required
                    />
                  </div>
                ) : undefined}
              </CheckAuthorization>
              <div className='button-group'>
                <Button
                  text='Speichern'
                  disabled={loading}
                  loading={loading}
                  className='hero'
                  onClick={handleSubmitClicked}
                />
                <Button
                  text='Abbrechen'
                  disabled={false}
                  loading={false}
                  className=''
                  onClick={() => navigate('/')}
                />
              </div>
            </>
          ) : (
            <LoadingIndicator height={25} width={25} mode='darker' />
          )}
        </div>
      </div>
    </CheckIfLoggedIn>
  );
}
