import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import {
  ApiClient,
  Button,
  CheckAuthorization,
  CheckIfLoggedIn,
  Input
} from '../components';
import USERROLES from '../constants/UserRoles';
import { isValidNumber } from '../helpers/validator';

export function ProfessionCreate({ setBreadcrumbLinks }) {
  let { addToast } = useToasts();
  const navigate = useNavigate();

  const [error, setError] = useState({ name: '', semesters: '' });
  const [profession, setProfession] = useState({ name: '', semesters: 0 });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = 'AMP | Beruf erstellen';
    setBreadcrumbLinks([
      { id: 1, label: 'Berufe anzeigen', path: '/professions' },
      { id: 2, label: 'Erstellen', path: '/professions/create' }
    ]);
  }, [setBreadcrumbLinks]);

  function onInputChange(field, value) {
    setProfession({ ...profession, [field]: value });
    setError({ ...error, [field]: '' });
  }

  const validateInputFields = profession =>
    profession.name && profession.semesters;

  function handleSubmitClicked() {
    if (!validateInputFields(profession)) {
      return setError({
        ...error,
        name: !profession.name ? 'Bitte Namen eingeben!' : '',
        semesters: !profession.semesters ? 'Bitte Semesteranzahl eingeben!' : ''
      });
    }
    if (!isValidNumber(Number.parseInt(profession.semesters))) {
      return setError({
        ...error,
        semesters: 'Bitte geben Sie eine Zahl an!'
      });
    }

    setProfession({
      ...profession,
      name: profession.name.trim(),
      semesters: Number.parseInt(profession.semesters)
    });

    setLoading(true);
    ApiClient.post('professions', {
      name: profession.name,
      semesters: profession.semesters
    })
      .then(() => {
        addToast(`${profession.name} erfolgreich erstellt!`, {
          appearance: 'success'
        });
        navigate('/');
      })
      .catch(() => {
        addToast('Etwas ist schief gelaufen!', {
          appearance: 'error'
        });
        setLoading(false);
      });
  }

  return (
    <CheckIfLoggedIn>
      <CheckAuthorization
        roles={[USERROLES.ADMIN, USERROLES.APPRENTICE_LEADER]}
        shouldNavigate={true}
      >
        <div className='createProfession container'>
          <div className='content'>
            <h1 className='title'>Beruf hinzufügen</h1>
            <div className='form'>
              <Input
                error={error.name}
                placeholder='Name'
                type='text'
                value={profession.name}
                onChange={event => onInputChange('name', event.target.value)}
                required
              />
            </div>
            <div className='form'>
              <Input
                error={error.semesters}
                placeholder='Semester'
                type='number'
                value={profession.semesters}
                onChange={event =>
                  onInputChange('semesters', event.target.value)
                }
                required
              />
            </div>
            <div className='button-group'>
              <Button
                text='Hinzufügen'
                disabled={loading}
                loading={loading}
                className='hero'
                onClick={handleSubmitClicked}
              />
              <Button
                text='Abbrechen'
                disabled={false}
                loading={false}
                className=''
                onClick={() => navigate('/professions')}
              />
            </div>
          </div>
        </div>
      </CheckAuthorization>
    </CheckIfLoggedIn>
  );
}
