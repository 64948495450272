/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable unicorn/no-null */
/* eslint-disable unicorn/prevent-abbreviations */
import { createRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from 'usehooks-ts';

import BreadcrumbHomeIcon from './BreadcrumbHomeIcon';
import BreadcrumbItems from './BreadcrumbItems';

export default function Breadcrumbs({
  isEditMode,
  className,
  style,
  home,
  options
}) {
  const divRef = createRef();
  const { width } = useWindowSize();
  const [dropdownCount, setDropdownCount] = useState(0);
  const [prevScrollWidth, setPrevScrollWidth] = useState(0);
  const [prevOptions, setPrevOptions] = useState(null);

  let navigate = useNavigate();

  const handleDivResize = () => {
    const EXPAND_THRESHOLD = 10;
    const { clientWidth } = divRef.current;
    let { scrollWidth } = divRef.current;
    if (dropdownCount < options.length - 2 && clientWidth - scrollWidth < 0) {
      setDropdownCount(dropdownCount + 1);
    } else if (
      dropdownCount > 0 &&
      scrollWidth - prevScrollWidth > EXPAND_THRESHOLD
    ) {
      setDropdownCount(dropdownCount - 1);
      scrollWidth = Math.min(prevScrollWidth + EXPAND_THRESHOLD, scrollWidth);
    }
    setPrevScrollWidth(scrollWidth);
  };

  const handleOptionsChanged = () => {
    if (!(JSON.stringify(options) === JSON.stringify(prevOptions))) {
      setDropdownCount(0);
    }
    setPrevOptions(options);
  };

  useEffect(() => {
    handleDivResize();
  }, [divRef, width]);

  useEffect(() => {
    handleOptionsChanged();
  }, [options]);

  function onClick(item) {
    navigate(item.path);
  }

  return (
    <div className='breadcrumb-parent'>
      <div
        ref={divRef}
        className={[
          className,
          `breadcrumb-container${isEditMode ? ' bc-edit' : ''}`
        ]
          .filter(c => c)
          .join(' ')}
        style={style}
      >
        <ol className='breadcrumb-list'>
          <BreadcrumbHomeIcon
            active={options?.length > 0}
            option={home}
            onClick={() => navigate('/')}
          />
          {options?.length > 0 ? (
            <BreadcrumbItems
              options={options}
              onClick={onClick}
              dropdownCount={dropdownCount}
            />
          ) : null}
        </ol>
      </div>
    </div>
  );
}
