/* eslint-disable indent */
import '../styles/RatingPointsOverviewFillout.css';

import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import jwtDecode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { BsShieldLock } from 'react-icons/bs';
import { ImLock } from 'react-icons/im';
import { MdOutlineDoNotDisturbAlt } from 'react-icons/md';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { CheckAuthorization, EvaluationPDF } from '../components';
import {
  ApiClient,
  Button,
  CheckIfLoggedIn,
  LoadingIndicator,
  RatingIndicator,
  Row,
  Table
} from '../components';
import USERROLES from '../constants/UserRoles';
import { getUserInfo } from '../helpers/auth';
import {
  categorizeRatingPoints,
  containsInstructorUnsigned,
  containsSelfUnsigned,
  ratingPointsIncludes
} from '../helpers/categoryRatingPoints';

export function RatingPointsOverview({ setBreadcrumbLinks }) {
  const { userId, semesterId } = useParams();
  const { addToast } = useToasts();
  const [categoriesWithRatingPoints, setCategoriesWithRatingPoints] =
    useState();
  const [semester, setSemester] = useState();
  const [company, setCompany] = useState();
  const [profession, setProfession] = useState();
  const [user, setUser] = useState();
  const [apprenticeLeader, setApprenticeLeader] = useState();
  const navigate = useNavigate();
  const isApprentice =
    jwtDecode(localStorage.getItem('access_token')).user.role.code ===
    USERROLES.APPRENTICE;

  useEffect(() => {
    Promise.all([
      ApiClient.get(`users/${userId}/semesters/${semesterId}`),
      ApiClient.get(`users/${userId}`)
    ])
      .then(([semester, user]) => {
        setBreadcrumbLinks([
          {
            id: 1,
            label: `${user?.firstname} ${user?.lastname}`,
            path: `/users/${userId}/semesters`
          },
          {
            id: 2,
            label: 'Alle Semester',
            path: `/users/${userId}/semesters`
          },
          {
            id: 3,
            label: semester?.index,
            path: `/users/${userId}/semesters/${semester.id}/subjects`
          },
          {
            id: 4,
            label: 'Beurteilung',
            path: `/users/${userId}/semesters/${semester.id}/assessment`
          }
        ]);
        setSemester(semester);
      })
      .catch(() =>
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
      );
    updateCategoriesAndRatingPoints();

    ApiClient.get(`users/${userId}`)
      .then(user => {
        setUser(user);
        ApiClient.get('companies')
          .then(companies =>
            companies.companies.map(company =>
              company.id === user.company_id ? setCompany(company) : ''
            )
          )
          .catch(() =>
            addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
          );
        ApiClient.get(`users/${userId}/apprentice_leader`)
          .then(APLeader => setApprenticeLeader(APLeader[0]))
          .catch(() =>
            addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
          );
        ApiClient.get(`professions/${user.profession_id}`)
          .then(profession => setProfession(profession))
          .catch(() =>
            addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
          );
      })
      .catch(() =>
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToast, semesterId, userId]);

  function updateCategoriesAndRatingPoints() {
    Promise.all([
      ApiClient.get(`users/${userId}/semesters/${semesterId}/rating_points`),
      ApiClient.get('assessment_categories')
    ])
      .then(response =>
        setCategoriesWithRatingPoints(
          categorizeRatingPoints(
            response[1].assessment_categories,
            response[0].rating_points
          )
        )
      )
      .catch(() =>
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
      );
  }

  function signAssessment() {
    ApiClient.put(`/users/${userId}/semesters/${semesterId}/assessments/sign`)
      .then(() => {
        updateCategoriesAndRatingPoints();
        addToast('Die Beurteilung wurde signiert!', { appearance: 'success' });
      })
      .catch(() =>
        addToast('Die Beurteilung konnte nicht signiert werden!', {
          appearance: 'error'
        })
      );
  }

  function ratingReleased() {
    if (isApprentice) {
      ApiClient.patch(`users/${userId}/semesters/${semesterId}`, {
        rating_released_self: true
      })
        .then(() => {
          setSemester({ ...semester, rating_released_self: true });
          addToast('Erfolgreich gespeichert!', { appearance: 'success' });
        })
        .catch(() =>
          addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
        );
    } else {
      ApiClient.patch(`users/${userId}/semesters/${semesterId}`, {
        rating_released_instructor: true
      })
        .then(() => {
          setSemester({ ...semester, rating_released_instructor: true });
          addToast('Erfolgreich gespeichert!', { appearance: 'success' });
        })
        .catch(() =>
          addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
        );
    }
  }

  const containsCategoriesAndRatingPoints =
    Array.isArray(categoriesWithRatingPoints) &&
    categoriesWithRatingPoints.length > 0;

  const showRatingIndicator = (rating, role) =>
    !rating?.locked ? (
      semester?.rating_released_self && semester?.rating_released_instructor ? (
        <RatingIndicator key={rating?.id + 'indicator'} rating={rating} />
      ) : role === 'APPRENTICE' &&
        getUserInfo().user.role.code === USERROLES.APPRENTICE ? (
        <RatingIndicator key={rating?.id + 'indicator'} rating={rating} />
      ) : role === 'INSTRUCTOR' &&
        (getUserInfo().user.role.code === USERROLES.INSTRUCTOR ||
          getUserInfo().user.role.code === USERROLES.APPRENTICE_LEADER) ? (
        <RatingIndicator key={rating?.id + 'indicator'} rating={rating} />
      ) : (
        <ImLock className='lock-icon' />
      )
    ) : (
      <ImLock className='lock-icon' />
    );
  const filloutButton = (
    <Button
      onClick={() => navigate('rate')}
      text='Beurteilung ausfüllen'
      className='hero'
    />
  );
  const editButton = (
    <Button onClick={() => navigate('rate')} text='Beurteilung bearbeiten' />
  );
  const signButton = (
    <Button
      onClick={signAssessment}
      text='Beurteilung signieren'
      className='hero'
    />
  );

  const freigebenButton = (
    <Button
      onClick={ratingReleased}
      text='Beurteilung freigeben'
      className='hero'
    />
  );

  const apprenticeButtons = () => {
    if (categoriesWithRatingPoints.length > 0) {
      if (containsInstructorUnsigned(categoriesWithRatingPoints)) {
        return ratingPointsIncludes(
          categoriesWithRatingPoints,
          'self_rating',
          // eslint-disable-next-line unicorn/no-null
          null
        ) ? (
          filloutButton
        ) : (
          <>
            {editButton}
            {!semester.rating_released_self && freigebenButton}
          </>
        );
      } else {
        // eslint-disable-next-line unicorn/prefer-ternary
        if (
          !ratingPointsIncludes(
            categoriesWithRatingPoints,
            'instructor_signed',
            0
          )
        ) {
          return signButton;
        } else {
          return (
            <>
              {editButton}
              {!semester.rating_released_self && freigebenButton}
            </>
          );
        }
      }
    }
  };

  const instructorButtons = () => {
    return ratingPointsIncludes(
      categoriesWithRatingPoints,
      'instructor_rating',
      // eslint-disable-next-line unicorn/no-null
      null
    ) ? (
      filloutButton
    ) : semester.rating_released_instructor ? (
      signButton /* maybe edit */
    ) : (
      <>
        {editButton}
        {freigebenButton}
      </>
    );
  };

  const actionButtons =
    semester?.ratings_fixed === 1 &&
    (containsInstructorUnsigned(categoriesWithRatingPoints) ||
      containsSelfUnsigned(categoriesWithRatingPoints))
      ? isApprentice
        ? apprenticeButtons()
        : instructorButtons()
      : undefined;

  const noFixedAssessment = (
    <p style={{ fontSize: 20, color: '#486F4F' }}>
      <MdOutlineDoNotDisturbAlt style={{ marginRight: 7, fill: '#486F4F' }} />
      Beurteilung wurde noch nicht fixiert!{' '}
      {!isApprentice ? (
        <Link to={`/users/${userId}/semesters/${semesterId}/assessment_points`}>
          Zur Übersicht
        </Link>
      ) : undefined}
    </p>
  );

  const signedSymbol = (
    <p style={{ fontSize: 20, color: '#486F4F' }}>
      <BsShieldLock style={{ marginRight: 7, fill: '#486F4F' }} />
      Beurteilung ist signiert!
    </p>
  );

  function createAndDownloadPDF() {
    pdf(
      categoriesWithRatingPoints.length > 0 &&
        company &&
        user &&
        profession &&
        semester ? (
        <EvaluationPDF
          categoriesWithRatingPoints={categoriesWithRatingPoints}
          company={company}
          user={user}
          profession={profession}
          apprenticeLeader={apprenticeLeader}
          semester={semester?.index}
        />
      ) : undefined
    )
      .toBlob()
      .then(blob =>
        saveAs(
          blob,
          'Semesterbeurteilung_' + user.firstname + '-' + user.lastname
        )
      )
      .catch(() =>
        addToast('Das PDF konnte nicht heruntergeladen werden!', {
          appearance: 'error'
        })
      );
  }

  return (
    <CheckIfLoggedIn>
      <div className='rating-points-overview container'>
        <div className='content'>
          <CheckAuthorization
            roles={[
              USERROLES.ADMIN,
              USERROLES.APPRENTICE_LEADER,
              USERROLES.APPRENTICE,
              USERROLES.INSTRUCTOR
            ]}
          >
            <div className='right-align'>{actionButtons}</div>
          </CheckAuthorization>
          <h1 className='title'>
            Beurteilung für{' '}
            {!isApprentice
              ? `${semester?.user?.firstname} ${semester?.user?.lastname} in `
              : ''}
            Semester {semester?.index}
            {!containsInstructorUnsigned(categoriesWithRatingPoints) &&
            !containsSelfUnsigned(categoriesWithRatingPoints) ? (
              <p
                className='downloadBtnPDF'
                onClick={() => createAndDownloadPDF()}
              >
                Download
              </p>
            ) : (
              ''
            )}
          </h1>
          {semester?.ratings_fixed === 0 ? (
            noFixedAssessment
          ) : (
            <>
              {containsCategoriesAndRatingPoints &&
              !containsInstructorUnsigned(categoriesWithRatingPoints) &&
              !containsSelfUnsigned(categoriesWithRatingPoints)
                ? signedSymbol
                : undefined}
              {categoriesWithRatingPoints ? (
                containsCategoriesAndRatingPoints ? (
                  <Table thead={['', 'Bewertung BB', 'Selbstbeurteilung']}>
                    {categoriesWithRatingPoints.map(category => (
                      <>
                        <Row
                          key={category.id}
                          className='category-title'
                          data={[category.name, '', '']}
                        />
                        {category.rating_points?.map(ratingPoint => (
                          <Row
                            key={ratingPoint.id}
                            data={[
                              <div
                                key={ratingPoint.id}
                                className='rating-point'
                              >
                                <b>{ratingPoint.title}</b>
                                <p>{ratingPoint.description}</p>
                              </div>,
                              showRatingIndicator(
                                ratingPoint.instructor_rating,
                                'INSTRUCTOR'
                              ),
                              showRatingIndicator(
                                ratingPoint.self_rating,
                                'APPRENTICE'
                              )
                            ]}
                          />
                        ))}
                      </>
                    ))}
                  </Table>
                ) : (
                  <h2>Diese Beurteilung ist leer!</h2>
                )
              ) : (
                <LoadingIndicator height={25} width={25} mode='darker' />
              )}
            </>
          )}
        </div>
      </div>
    </CheckIfLoggedIn>
  );
}
