import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { getUserInfo } from '../helpers/auth';

export function CheckIfLoggedIn({ children }) {
  let navigate = useNavigate();
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  let access_token = localStorage.getItem('access_token');

  useEffect(() => {
    if (localStorage.getItem('access_token')) {
      try {
        getUserInfo();
        setIsLoggedIn(true);
      } catch {
        setIsLoggedIn(false);
        navigate('/login');
      }
    } else {
      setIsLoggedIn(false);
      navigate('/login');
    }
  }, [access_token, location.pathname, navigate]);

  return <>{isLoggedIn ? children : undefined}</>;
}
