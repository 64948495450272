/* eslint-disable indent */
/* eslint-disable unicorn/no-null */
import '../styles/SubjectCreate.css';

import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import {
  ApiClient,
  Button,
  CheckAuthorization,
  CheckIfLoggedIn,
  Input
} from '../components';
import USERROLES from '../constants/UserRoles';

export function SubjectCreate({ setBreadcrumbLinks }) {
  let { addToast } = useToasts();
  const navigate = useNavigate();

  const [subjectName, setSubjectName] = useState('');

  const [selectedProfession, setSelectedProfession] = useState('');
  const [professions, setProfessions] = useState('');

  const [error, setError] = useState({ name: '', profession: '' });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = 'AMP | Fach erstellen';
    setBreadcrumbLinks([
      {
        id: 1,
        label: 'Fächer anzeigen',
        path: '/subjects'
      },
      {
        id: 1,
        label: 'Erstellen',
        path: '/subjects/create'
      }
    ]);
    ApiClient.get('professions')
      .then(professions => setProfessions(professions))
      .catch(() =>
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
      );
  }, [addToast, setBreadcrumbLinks]);

  function onInputChange(value) {
    setSubjectName(value);
    setError();
  }

  const validateInput = name => name && name?.trim() && !Number.isNaN(name);

  function handleSubmitClicked() {
    setSubjectName(subjectName.trim());

    if (!validateInput(subjectName)) {
      return setError('Bitte gültiger Name für Fach eingeben!');
    }
    if (Array.isArray(selectedProfession) && selectedProfession.length === 0) {
      return setError('Bitte mindestens ein Fach dem Beruf zuweisen!');
    }

    setLoading(true);
    ApiClient.post('subjects', { name: subjectName })
      .then(subject => {
        addToast(`${subjectName} erfolgreich hinzugefügt!`, {
          appearance: 'success'
        });
        ApiClient.post(`professions/${selectedProfession.value}/subjects`, {
          subjects: [subject.id]
        })
          .then(() => {
            addToast('Verknüpfung erfolgreich hinzugefügt', {
              appearance: 'success'
            });
          })
          .catch(error => {
            setLoading(false);
            addToast('Fach konnte keinem Beruf zugewiesen werden', {
              appearance: error
            });
          });
        navigate('/subjects');
      })
      .catch(error => {
        setLoading(false);
        addToast(
          error?.status === 409
            ? 'Dieses Fach existiert bereits'
            : 'Etwas ist schief gelaufen!',
          {
            appearance: 'error'
          }
        );
      });
  }

  let options = professions.professions?.map(profession => ({
    value: profession?.id,
    label: profession?.name
  }));

  return (
    <CheckIfLoggedIn>
      <CheckAuthorization
        roles={[
          USERROLES.ADMIN,
          USERROLES.APPRENTICE_LEADER,
          USERROLES.INSTRUCTOR
        ]}
        shouldNavigate={true}
      >
        <div className='create-subject container'>
          <div className='content'>
            <h1>Fach hinzufügen</h1>
            <div className='create-subject-form'>
              <Input
                error={error?.name}
                placeholder='Name'
                type='text'
                value={subjectName}
                onChange={event => onInputChange(event.target.value)}
                required
              />
              <Input
                error={error?.profession}
                placeholder='Beruf'
                onChange={setSelectedProfession}
                select
                value={selectedProfession.label}
                options={options}
                key={options?.length}
              />
            </div>

            <div className='button-group'>
              <Button
                text='Hinzufügen'
                disabled={loading}
                loading={loading}
                className='hero'
                onClick={handleSubmitClicked}
              />
              <Button
                text='Abbrechen'
                disabled={false}
                loading={false}
                className=''
                onClick={() => navigate('/subjects')}
              />
            </div>
          </div>
        </div>
      </CheckAuthorization>
    </CheckIfLoggedIn>
  );
}
