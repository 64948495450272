import { useEffect, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { useNavigate, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import {
  ApiClient,
  Button,
  CheckAuthorization,
  CheckIfLoggedIn,
  Input,
  LoadingIndicator
} from '../components';
import USERROLES from '../constants/UserRoles';
import { isValidNumber } from '../helpers/validator';

export function DepartmentEdit({ setBreadcrumbLinks }) {
  let { addToast } = useToasts();
  const navigate = useNavigate();

  const [error, setError] = useState({
    name: '',
    subjects: '',
    training_officer_id: '',
    contact_person_id: '',
    programming_languages: '',
    background_color: ''
  });
  const [department, setDepartment] = useState();
  const [checkDepartment, setCheckDepartment] = useState();
  const [allTrainingOfficers, setAllTrainingOfficers] = useState();
  const [allContactPersons, setAllContactPersons] = useState();
  const [allProgrammingLanguages, setAllProgrammingLanguages] = useState();
  const [loading, setLoading] = useState(false);

  const { departmentId } = useParams();

  useEffect(() => {
    Promise.all([
      ApiClient.get(`departments/${departmentId}`),
      ApiClient.get('departments/training_officers'),
      ApiClient.get('departments/contact_persons'),
      ApiClient.get('departments/programming_languages')
    ])
      .then(
        ([
          department,
          training_officers,
          contact_persons,
          programming_languages
        ]) => {
          setBreadcrumbLinks([
            { id: 1, label: 'Abteilungen anzeigen', path: '/departments' },
            {
              id: 2,
              label: department.name,
              path: `/departments/${departmentId}`
            },
            {
              id: 3,
              label: 'Bearbeiten',
              path: `/departments/${departmentId}/edit`
            }
          ]);
          department.programming_languages =
            department.programming_languages.map(pl => ({
              label: pl.programming_language.name,
              value: pl.programming_language.id
            }));

          department.training_officer_id = training_officers.find(
            trainingOfficer =>
              trainingOfficer.name === department.training_officer.name
          ).id;
          department.contact_person_id = contact_persons.find(
            contactPerson =>
              contactPerson.name === department.contact_person.name
          ).id;
          delete department.contact_person;
          delete department.training_officer;
          setCheckDepartment(department);
          setDepartment(department);
          setAllTrainingOfficers(training_officers);
          setAllContactPersons(contact_persons);
          setAllProgrammingLanguages(programming_languages);
        }
      )
      .catch(() =>
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
      );
  }, [addToast, departmentId, setBreadcrumbLinks]);

  function onInputChange(field, value) {
    setDepartment({ ...department, [field]: value });
    setError({ ...error, [field]: '' });
  }

  const validateInputFields = department =>
    department.name &&
    department.subjects &&
    department.training_officer_id &&
    department.contact_person_id &&
    department.background_color &&
    Array.isArray(department.programming_languages) &&
    department.programming_languages.length > 0;

  function handleSubmitClicked() {
    if (!validateInputFields(department)) {
      return setError({
        ...error,
        name: !department.name ? 'Bitte Namen eingeben!' : '',
        subjects: !department.subjects ? 'Bitte Themen eingeben!' : '',
        background_color: !department.background_color
          ? 'Bitte Hintergrundfarbe eingeben!'
          : '',
        training_officer_id: !department.training_officer_id
          ? 'Bitte Aubsildungsbeauftragten eingeben!'
          : '',
        contact_person_id: !department.contact_person_id
          ? 'Bitte Kontaktperson eingeben!'
          : '',
        programming_languages: !department.programming_languages
          ? 'Bitte Programmiersprachen eingeben!'
          : ''
      });
    }
    if (!isValidNumber(Number.parseInt(department.training_officer_id))) {
      return setError({
        ...error,
        training_officer_id: 'Bitte geben Sie eine Zahl an!'
      });
    }

    if (!/^#[\da-f]{6}$/i.test(department.background_color)) {
      return setError({
        ...error,
        background_color: 'Bitte geben Sie eine Farbe in Form von HEX ein!'
      });
    }

    if (!isValidNumber(Number.parseInt(department.contact_person_id))) {
      return setError({
        ...error,
        contact_person_id: 'Bitte geben Sie eine Zahl an!'
      });
    }

    setDepartment({
      ...department,
      name: department.name.trim(),
      subjects: department.subjects.trim(),
      background_color: department.background_color.trim(),
      training_officer_id: Number.parseInt(department.training_officer_id),
      contact_person_id: Number.parseInt(department.contact_person_id)
    });

    setLoading(true);
    ApiClient.patch(`departments/${departmentId}`, {
      id: department.id,
      name: department.name,
      oldName: checkDepartment.name,
      subjects: department.subjects,
      training_officer_id: department.training_officer_id,
      contact_person_id: department.contact_person_id,
      programming_languages: department.programming_languages,
      background_color: department.background_color,
      version: department.version
    })
      .then(() => {
        addToast(`${department.name} erfolgreich bearbeitet!`, {
          appearance: 'success'
        });
        navigate(`/departments/${departmentId}`);
      })
      .catch(error => {
        if (error?.data?.error?.code === 'DEPARTMENT_EXISTS') {
          setError({
            ...error,
            name: 'Abteilung mit diesen Namen existiert bereits.'
          });
        }
        addToast('Etwas ist schief gelaufen!', {
          appearance: 'error'
        });
        setLoading(false);
      });
  }

  function onTrainingOfficerChange(selection) {
    if (selection.__isNew__) {
      ApiClient.post('departments/training_officers', {
        name: selection.value
      }).then(trainingOfficer => {
        onInputChange('training_officer_id', trainingOfficer.id);
        setAllTrainingOfficers([...allTrainingOfficers, trainingOfficer]);
        addToast(
          `Ausbildungsbeauftragten ${selection.value} erfolgreich erstellt!`,
          {
            appearance: 'success'
          }
        );
      });
    } else {
      onInputChange('training_officer_id', selection.value);
    }
  }

  function onContactPersonChange(selection) {
    if (selection.__isNew__) {
      ApiClient.post('departments/contact_persons', {
        name: selection.value
      }).then(contactPerson => {
        onInputChange('contact_person_id', contactPerson.id);
        setAllContactPersons([...allContactPersons, contactPerson]);
        addToast(`Kontaktperson ${selection.value} erfolgreich erstellt!`, {
          appearance: 'success'
        });
      });
    } else {
      onInputChange('contact_person_id', selection.value);
    }
  }

  function onProgrammingLanguagesChange(selection) {
    onInputChange('programming_languages', selection);
  }

  function handleCreate(parameters) {
    ApiClient.post('departments/programming_languages', {
      name: parameters
    }).then(programmingLanguage => {
      setAllProgrammingLanguages([
        ...allProgrammingLanguages,
        programmingLanguage
      ]);

      addToast(`Programmiersprache ${parameters} erfolgreich erstellt!`, {
        appearance: 'success'
      });
    });
  }

  return (
    <CheckIfLoggedIn>
      <CheckAuthorization
        roles={[
          USERROLES.ADMIN,
          USERROLES.APPRENTICE_LEADER,
          USERROLES.INSTRUCTOR
        ]}
        shouldNavigate={true}
      >
        <div className='departmentEdit container'>
          <div className='content'>
            {department && allContactPersons && allTrainingOfficers ? (
              <>
                <h1 className='title'>{checkDepartment.name} bearbeiten</h1>
                <div className='form'>
                  <Input
                    error={error.name}
                    placeholder='Name'
                    type='text'
                    value={department.name}
                    onChange={event =>
                      onInputChange('name', event.target.value)
                    }
                    required
                  />
                </div>
                <div className='form'>
                  <Input
                    select
                    error={error.training_officer_id}
                    placeholder='Ausbildungsbeauftragter'
                    value={{
                      label: allTrainingOfficers.find(
                        trainingOfficer =>
                          trainingOfficer.id === department.training_officer_id
                      ).name,
                      value: department.training_officer_id
                    }}
                    options={allTrainingOfficers?.map(trainingOfficer => ({
                      label: trainingOfficer.name,
                      value: trainingOfficer.id
                    }))}
                    onChange={onTrainingOfficerChange}
                    required
                    creatable
                    createText='Ausbildungsbeauftragten'
                  />
                </div>
                <div className='form'>
                  <Input
                    select
                    error={error.contact_person_id}
                    placeholder='Kontaktperson'
                    value={{
                      label: allContactPersons.find(
                        contactPerson =>
                          contactPerson.id === department.contact_person_id
                      ).name,
                      value: department.contact_person_id
                    }}
                    options={allContactPersons?.map(contactPerson => ({
                      label: contactPerson.name,
                      value: contactPerson.id
                    }))}
                    onChange={onContactPersonChange}
                    required
                    creatable
                    createText='Kontaktperson'
                  />
                </div>
                <div className='form'>
                  <Input
                    select
                    error={error.programming_languages}
                    placeholder='Programmiersprachen'
                    options={allProgrammingLanguages?.map(
                      programmingLanguage => ({
                        label: programmingLanguage.name,
                        value: programmingLanguage.id
                      })
                    )}
                    value={department.programming_languages}
                    onChange={onProgrammingLanguagesChange}
                    onCreate={handleCreate}
                    required
                    isMulti
                    creatable
                    createText='Programmiersprache'
                  />
                </div>
                <div className='form department-background_color'>
                  <Input
                    color={department.background_color}
                    error={error.background_color}
                    placeholder='Hintergrundfarbe'
                    type='text'
                    value={department.background_color}
                    onChange={event =>
                      onInputChange('background_color', event.target.value)
                    }
                    required
                  />
                  <HexColorPicker
                    className='department-color-picker'
                    color={department.background_color}
                    onChange={color => onInputChange('background_color', color)}
                  />
                </div>
                <div className='form'>
                  <Input
                    textarea
                    error={error.subjects}
                    placeholder='Themen'
                    type='text'
                    value={department.subjects}
                    onChange={event =>
                      onInputChange('subjects', event.target.value)
                    }
                    required
                  />
                </div>
                <div className='button-group'>
                  <Button
                    text='Speichern'
                    disabled={
                      loading ||
                      !validateInputFields(department) ||
                      JSON.stringify(department) ===
                        JSON.stringify(checkDepartment)
                    }
                    loading={loading}
                    className='hero'
                    onClick={handleSubmitClicked}
                  />
                  <Button
                    text='Abbrechen'
                    disabled={false}
                    loading={false}
                    className=''
                    onClick={() => navigate(`/departments/${departmentId}`)}
                  />
                </div>
              </>
            ) : (
              <LoadingIndicator height={25} width={25} mode='darker' />
            )}
          </div>
        </div>
      </CheckAuthorization>
    </CheckIfLoggedIn>
  );
}
