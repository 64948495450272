import '../styles/RatingPointsOverviewFillout.css';

import jwtDecode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { BsShieldLock } from 'react-icons/bs';
import { MdOutlineDoNotDisturbAlt } from 'react-icons/md';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import {
  ApiClient,
  CheckIfLoggedIn,
  Input,
  LoadingIndicator,
  RatingIndicator,
  Row,
  Table
} from '../components';
import UserRoles from '../constants/UserRoles';
import {
  categorizeRatingPoints,
  ratingPointsIncludes
} from '../helpers/categoryRatingPoints';

export function RatingPointsFillout({ setBreadcrumbLinks }) {
  const { userId, semesterId } = useParams();
  const { addToast } = useToasts();
  const [categoriesWithRatingPoints, setCategoriesWithRatingPoints] =
    useState();
  const [semester, setSemester] = useState();
  const [loadingRatingPoints, setLoadingRatingPoints] = useState([]);
  const [state, setState] = useState('Gespeichert!');
  const navigate = useNavigate();
  const isApprentice =
    jwtDecode(localStorage.getItem('access_token')).user.role.code ===
    UserRoles.APPRENTICE;

  useEffect(() => {
    Promise.all([
      ApiClient.get(`users/${userId}/semesters/${semesterId}`),
      ApiClient.get(`users/${userId}`)
    ])
      .then(([semester, user]) => {
        setBreadcrumbLinks([
          {
            id: 1,
            label: `${user?.firstname} ${user?.lastname}`,
            path: `/users/${userId}/semesters`
          },
          {
            id: 2,
            label: 'Alle Semester',
            path: `/users/${userId}/semesters`
          },
          {
            id: 3,
            label: semester?.index,
            path: `/users/${userId}/semesters/${semester.id}/subjects`
          },
          {
            id: 4,
            label: 'Beurteilung',
            path: `/users/${userId}/semesters/${semester.id}/assessment/rate`
          }
        ]);
        setSemester(semester);
      })
      .catch(() =>
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
      );
    updateCategoriesAndRatingPoints();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToast, semesterId, userId, navigate]);

  function updateCategoriesAndRatingPoints() {
    Promise.all([
      ApiClient.get(`users/${userId}/semesters/${semesterId}/rating_points`),
      ApiClient.get('assessment_categories')
    ])
      .then(response =>
        setCategoriesWithRatingPoints(
          categorizeRatingPoints(
            response[1].assessment_categories,
            response[0].rating_points
          )
        )
      )
      .catch(() =>
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
      );
  }

  function onRatingChanged(ratingPointId, { rating, remark }) {
    setLoadingRatingPoints([...loadingRatingPoints, ratingPointId]);
    setState('Speichervorgang...');
    let patchObject = {
      rating: rating ?? undefined,
      remark: remark === undefined ? undefined : remark
    };

    ApiClient.patch(
      // eslint-disable-next-line max-len
      `/users/${userId}/semesters/${semesterId}/rating_points/${ratingPointId}/rating`,
      patchObject
    )
      .then(() => {
        updateCategoriesAndRatingPoints();
        setState('Gespeichert!');
      })
      .catch(() => {
        addToast('Änderung konnte nicht gespeichert werden!', {
          appearance: 'error'
        });
        setState('Fehler: Konnte nicht gespeichert werden!');
      })
      .finally(() =>
        setLoadingRatingPoints([
          ...loadingRatingPoints.filter(id => id != ratingPointId)
        ])
      );
  }

  const containsCategoriesAndRatingPoints =
    Array.isArray(categoriesWithRatingPoints) &&
    categoriesWithRatingPoints.length > 0;

  const showRatingIndicator = (rating, ratingPointId) => (
    <RatingIndicator
      key={ratingPointId + 'rating_indicator'}
      rating={rating}
      editable
      onChange={newRating =>
        onRatingChanged(ratingPointId, { rating: newRating })
      }
      loading={loadingRatingPoints.includes(ratingPointId)}
    />
  );

  async function onTextfieldChanged(ratingPointId, value) {
    const categories = [...categoriesWithRatingPoints];
    const categoryIndex = categories.findIndex(category =>
      category.rating_points
        .map(ratingPoint => ratingPoint.id)
        .includes(ratingPointId)
    );
    // eslint-disable-next-line security/detect-object-injection
    const ratingPointIndex = categories[categoryIndex].rating_points.findIndex(
      ratingPoint => ratingPoint.id === ratingPointId
    );
    let rating =
      // eslint-disable-next-line security/detect-object-injection
      categories[categoryIndex].rating_points[ratingPointIndex][
        isApprentice ? 'self_rating' : 'instructor_rating'
      ];
    if (rating !== null) {
      rating.remark = value;
    }

    setCategoriesWithRatingPoints(categories);
  }

  const ratingPointsTable = containsCategoriesAndRatingPoints ? (
    <Table>
      {categoriesWithRatingPoints.map(category => (
        <>
          <Row
            key={category.id}
            className='category-title'
            data={[category.name, '', '']}
          />
          {category.rating_points?.map(ratingPoint => (
            <Row
              key={ratingPoint.id}
              data={[
                <div
                  key={ratingPoint.id + 'description'}
                  className='rating-point'
                >
                  <b>{ratingPoint.title}</b>
                  <p>{ratingPoint.description}</p>
                </div>,
                showRatingIndicator(
                  isApprentice
                    ? ratingPoint.self_rating
                    : ratingPoint.instructor_rating,
                  ratingPoint.id
                ),
                <Input
                  textarea
                  key={ratingPoint.id + 'remark'}
                  placeholder='Bemerkung'
                  onFocusLeave={event =>
                    onRatingChanged(ratingPoint.id, {
                      remark: event.target.value
                    })
                  }
                  value={
                    isApprentice
                      ? ratingPoint.self_rating?.remark
                      : ratingPoint.instructor_rating?.remark
                  }
                  onChange={event =>
                    onTextfieldChanged(ratingPoint.id, event.target.value)
                  }
                />
              ]}
            />
          ))}
        </>
      ))}
    </Table>
  ) : (
    <h2>Diese Beurteilung ist leer!</h2>
  );

  const checkIfAssessmentIsSigned = () => {
    // eslint-disable-next-line unicorn/prefer-ternary
    if (
      ratingPointsIncludes(categoriesWithRatingPoints, 'instructor_signed', 1)
    ) {
      return (
        <p style={{ fontSize: 20 }}>
          <BsShieldLock style={{ marginRight: 7 }} />
          Beurteilung ist signiert!
        </p>
      );
    } else {
      return ratingPointsTable;
    }
  };

  const noFixedAssessment = (
    <p style={{ fontSize: 20, color: '#486F4F' }}>
      <MdOutlineDoNotDisturbAlt style={{ marginRight: 7, fill: '#486F4F' }} />
      Beurteilung wurde noch nicht fixiert!{' '}
      {!isApprentice ? (
        <Link to={`/users/${userId}/semesters/${semesterId}/assessment_points`}>
          Zur Übersicht
        </Link>
      ) : undefined}
    </p>
  );

  return (
    <CheckIfLoggedIn>
      <div className='rating-points-overview rating-points-fillout container'>
        <div className='content'>
          <Link to={`/users/${userId}/semesters/${semesterId}/assessment`}>
            Zurück zur Übersicht
          </Link>
          <div className='right-align'>
            <p className='state'>{state}</p>
          </div>
          <h1 className='title'>
            Beurteilung für{' '}
            {!isApprentice
              ? `${semester?.user?.firstname} ${semester?.user?.lastname} in `
              : ''}
            Semester {semester?.index}
          </h1>
          {semester?.ratings_fixed === 0 ? (
            noFixedAssessment
          ) : categoriesWithRatingPoints ? (
            checkIfAssessmentIsSigned()
          ) : (
            <LoadingIndicator height={25} width={25} mode='darker' />
          )}
        </div>
      </div>
    </CheckIfLoggedIn>
  );
}
