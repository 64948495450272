import '../styles/Apprentices.css';

import { useEffect, useState } from 'react';
import { GoPencil } from 'react-icons/go';
import { TfiLayoutGrid2, TfiViewList } from 'react-icons/tfi';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import {
  ApiClient,
  Apprentice,
  CheckAuthorization,
  CheckIfLoggedIn,
  LoadingIndicator,
  Row,
  Table
} from '../components';
import USERROLES from '../constants/UserRoles';
import { getUserInfo } from '../helpers/auth';
import { redirectUserWhenOnClick } from '../helpers/user';
import blankProfilePicture from '../res/blank_profile_picture.jpg';

export function Apprentices({ setBreadcrumbLinks, setHideBreadcrumb }) {
  const { addToast } = useToasts();
  const [users, setUsers] = useState();
  const [view, setView] = useState(localStorage.getItem('user-view') || 'grid');
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'AMP | Home';
    setHideBreadcrumb(false);
    setBreadcrumbLinks([]);
    if (!getUserInfo()) return navigate('/login');
    if (getUserInfo()?.user.role.code === USERROLES.APPRENTICE) {
      navigate(`/users/${getUserInfo().user.id}/semesters`);
    } else {
      ApiClient.get(
        getUserInfo()?.user?.role?.code === USERROLES.ADMIN
          ? 'users'
          : 'users/apprentices'
      )
        .then(({ users }) => {
          // let final = [];
          // // eslint-disable-next-line unicorn/no-array-for-each
          // users.forEach(user => {
          //   if (final.some(role => role.role === user.role.name)) {
          //     final.find(role => role.role === user.role.name)
          // .users.push(user);
          //   } else {
          //     final.push({
          //       role: user.role.name,
          //       users: []
          //     });
          //     final.find(role => role.role === user.role.name)
          // .users.push(user);
          //   }
          // });
          // setUsers([...final]);
          setUsers(users);
        })
        .catch(() =>
          addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
        );
    }
  }, [addToast, navigate, setBreadcrumbLinks, setHideBreadcrumb]);

  function changeView(view) {
    setView(view);
    localStorage.setItem('user-view', view);
  }

  return (
    <CheckIfLoggedIn>
      <CheckAuthorization
        roles={[
          USERROLES.ADMIN,
          USERROLES.APPRENTICE_LEADER,
          USERROLES.INSTRUCTOR,
          USERROLES.HR,
          USERROLES.TRAINING_OFFICER
        ]}
        shouldNavigate={false}
      >
        <div className='apprentices container'>
          <div className='content'>
            <div className='apprentices-view'>
              <TfiLayoutGrid2
                title='Rasteransicht'
                onClick={() => changeView('grid')}
                className={`apprentice-view-icon ${
                  view === 'grid' ? ' active-view' : ''
                }`}
              />
              <div className='apprentices-view-spacer' />
              <TfiViewList
                title='Listenansicht'
                onClick={() => changeView('list')}
                className={`apprentice-view-icon ${
                  view === 'list' ? ' active-view' : ''
                }`}
              />
            </div>
            {users ? (
              Array.isArray(users) && users.length > 0 ? (
                <>
                  {view === 'grid' ? (
                    <>
                      {users.map(user => (
                        <Apprentice
                          latestSemesterId={user.latestSemesterId}
                          key={user.id}
                          role={user.role?.code}
                          userId={user.id}
                          name={`${user.firstname} ${user.lastname}`}
                          profilePicturePath={user.profile_picture_name}
                        />
                      ))}
                    </>
                  ) : (
                    <div className='apprentices-table'>
                      <Table
                        thead={['', 'Name', 'Beruf', 'Firma', 'Rolle', '']}
                        leftAlign
                      >
                        {users.map(user => (
                          <Row
                            onClick={() =>
                              redirectUserWhenOnClick(
                                user.role?.code,
                                user.latestSemesterId,
                                user.id,
                                navigate
                              )
                            }
                            key={user.id}
                            data={[
                              <img
                                className='list-profile-img'
                                key={
                                  user.profile_picture_name ||
                                  blankProfilePicture
                                }
                                src={
                                  user.profile_picture_name ||
                                  blankProfilePicture
                                }
                              />,
                              `${user.firstname} ${user.lastname}`,
                              user.profession?.name || '',
                              user.company?.name || '',
                              user.role?.name || '',
                              <CheckAuthorization
                                roles={[
                                  USERROLES.ADMIN,
                                  USERROLES.APPRENTICE_LEADER
                                ]}
                                key={'edit' + user.id}
                                shouldNavigate={false}
                              >
                                <GoPencil
                                  onClick={event => {
                                    event.stopPropagation();
                                    navigate(`users/${user.id}/edit`);
                                  }}
                                  style={{ cursor: 'pointer' }}
                                />
                              </CheckAuthorization>
                            ]}
                          />
                        ))}
                      </Table>
                    </div>
                  )}
                </>
              ) : (
                <h2>Noch keine Benutzer vorhanden.</h2>
              )
            ) : (
              <LoadingIndicator height={25} width={25} mode='darker' />
            )}
          </div>
        </div>
      </CheckAuthorization>
    </CheckIfLoggedIn>
  );
}
