import '../styles/RatingIndicator.css';

import { useState } from 'react';
import { BsFillInfoCircleFill } from 'react-icons/bs';

export function RatingIndicator({ rating, editable, onChange, loading }) {
  const [overwriteRating, setOverwriteRating] = useState();

  const bubbles = () => {
    const bubbles = [];
    for (let index = 1; index <= 5; index++) {
      bubbles.push(
        <div
          className='bubble'
          onClick={() => (editable ? onChange(index) : undefined)}
          onMouseEnter={() =>
            editable ? setOverwriteRating(index) : undefined
          }
          onMouseLeave={() => setOverwriteRating()}
          key={'rating_indicator' + index}
        ></div>
      );
    }
    return bubbles;
  };
  return (
    <div
      className={
        'rating-indicator' +
        (rating?.rating ? ` rating-${overwriteRating || rating?.rating}` : '') +
        (editable ? ' editable' : '') +
        (loading ? ' loading' : '')
      }
    >
      {bubbles()}
      {!editable && rating?.remark ? (
        <>
          <BsFillInfoCircleFill className='info-circle' />
          <div className='popup-remark'>{rating.remark}</div>
        </>
      ) : undefined}
    </div>
  );
}
