import '../styles/Button.css';

import PropTypes from 'prop-types';

import { LoadingIndicator } from '.';

export function Button({ onClick, text, className, disabled, loading }) {
  return (
    <button
      className={`btn ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {loading ? (
        <>
          <LoadingIndicator
            height={20}
            width={20}
            disabled={disabled}
            mode='light'
          />
          {text}
        </>
      ) : (
        text
      )}
    </button>
  );
}

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool
};
