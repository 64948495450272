import { StyleSheet, Text, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  section: {
    textAlign: 'left',
    marginLeft: 30,
    marginRight: 30,
    marginBottom: 5,
    marginTop: 5
  },
  row: {
    marginTop: -5,
    flexDirection: 'row',
    alignItems: 'center',
    height: 24,
    fontStyle: 'bold'
  },
  col1: {
    width: '35%',
    fontSize: 12,
    marginLeft: 5
  },
  col2: {
    width: '65%',
    fontSize: 12
  }
});

export const TableUserInfos = properties => {
  return (
    <View style={styles.section} key='1'>
      <View style={styles.row}>
        <Text style={styles.col1}>Semester </Text>
        <Text style={styles.col2}>
          {properties.semester ? properties.semester : ''}
        </Text>
      </View>

      <View style={styles.row}>
        <Text style={styles.col1}>Ausbildungsfirma </Text>
        <Text style={styles.col2}>{properties.company?.name}</Text>
      </View>

      <View style={styles.row}>
        <Text style={styles.col1}>Ausbildner </Text>
        <Text style={styles.col2}>
          {properties.apprenticeLeader?.firstname +
            ' ' +
            properties.apprenticeLeader?.lastname}
        </Text>
      </View>

      <View style={styles.row}>
        <Text style={styles.col1}>Ausbildungsberuf </Text>
        <Text style={styles.col2}>{properties.profession?.name}</Text>
      </View>

      <View style={styles.row}>
        <Text style={styles.col1}>Lernender </Text>
        <Text style={styles.col2}>
          {properties.user?.firstname + ' ' + properties.user?.lastname}
        </Text>
      </View>
    </View>
  );
};
