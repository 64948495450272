import '../styles/Footer.css';

export function Footer() {
  return (
    <footer>
      <div className='footer-body'>
        <span>Copyright &copy; Endress+Hauser Process Solutions AG</span>
        <a
          href='https://netilion.endress.com/imprint'
          target='_blank'
          rel='noreferrer'
        >
          Imprint
        </a>
        <a
          href='https://netilion.endress.com/legal/terms-of-service'
          target='_blank'
          rel='noreferrer'
        >
          Terms of Service
        </a>
        <a
          href='https://netilion.endress.com/legal/privacy-policy'
          target='_blank'
          rel='noreferrer'
        >
          Privacy Policy
        </a>
      </div>
    </footer>
  );
}
