import Icon from '../Icon';
import BreadcrumbActionMenu from './BreadcrumbActionMenu';
import BreadcrumbItem from './BreadcrumbItem';
import ListItemAction from './ListItemAction';

export default function BreadcrumbItems({ options, dropdownCount, onClick }) {
  return (
    <>
      <Icon className='bread-crumb-icon-first' name='arrow-right' />
      {dropdownCount === 0 ? (
        options.map(item => (
          <BreadcrumbItem
            key={item.id}
            option={item}
            active={options[options.length - 1] === item}
            onClick={onClick}
          />
        ))
      ) : (
        <>
          <BreadcrumbItem
            key={options[0].id}
            option={options[0]}
            onClick={onClick}
          />
          <li>
            <BreadcrumbActionMenu
              id='breadcrumb-action-menu'
              actions={options?.slice(1, dropdownCount + 1).map(item => (
                <ListItemAction
                  key={item.id}
                  onClick={() => {
                    onClick(item);
                  }}
                >
                  {item.label}
                </ListItemAction>
              ))}
            />
            <Icon className='bread-crumb-icon-right' name='arrow-right' />
          </li>
          {options?.slice(dropdownCount + 1, options.length).map(item => (
            <BreadcrumbItem
              key={item.id}
              option={item}
              onClick={onClick}
              active={options[options.length - 1] === item}
            />
          ))}
        </>
      )}
    </>
  );
}
