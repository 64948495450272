import { StyleSheet, Text, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  fontSize: {
    fontSize: 12,
    marginRight: 5
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 'auto',
    width: 'auto',
    fontStyle: 'bold',
    marginBottom: 20
  },
  signLine1: {
    width: '18%',
    marginTop: 10,
    marginLeft: 5,
    marginRight: 5,
    borderBottomWidth: 1
  },
  signLine2: {
    width: '18%',
    borderBottomWidth: 1,
    marginTop: 10,
    marginRight: 5,
    marginLeft: 7
  },
  signLine3: {
    width: '18%',
    borderBottomWidth: 1,
    marginTop: 10,
    marginRight: 5,
    marginLeft: 5
  },
  signDate: {
    width: '18%',
    borderBottomWidth: 1,
    marginTop: 10,
    marginRight: 45,
    marginLeft: 5
  },
  signDate2: {
    width: '18%',
    borderBottomWidth: 1,
    marginTop: 10,
    marginRight: 25,
    marginLeft: 5
  },
  signDate3: {
    width: '18%',
    borderBottomWidth: 1,
    marginTop: 10,
    marginRight: 5,
    marginLeft: 5
  }
});
export const SignDate = () => {
  return (
    <View style={styles.row} key='1'>
      <Text style={styles.fontSize}>Datum: </Text>
      <Text style={styles.signDate} />

      <Text style={styles.fontSize}>Datum: </Text>
      <Text style={styles.signDate2} />

      <Text style={styles.fontSize}>Datum: </Text>
      <Text style={styles.signDate3} />
    </View>
  );
};
export const Sign = () => {
  return (
    <>
      <View style={styles.row} key='2'>
        <Text style={styles.fontSize}>Berufsbildner: </Text>
        <Text style={styles.signLine1} />

        <Text style={styles.fontSize}>Lernender: </Text>
        <Text style={styles.signLine2} />

        <Text style={styles.fontSize}>Eltern: </Text>
        <Text style={styles.signLine3} />
      </View>
    </>
  );
};
