import '../styles/Apprentice.css';

import { useEffect, useState } from 'react';
import { GoPencil } from 'react-icons/go';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import USERROLES from '../constants/UserRoles';
import { getUserInfo } from '../helpers/auth';
import { redirectUserWhenOnClick } from '../helpers/user';
import blankProfilePicture from '../res/blank_profile_picture.jpg';
import { ApiClient, CheckAuthorization } from '.';

export function Apprentice({
  userId,
  name,
  profilePicturePath,
  latestSemesterId,
  role
}) {
  const navigate = useNavigate();
  const [subjects, setSubjects] = useState();
  const { addToast } = useToasts();

  useEffect(() => {
    if (getUserInfo()?.user?.role?.code !== USERROLES.TRAINING_OFFICER)
      ApiClient.get(
        // eslint-disable-next-line max-len
        `users/${userId}/semesters/${latestSemesterId}/subjects?order_by=added_at`
      )
        .then(({ subjects }) => setSubjects(subjects))
        .catch(() =>
          addToast('Etwas ist schiefgelaufen', { appearance: 'error' })
        );
  }, [latestSemesterId, userId, addToast]);

  function getGradientColor(averageGrade) {
    if (!averageGrade) return '#E6ECF0';
    let red = -12.87 * Math.pow(averageGrade, 2) + 47.47 * averageGrade + 205.4;
    let green =
      -20.07 * Math.pow(averageGrade, 2) + 176.27 * averageGrade - 156.2;
    let blue = -1.53 * Math.pow(averageGrade, 2) + 12.13 * averageGrade - 10;

    return `rgb(${red}, ${green}, ${blue})`;
  }

  function getGradientColors() {
    return subjects
      ?.map(subject => getGradientColor(subject?.average))
      .toString();
  }

  return (
    <div
      key={`apprentice-${userId}`}
      className='apprentice'
      onClick={() =>
        redirectUserWhenOnClick(role, latestSemesterId, userId, navigate)
      }
    >
      <div
        className='profile-img'
        style={{
          backgroundImage: `url(${
            profilePicturePath ? profilePicturePath : blankProfilePicture
          })`
        }}
      />
      <div
        className='color-banner'
        style={{
          // eslint-disable-next-line max-len
          background:
            subjects?.length === 1
              ? getGradientColor(subjects[0].average)
              : `linear-gradient(to right, ${getGradientColors()})`
        }}
      />
      <label className='name'>
        {name}
        <CheckAuthorization
          roles={[USERROLES.ADMIN, USERROLES.APPRENTICE_LEADER]}
          shouldNavigate={false}
        >
          <span
            className='edit-icon'
            style={{ cursor: 'pointer' }}
            onClick={event => {
              event.stopPropagation();
              navigate(`users/${userId}/edit`);
            }}
          >
            <GoPencil />
          </span>
        </CheckAuthorization>
      </label>
      <CheckAuthorization
        roles={[
          USERROLES.ADMIN,
          USERROLES.APPRENTICE_LEADER,
          USERROLES.INSTRUCTOR,
          USERROLES.APPRENTICE,
          USERROLES.HR
        ]}
        shouldNavigate={false}
      >
        {subjects
          ?.filter(subject => !!subject.average)
          .slice(0, 2)
          .map(subject =>
            subject.average ? (
              <div className='last-grade' key={subject.name}>
                <p>{subject?.subject?.name}</p>
                <p>{Math.round(subject?.average * 100) / 100}</p>
              </div>
            ) : undefined
          )}
      </CheckAuthorization>
    </div>
  );
}
