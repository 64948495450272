/* eslint-disable max-len */
/* eslint-disable indent */
import '../styles/Plan.css';

import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import {
  ApiClient,
  Button,
  CheckAuthorization,
  LoadingIndicator,
  PopUp,
  SidePopUp,
  Timeline
} from '../components';
import { DAY_HEIGHT, DEFAULT_DURATION } from '../constants/Plan';
import USERROLES from '../constants/UserRoles';
import { getUserInfo } from '../helpers/auth';
import {
  assemble,
  getCalendar,
  getDifferenceDays,
  getPlannedDays,
  getStartDateForSidePopUp,
  updateProperties,
  uuidv4
} from '../helpers/plan';

export function Plan({ setIsEditMode, setBreadcrumbLinks }) {
  const { planId } = useParams();
  const { addToast } = useToasts();
  const [plan, setPlan] = useState();
  const [departments, setDepartments] = useState();
  const [selected, setSelected] = useState();
  const [calendarUser, setCalendarUser] = useState();
  const [calendar, setCalendar] = useState();
  const [isToolBoxVisible, setIsToolBoxVisible] = useState(false);
  const [isSidePopUpVisible, setIsSidePopUpVisible] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [isSidePopUpLoading, setIsSidePopUpLoading] = useState(false);
  const [isPlanRefreshing, setIsPlanRefreshing] = useState(false);
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [departmentToDelete, setDepartmentToDelete] = useState();
  const [isRemoving, setIsRemoving] = useState(false);

  useEffect(() => {
    document.title = 'AMP | Plan';
    if (
      getUserInfo()?.user?.role?.code !== USERROLES.APPRENTICE &&
      getUserInfo()?.user?.role?.code !== USERROLES.TRAINING_OFFICER
    )
      setIsEditMode(true);
    else setIsEditMode(false);

    ApiClient.get(`plans/${planId}`)
      .then(plan => {
        const calendarUser = {
          startDate: plan.plan.user
            ? plan.plan.user.apprenticeship_start
            : '2020-08-01',
          endDate: plan.plan.user
            ? moment(plan.plan.user.apprenticeship_start)
                .add(4, 'years')
                .format('YYYY') + '-07-31'
            : '2024-07-31'
        };
        const result = assemble(plan.departments);
        if (plan?.plan?.user) {
          setBreadcrumbLinks([
            {
              id: 1,
              label: `${plan?.plan?.user?.firstname} ${plan?.plan?.user?.lastname}`,
              path: `/users/${plan?.plan?.user?.id}/semesters`
            },
            {
              id: 2,
              label: 'Ausbildungsplan',
              path: `/users/${plan?.plan?.user?.id}/plan/${planId}`
            }
          ]);
        } else {
          setBreadcrumbLinks([
            {
              id: 1,
              label: 'Berufe anzeigen',
              path: '/professions'
            },
            {
              id: 2,
              label: plan?.plan?.profession?.name,
              path: `/professions/${plan?.plan?.profession?.id}/subjects`
            },
            {
              id: 3,
              label: 'Basisplan',
              path: `/plan/${plan.id}`
            }
          ]);
        }
        setPlan({
          ...plan.plan,
          departments: updateProperties(result, calendarUser)
        });
        setSelected(updateProperties(result, calendarUser));
        setCalendarUser(calendarUser);
        setCalendar(getCalendar(calendarUser));
      })
      .catch(() =>
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
      );

    if (
      getUserInfo()?.user?.role?.code !== USERROLES.APPRENTICE &&
      getUserInfo()?.user?.role?.code !== USERROLES.TRAINING_OFFICER
    )
      ApiClient.get('departments')
        .then(departments => setDepartments(departments))
        .catch(() =>
          addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
        );

    return () => {
      setIsEditMode(false);
    };
  }, [addToast, planId, setBreadcrumbLinks, setIsEditMode]);

  function TimeLine({ calendar }) {
    const timeline = useMemo(
      () => <Timeline calendar={calendar} />,
      [calendar]
    );
    return timeline;
  }

  function handleOnDragEnds(result) {
    if (!result.destination) return;

    if (
      result.source.droppableId === 'selected' &&
      result.destination.droppableId === 'selected'
    ) {
      const items = [...selected];
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      setSelected(updateProperties(items, calendarUser));
    } else if (
      result.source.droppableId === 'departments' &&
      result.destination.droppableId === 'selected'
    ) {
      const chars = [...departments];
      let sel = [...selected];
      let reorderedItem = { ...chars[result.source.index] };
      reorderedItem.id = uuidv4();

      reorderedItem.duration = reorderedItem.duration
        ? reorderedItem.duration
        : DEFAULT_DURATION;

      sel.splice(result.destination.index, 0, reorderedItem);

      sel = updateProperties(sel, calendarUser);
      setSelected(sel);
      setPlan({ ...plan, departments: [...sel] });

      reorderedItem = sel.find(x => x.id === reorderedItem.id);

      ApiClient.post(`plans/${planId}`, {
        parent: reorderedItem.parent,
        version_id: reorderedItem.version_id
      })
        .then(department => {
          sel[sel.findIndex(x => x.id === reorderedItem.id)] = {
            ...reorderedItem,
            ...department
          };
          sel = updateProperties(sel, calendarUser);
          setSelected([...sel]);
          setPlan({ ...plan, departments: [...sel] });

          ApiClient.patch(`plans/${planId}`, { departments: [...sel] })
            .then(() =>
              addToast('Erfolgreich erstellt', { appearance: 'success' })
            )
            .catch(() =>
              addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
            );
        })
        .catch(() =>
          addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
        );
    }
  }

  function handleSidePopUpClose() {
    setIsSidePopUpVisible(false);

    setTimeout(() => {
      setSelectedDepartment();
    }, 250);
  }

  function handleSidePopUpSubmit(range) {
    setIsSidePopUpLoading(true);

    if (
      selectedDepartment.parent &&
      moment(selected.find(x => x.id === selectedDepartment.parent).endDate)
        .add(1, 'days')
        .format('YYYY-MM-DD') !== moment(range.startDate).format('YYYY-MM-DD')
    ) {
      setIsSidePopUpLoading(false);
      addToast(
        'Das Startdatum darf keinen hohlraum zwischen der vorherigen und der aktuellen Abteilung erstellen.',
        { appearance: 'error' }
      );
      return;
    }

    if (
      !selectedDepartment.parent &&
      moment(range.startDate).format('YYYY-MM-DD') !==
        moment(calendarUser.startDate).format('YYYY-MM-DD')
    ) {
      setIsSidePopUpLoading(false);
      addToast(
        'Das Startdatum darf keinen hohlraum zwischen dem Ausbildungsstart und der aktuellen Abteilung erstellen.',
        { appearance: 'error' }
      );
      return;
    }

    let duration =
      moment(range.endDate).diff(moment(range.startDate), 'days') + 1;

    if (duration > 7) {
      ApiClient.patch(`plans/${planId}/departments/${selectedDepartment.id}`, {
        duration
      })
        .then(() => {
          let sel = [...selected];
          sel.find(
            department => department.id === selectedDepartment.id
          ).duration = Number.parseInt(duration);
          setSelected(updateProperties([...sel], calendarUser));

          addToast('Erfolgreich bearbeitet!', { appearance: 'success' });
          handleSidePopUpClose();
        })
        .catch(() =>
          addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
        )
        .finally(() => setIsSidePopUpLoading(false));
    } else {
      addToast('Die Mindestlänge beträgt 7 Tage!', { appearance: 'error' });
      setIsSidePopUpLoading(false);
    }
  }

  function handleRemoveDepartment() {
    setIsRemoving(true);
    ApiClient.delete(`plans/${planId}/departments/${departmentToDelete.id}`)
      .then(() => {
        let sel = [...selected];
        const index = sel.findIndex(
          department => department.id === departmentToDelete.id
        );
        if (selected.length !== 1)
          if (index === 0) {
            // eslint-disable-next-line unicorn/no-null
            sel[index + 1].parent = null;
          } else if (index !== sel.length - 1) {
            sel[index + 1].parent = sel[index - 1].parent;
          }

        sel = sel.filter(department => department.id !== departmentToDelete.id);
        sel = updateProperties([...sel], calendarUser);

        savePlan(sel)
          .then(() => {
            setIsDeleteVisible(false);
            setDepartmentToDelete();
            addToast('Abteilung erfolgreich entfernt!', {
              appearance: 'success'
            });
          })
          .catch(() =>
            addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
          )
          .finally(() => setIsRemoving(false));
      })
      .catch(() => {
        setIsRemoving(false);
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' });
      });
  }

  function savePlan(array) {
    console.log(plan);
    console.log(calendarUser);
    setIsPlanRefreshing(true);

    const arrayToSave = array || selected;
    console.log(arrayToSave);
    return new Promise((resolve, reject) => {
      ApiClient.patch(`plans/${planId}`, {
        apprenticeId: plan.user.id,
        departments: [...arrayToSave]
      })
        .then(() => {
          setSelected([...arrayToSave]);
          setPlan({ ...plan, departments: [...arrayToSave] });
          setIsPlanRefreshing(false);
          resolve();
        })
        .catch(() => {
          setIsPlanRefreshing(false);
          reject();
        });
    });
  }

  function handleSave() {
    savePlan()
      .then(() =>
        addToast('Plan erfolgreich gespeichert!', {
          appearance: 'success'
        })
      )
      .catch(() =>
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
      );
  }

  return (
    <>
      {isPlanRefreshing && (
        <div className='plan-refreshing'>
          <LoadingIndicator height={50} width={50} mode='darker' />
        </div>
      )}
      {selectedDepartment && (
        <SidePopUp
          selectedDepartment={selectedDepartment}
          handleClose={handleSidePopUpClose}
          isSidePopUpVisible={isSidePopUpVisible}
          startDate={getStartDateForSidePopUp(selectedDepartment, selected)}
          endDate={calendarUser?.endDate}
          disabled={true}
          loading={isSidePopUpLoading}
          handleSubmit={handleSidePopUpSubmit}
        />
      )}
      <div
        className={`plan-container${
          getUserInfo()?.user?.role?.code === USERROLES.APPRENTICE ||
          getUserInfo()?.user?.role?.code === USERROLES.TRAINING_OFFICER
            ? ' azubi'
            : ' admin'
        }`}
      >
        {isDeleteVisible && (
          <PopUp
            title='Abteilung entfernen'
            description={`Sind Sie sicher, dass Sie die Abteilung: ${departmentToDelete.name} aus dem Plan entfernen möchten?`}
            handleCancel={() => {
              setIsDeleteVisible(false);
              setDepartmentToDelete();
            }}
            handleSubmit={handleRemoveDepartment}
            loading={isRemoving}
            disabled={isRemoving}
            submitLabel='Entfernen'
          />
        )}
        {plan &&
        calendar &&
        (getUserInfo().user.role.code !== USERROLES.APPRENTICE &&
        getUserInfo()?.user?.role?.code !== USERROLES.TRAINING_OFFICER
          ? departments
          : true) &&
        selected ? (
          <DragDropContext
            onDragStart={() => isToolBoxVisible && setIsToolBoxVisible(false)}
            onDragEnd={handleOnDragEnds}
          >
            <Droppable
              droppableId='selected'
              isDropDisabled={
                getUserInfo()?.user.role.code === USERROLES.APPRENTICE ||
                getUserInfo()?.user?.role?.code === USERROLES.TRAINING_OFFICER
              }
            >
              {provided => (
                <div
                  onClick={() => isToolBoxVisible && setIsToolBoxVisible(false)}
                  className='planView-container'
                >
                  <TimeLine calendar={calendar} />
                  <ul
                    className='planView'
                    {...{
                      ...provided.draggableProps,
                      style: {
                        minHeight: DAY_HEIGHT * calendar.length
                      }
                    }}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                  >
                    {selected?.map((department, index) => (
                      <Draggable
                        key={department.id}
                        draggableId={department.id?.toString()}
                        index={index}
                        isDragDisabled={
                          getUserInfo()?.user.role.code ===
                            USERROLES.APPRENTICE ||
                          getUserInfo()?.user?.role?.code ===
                            USERROLES.TRAINING_OFFICER
                        }
                      >
                        {provided => (
                          <li
                            onClick={() => {
                              setSelectedDepartment(department);
                              setIsSidePopUpVisible(true);
                            }}
                            id={department.id}
                            className='plan-department'
                            ref={provided.innerRef}
                            {...{
                              ...provided.draggableProps,
                              style: {
                                ...provided.draggableProps.style,
                                backgroundColor: department.background_color,
                                height: DAY_HEIGHT * department.duration
                              }
                            }}
                            {...provided.dragHandleProps}
                          >
                            <CheckAuthorization
                              roles={[
                                USERROLES.ADMIN,
                                USERROLES.APPRENTICE_LEADER,
                                USERROLES.INSTRUCTOR,
                                USERROLES.HR
                              ]}
                            >
                              <RiDeleteBin5Line
                                onClick={event => {
                                  event.stopPropagation();
                                  setDepartmentToDelete(department);
                                  setIsDeleteVisible(true);
                                }}
                                className='planner-delete-icon'
                              />
                            </CheckAuthorization>
                            <div>
                              <label>{department.name}</label>
                              <p>{department.subjects}</p>
                            </div>
                            <span>Dauer: {department.duration} Tage</span>
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                </div>
              )}
            </Droppable>
            <CheckAuthorization
              roles={[
                USERROLES.ADMIN,
                USERROLES.APPRENTICE_LEADER,
                USERROLES.INSTRUCTOR,
                USERROLES.HR
              ]}
            >
              {getDifferenceDays(calendar, selected) < 0 && (
                <div className='overplanned'>
                  <label>Die geplante Dauer geht über die Lehre hinaus!</label>
                </div>
              )}
              {departments && (
                <div
                  className={`toolbox-container${
                    isToolBoxVisible ? ' open' : ''
                  }`}
                >
                  <div
                    className='toolbox-opener'
                    onClick={() => setIsToolBoxVisible(!isToolBoxVisible)}
                  >
                    <div />
                    <div />
                  </div>
                  <div className='toolbox-departments'>
                    <Droppable droppableId='departments' isDropDisabled={true}>
                      {(provided, snapshot) => (
                        <div>
                          <ul
                            className='toolbox'
                            ref={provided.innerRef}
                            isdraggingover={snapshot.isDraggingOver?.toString()}
                          >
                            <h2>Abteilungen</h2>
                            {departments.length === 0 ? (
                              <label>
                                Es existieren noch keine Abteilungen!
                              </label>
                            ) : (
                              departments?.map((department, index) => (
                                <Draggable
                                  key={department.name}
                                  draggableId={department.name}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <>
                                      <li
                                        className='department'
                                        ref={provided.innerRef}
                                        {...{
                                          ...provided.draggableProps,
                                          style: {
                                            ...provided.draggableProps.style,
                                            backgroundColor:
                                              department.background_color
                                          }
                                        }}
                                        {...provided.dragHandleProps}
                                      >
                                        {department.name}
                                      </li>
                                      {snapshot.isDragging && (
                                        <li
                                          style={{
                                            backgroundColor:
                                              department.background_color
                                          }}
                                          className='department'
                                        >
                                          {department.name}
                                        </li>
                                      )}
                                    </>
                                  )}
                                </Draggable>
                              ))
                            )}
                          </ul>
                        </div>
                      )}
                    </Droppable>
                  </div>
                  <div className='toolbox-plan-details'>
                    <div>
                      <h2>Plan Details</h2>
                      <label>
                        {plan?.user
                          ? `Lernender: ${plan.user?.firstname} ${plan.user?.lastname}`
                          : `Beruf: ${plan.profession?.name}`}
                      </label>
                      <label>{getPlannedDays(calendar, selected)}</label>
                    </div>
                    <Button
                      disabled={
                        JSON.stringify(plan.departments) ===
                        JSON.stringify(selected)
                      }
                      text='Speichern'
                      className='hero'
                      onClick={handleSave}
                    />
                  </div>
                </div>
              )}
            </CheckAuthorization>
          </DragDropContext>
        ) : (
          <div className='plan-loading'>
            <LoadingIndicator height={30} width={30} mode='darker' />
          </div>
        )}
      </div>
    </>
  );
}
