/* eslint-disable unicorn/no-useless-undefined */
const createStyle = (color, size, customStyle) => {
  if (color || size || customStyle) {
    const computedStyle = customStyle ? { ...customStyle } : {};

    if (color) {
      computedStyle.color = color;
    }

    if (size) {
      computedStyle.fontSize = `${size}px`;
      computedStyle.lineHeight = `${size}px`;
    }

    return computedStyle;
  }

  return undefined;
};

export default function Icon({ id, name, color, style, className, size }) {
  return (
    <i
      id={id}
      className={[className, 'icon', `icon-${name}`].filter(c => c).join(' ')}
      style={createStyle(color, size, style)}
    />
  );
}
