import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import forbidden from '../res/forbidden.png';

export function Forbidden({ setBreadcrumbLinks }) {
  useEffect(() => {
    setBreadcrumbLinks([
      {
        id: 1,
        label: 'Forbidden',
        path: '/403'
      }
    ]);
  }, [setBreadcrumbLinks]);

  return (
    <div className='forbidden container'>
      <div className='content'>
        <div>
          <h3>Access denied!</h3>
        </div>
        <div>
          <div>
            <h2>Sie sind nicht berechtigt, auf diese Seite zuzugreifen.</h2>
            <p>
              Gehe Sie zurück zur <Link to='/'>Startseite</Link> oder melden Sie
              dieses Problem Ihrem Vorgesetzten.
            </p>
          </div>
          <img src={forbidden} alt='Forbidden' />
        </div>
      </div>
    </div>
  );
}
