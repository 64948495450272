/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable unicorn/no-null */
import '../styles/SubjectCreate.css';
import '../styles/SubjectProfessionAssign.css';

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import {
  ApiClient,
  Button,
  CheckAuthorization,
  CheckIfLoggedIn,
  Input
} from '../components';
import USERROLES from '../constants/UserRoles';

export function SubjectProfessionAssign({ setBreadcrumbLinks }) {
  let { addToast } = useToasts();
  const navigate = useNavigate();
  const { professionId } = useParams();

  const [subjects, setSubjects] = useState(null);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [error, setError] = useState('');
  const [profession, setProfession] = useState(null);
  const [associations, setAssociations] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    ApiClient.get(`professions/${professionId}`)
      .then(profession => {
        setBreadcrumbLinks([
          { id: 1, label: 'Berufe anzeigen', path: '/professions' },
          {
            id: 2,
            label: profession.name,
            path: `/professions/${professionId}/subjects`
          },
          {
            id: 3,
            label: 'Fächer',
            path: `/professions/${professionId}/subjects`
          }
        ]);
        setProfession(profession);
      })
      .catch(() =>
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
      );
    ApiClient.get('subjects')
      .then(({ subjects }) => setSubjects(subjects))
      .catch(() =>
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
      );
    updateAssociations();
  }, []);

  useEffect(() => {
    setError('');
  }, [selectedSubjects]);

  function updateAssociations() {
    ApiClient.get(`professions/${professionId}/subjects`)
      .then(({ subjects }) => setAssociations(subjects))
      .catch(() =>
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
      );
  }

  function handleSubmitClicked() {
    if (Array.isArray(selectedSubjects) && selectedSubjects.length === 0) {
      return setError('Bitte mindestens ein Fach zum Hinzufügen auswählen!');
    }

    setLoading(true);
    ApiClient.post(`professions/${professionId}/subjects`, {
      subjects: selectedSubjects.map(subject => subject.value)
    })
      .then(() => {
        addToast(
          (selectedSubjects.length === 1
            ? selectedSubjects[0].label
            : 'Fächer') + ' erfolgreich hinzugefügt!',
          {
            appearance: 'success'
          }
        );
        setSelectedSubjects([]);
        updateAssociations();
      })
      .catch(() =>
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
      )
      .finally(() => setLoading(false));
  }

  let options = subjects
    ?.filter(
      subject =>
        !associations.map(association => association.id).includes(subject.id)
    )
    .map(subject => ({
      value: subject.id,
      label: subject.name
    }));

  return (
    <CheckIfLoggedIn>
      <CheckAuthorization
        roles={[
          USERROLES.ADMIN,
          USERROLES.APPRENTICE_LEADER,
          USERROLES.INSTRUCTOR
        ]}
        shouldNavigate={true}
      >
        <div className='create-subject container'>
          <div className='content'>
            <h1>Fächer {profession ? `von ${profession?.name}` : ''}</h1>
            <h2>Mit dem Beruf assozierte Fächer</h2>
            {associations.length > 0 ? (
              associations.map(associatedSubject => (
                <div
                  key={associatedSubject.id}
                  className='association-subject-item'
                >
                  <label>{associatedSubject.name}</label>
                </div>
              ))
            ) : (
              <p>Es wurden noch keine Fächer zugewiesen.</p>
            )}
            <h2>Fächer hinzufügen</h2>
            <div className='create-subject-form'>
              <Input
                error={error}
                placeholder='Fach / Fächer'
                onChange={setSelectedSubjects}
                select
                required
                value={selectedSubjects}
                options={options}
                isMulti
                key={options?.length}
              />
            </div>
            <div className='button-group'>
              <Button
                text='Hinzufügen'
                disabled={loading}
                loading={loading}
                className='hero'
                onClick={handleSubmitClicked}
              />
              <Button
                text='Abbrechen'
                disabled={false}
                loading={false}
                className=''
                onClick={() => navigate('/professions')}
              />
            </div>
          </div>
        </div>
      </CheckAuthorization>
    </CheckIfLoggedIn>
  );
}
