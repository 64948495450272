/* eslint-disable unicorn/prefer-dom-node-append */
/* eslint-disable unicorn/prefer-query-selector */
/* eslint-disable indent */
import '../styles/Multiplan.css';

import moment from 'moment';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import {
  ApiClient,
  CheckAuthorization,
  CheckIfLoggedIn,
  LoadingIndicator,
  Timeline
} from '../components';
import { DAY_HEIGHT } from '../constants/Plan';
import USERROLES from '../constants/UserRoles';
import { getUserInfo } from '../helpers/auth';
import { assemble, getCalendar, updateProperties } from '../helpers/plan';

export function Multiplan({
  setHideBreadcrumb,
  setBreadcrumbLinks,
  setIsEditMode
}) {
  const [plans, setPlans] = useState();
  const [calendar, setCalendar] = useState();

  const { addToast } = useToasts();
  const navigate = useNavigate();
  function formatDate(dateString) {
    return new Date(dateString).toLocaleDateString('default', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  }

  useEffect(() => {
    document.title = 'AMP | Home';
    setIsEditMode(true);
    setBreadcrumbLinks([
      {
        id: 1,
        label: 'Multiplan',
        path: '/multiplan'
      }
    ]);
    ApiClient.get(
      getUserInfo()?.user?.role?.code === USERROLES.ADMIN
        ? 'users'
        : 'users/apprentices'
    )
      .then(({ users }) => {
        users = users.filter(user => user.plan_id);
        users.sort(
          (a, b) =>
            new Date(a.apprenticeship_start) - new Date(b.apprenticeship_start)
        );
        const dates = {
          startDate: users[0].apprenticeship_start,
          endDate:
            moment(users[users.length - 1].apprenticeship_start)
              .add(4, 'years')
              .format('YYYY') + '-07-31'
        };
        setCalendar({
          dates,
          calendar: getCalendar({
            ...dates
          })
        });
        Promise.all(users.map(user => ApiClient.get(`plans/${user.plan_id}`)))
          .then(plans => {
            setPlans(
              plans.map(plan => {
                const result = assemble(plan.departments);
                const calendarUser = {
                  ...plan.plan.user,
                  startDate: plan.plan.user
                    ? plan.plan.user.apprenticeship_start
                    : '2020-08-01',
                  endDate: plan.plan.user
                    ? moment(plan.plan.user.apprenticeship_start)
                        .add(4, 'years')
                        .format('YYYY') + '-07-31'
                    : '2024-07-31'
                };
                return {
                  plan: {
                    ...plan.plan,
                    user: {
                      ...plan.plan.user,
                      ...calendarUser
                    }
                  },
                  departments: updateProperties(result, calendarUser)
                };
              })
            );
            let element = document.getElementById(
              moment().format('YYYY-MM-DD')
            );
            if (element) {
              let div = document.createElement('div');
              div.classList.add('highlight');
              element.appendChild(div);
              setTimeout(() => {
                document
                  .getElementById(
                    moment().subtract(25, 'days').format('YYYY-MM-DD')
                  )
                  .scrollIntoView();
              }, 100);
            }
          })
          .catch(() =>
            addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
          );
      })
      .catch(() =>
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
      );
    return () => {
      setIsEditMode(false);
    };
  }, [
    addToast,
    navigate,
    setBreadcrumbLinks,
    setHideBreadcrumb,
    setIsEditMode
  ]);

  return (
    <CheckIfLoggedIn>
      <CheckAuthorization
        roles={[
          USERROLES.ADMIN,
          USERROLES.APPRENTICE_LEADER,
          USERROLES.INSTRUCTOR,
          USERROLES.HR,
          USERROLES.TRAINING_OFFICER
        ]}
        shouldNavigate
        path='/404'
      >
        <div className='multiplan'>
          {plans && calendar ? (
            <DragDropContext>
              <Droppable droppableId='selected' isDropDisabled>
                {provided => (
                  <div className='planView-container'>
                    <Timeline flip calendar={calendar.calendar} />
                    <div className='plans-view'>
                      <div className='plan-spacer' />
                      {plans.map((plan, index) => {
                        const cal = getCalendar(plan.plan.user);
                        return (
                          <div key={index} className='multiplan-plan-container'>
                            <h3>
                              {plan.plan.user.firstname}{' '}
                              {plan.plan.user.lastname}
                            </h3>
                            <ul
                              className='planView'
                              {...{
                                ...provided.draggableProps,
                                style: {
                                  minHeight: DAY_HEIGHT * cal.length,
                                  marginTop:
                                    index === 0
                                      ? 0
                                      : (getCalendar({
                                          startDate: calendar.dates.startDate,
                                          endDate: plan.plan.user.startDate
                                        }).length -
                                          1) *
                                        DAY_HEIGHT
                                }
                              }}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              {plan.departments?.map((department, index) => (
                                <Draggable
                                  key={department.id}
                                  draggableId={department.id?.toString()}
                                  index={index}
                                  isDragDisabled
                                >
                                  {provided => (
                                    <li
                                      id={department.id}
                                      className='plan-department'
                                      ref={provided.innerRef}
                                      {...{
                                        ...provided.draggableProps,
                                        style: {
                                          ...provided.draggableProps.style,
                                          backgroundColor:
                                            department.background_color,
                                          height:
                                            DAY_HEIGHT * department.duration
                                        }
                                      }}
                                      {...provided.dragHandleProps}
                                    >
                                      <div>
                                        <label>{department.name}</label>
                                        <p>
                                          {formatDate(department.startDate) +
                                            ' - ' +
                                            formatDate(department.endDate)}
                                        </p>
                                        <p>{department.subjects}</p>
                                      </div>
                                      <span>
                                        Dauer: {department.duration} Tage
                                      </span>
                                    </li>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </ul>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <div className='plan-loading'>
              <LoadingIndicator height={30} width={30} mode='darker' />
            </div>
          )}
        </div>
      </CheckAuthorization>
    </CheckIfLoggedIn>
  );
}
