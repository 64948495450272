import moment from 'moment';

import { DEFAULT_DURATION } from '../constants/Plan';

export function getDaysBetweenDates(startDate, endDate) {
  var now = startDate.clone(),
    dates = [];

  while (now.isSameOrBefore(endDate)) {
    dates.push({
      date: now.format('YYYY-MM-DD'),
      cw: now.isoWeek()
    });
    now.add(1, 'day');
  }
  return dates;
}

// eslint-disable-next-line unicorn/no-null
export function assemble(array, parentId = null, result = []) {
  for (const element of array) {
    if (element.parent === parentId) {
      result.push(element);
      assemble(array, element.id, result);
    }
  }
  return result;
}

export function getDifferenceDays(calendar, selected) {
  let count = 0;
  for (const value of selected) {
    count += value.duration;
  }
  return calendar.length - count;
}

export function getStartDateForSidePopUp(selectedDepartment, selected) {
  if (selectedDepartment?.parent)
    return moment(
      selected.find(x => x.id === selectedDepartment.parent).endDate
    ).add(1, 'days');
  return selectedDepartment?.startDate;
}

export function updateProperties(array, user) {
  let start = user.startDate;
  return array.map((item, index) => {
    item.parent = index !== 0 ? array[index - 1].id : undefined;
    item.startDate = start;
    item.endDate = moment(start)
      .add(item.duration - 1 || DEFAULT_DURATION, 'days')
      .format('YYYY-MM-DD');
    start = moment(start).add(item.duration, 'days').format('YYYY-MM-DD');
    return item;
  });
}

export function getPlannedDays(calendar, selected) {
  const differenceDays = getDifferenceDays(calendar, selected);
  return `Tage ${differenceDays < 0 ? 'überplant' : 'ungeplant'}: ${
    differenceDays < 0 ? differenceDays * -1 : differenceDays
  }`;
}

export function getCalendar(user) {
  let dates = getDaysBetweenDates(moment(user.startDate), moment(user.endDate));
  return dates.sort((a, b) => new Date(b.date) + new Date(a.date));
}

export function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}
