import '../styles/DepartmentView.css';

import { useEffect, useState } from 'react';
import { GoPencil } from 'react-icons/go';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { useNavigate, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import {
  ApiClient,
  CheckAuthorization,
  CheckIfLoggedIn,
  LoadingIndicator,
  PopUp
} from '../components';
import USERROLES from '../constants/UserRoles';

export function DepartmentView({ setBreadcrumbLinks }) {
  const [department, setDepartment] = useState();
  const [showDeletePopUp, setShowDeletePopUp] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { addToast } = useToasts();
  const navigate = useNavigate();
  const { departmentId } = useParams();

  useEffect(() => {
    ApiClient.get(`/departments/${departmentId}`)
      .then(department => {
        setBreadcrumbLinks([
          { id: 1, label: 'Abteilungen anzeigen', path: '/departments' },
          {
            id: 2,
            label: department.name,
            path: `/departments/${departmentId}`
          }
        ]);
        setDepartment(department);
      })
      .catch(() =>
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
      );
  }, [addToast, departmentId, setBreadcrumbLinks]);

  function handleDelete() {
    setIsDeleting(true);
    ApiClient.delete(`/departments/${departmentId}`)
      .then(() => {
        addToast('Abteilung erfolgreich gelöscht!', { appearance: 'success' });
        navigate('/departments');
      })
      .catch(() =>
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
      )
      .finally(() => setIsDeleting(false));
  }

  return (
    <CheckIfLoggedIn>
      <CheckAuthorization
        roles={[
          USERROLES.ADMIN,
          USERROLES.APPRENTICE_LEADER,
          USERROLES.INSTRUCTOR
        ]}
        shouldNavigate={true}
      >
        <div className='departmentView container'>
          {showDeletePopUp ? (
            <PopUp
              title={department.name + ' löschen'}
              description={
                <span>
                  Sind Sie sicher, dass Sie die{' '}
                  <div>Abteilung: {department.name}</div>
                  löschen möchten?
                </span>
              }
              submitLabel='Löschen'
              handleCancel={() => setShowDeletePopUp(false)}
              handleSubmit={handleDelete}
              disabled={isDeleting}
              loading={isDeleting}
            />
          ) : undefined}
          <div className='content'>
            {department ? (
              <>
                <h1 className='title'>
                  {department.name}
                  <div className='departmentView-title-icons'>
                    <RiDeleteBin5Line
                      onClick={() => setShowDeletePopUp(true)}
                      className='title-icon'
                    />
                    <GoPencil
                      onClick={() =>
                        navigate(`/departments/${departmentId}/edit`)
                      }
                      className='title-icon'
                    />
                  </div>
                </h1>
                <div className='departmentView-non-form'>
                  <div className='non-form'>
                    <label>Themen</label>
                    <p>{department.subjects}</p>
                  </div>
                  <div className='non-form'>
                    <label>Ausbildungsbeauftragter</label>
                    <span>{department.training_officer.name}</span>
                  </div>
                  <div className='non-form'>
                    <label>Kontaktperson</label>
                    <span>{department.contact_person.name}</span>
                  </div>
                  <div className='non-form'>
                    <label>Hintergrundfarbe</label>
                    <span>
                      <div
                        style={{
                          height: 30,
                          width: 30,
                          backgroundColor: department.background_color
                        }}
                      />{' '}
                      {department.background_color}
                    </span>
                  </div>
                  <div className='non-form'>
                    <label>Programmiersprachen</label>
                    <div className='departmentView-programmingLanguages'>
                      {department.programming_languages.map(
                        programmingLanguage => (
                          <div
                            key={`ProgrammingLang-${programmingLanguage.id}`}
                          >
                            {programmingLanguage.programming_language.name}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <LoadingIndicator height={25} width={25} mode='darker' />
            )}
          </div>
        </div>
      </CheckAuthorization>
    </CheckIfLoggedIn>
  );
}
