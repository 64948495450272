import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import {
  ApiClient,
  CheckAuthorization,
  CheckIfLoggedIn,
  LoadingIndicator,
  Row,
  Table
} from '../components';
import UserRoles from '../constants/UserRoles';

export function SemestersOverview({ setBreadcrumbLinks, oldBreadcrumbLinks }) {
  const { addToast } = useToasts();
  const [semesters, setSemesters] = useState();
  const [oldBreadcrumb, setOldBreadcrumb] = useState();
  const { userId } = useParams();
  const navigate = useNavigate();
  const getLastCharFromURL =
    oldBreadcrumb?.length > 0
      ? /[^/]*$/.exec(oldBreadcrumb[oldBreadcrumb.length - 1].path)[0]
      : '';
  const isLastCharDigit = !Number.isNaN(
    Number.parseInt(getLastCharFromURL, 10)
  );
  const isFirstRender = useRef(true);

  console.log(getLastCharFromURL);

  useEffect(() => {
    if (isFirstRender.current) {
      setOldBreadcrumb(oldBreadcrumbLinks);
      isFirstRender.current = false;
    }
  }, [oldBreadcrumbLinks]);

  useEffect(() => {
    Promise.all([
      ApiClient.get(`users/${userId}`),
      ApiClient.get(`users/${userId}/semesters`)
    ])
      .then(([user, { semesters }]) => {
        setBreadcrumbLinks([
          {
            id: 1,
            label: `${user.firstname} ${user.lastname}`,
            path: `/users/${userId}/semesters`
          },
          {
            id: 2,
            label: 'Alle Semester',
            path: `/users/${userId}/semesters`
          }
        ]);
        setSemesters(semesters);
      })
      .catch(() =>
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
      );
  }, [addToast, setBreadcrumbLinks, userId]);

  console.log(getLastCharFromURL);

  return (
    <CheckIfLoggedIn>
      <CheckAuthorization
        roles={[
          UserRoles.ADMIN,
          UserRoles.APPRENTICE,
          UserRoles.APPRENTICE_LEADER,
          UserRoles.HR,
          UserRoles.INSTRUCTOR
        ]}
        shouldNavigate
        path='/403'
      >
        <div className='subjectOverview container'>
          <div className='content'>
            <h1 className='title'>Alle Semester</h1>
            {semesters ? (
              Array.isArray(semesters) && semesters.length > 0 ? (
                <Table leftAlign>
                  {semesters.map(semester => (
                    <Row
                      key={semester.id}
                      data={[`Semester ${semester.index}`]}
                      onClick={() =>
                        getLastCharFromURL && !isLastCharDigit
                          ? navigate(`${semester.id}/${getLastCharFromURL}`)
                          : navigate(`${semester.id}/subjects`)
                      }
                    />
                  ))}
                </Table>
              ) : (
                <h2>Keine Semester vorhanden!</h2>
              )
            ) : (
              <LoadingIndicator height={25} width={25} mode='darker' />
            )}
          </div>
        </div>
      </CheckAuthorization>
    </CheckIfLoggedIn>
  );
}
