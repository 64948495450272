import '../styles/Timeline.css';

import { useRef } from 'react';

import { COLORS, DAY_HEIGHT } from '../constants/Plan';

export function Timeline({ flip, calendar }) {
  let colorReference = useRef(0);

  let month = 1;
  let year = 1;
  function getTimeLineNumber(day, index) {
    if (day.date.split('-')[2] === '01' || index === 0) {
      if (month === 12 || index === 0) {
        month = 1;
        let temporaryYear = year;
        year++;
        return (
          <span>
            {flip ? day.date.split('-')[0] : temporaryYear + 'Lj.'} / {month}{' '}
            Mt.
          </span>
        );
      }
      month++;
      return <span>{month} Mt.</span>;
    }
  }

  return (
    <>
      <div className='kw'>
        {calendar.map((day, index) => (
          <div
            id={day.date}
            key={day.date}
            style={{
              minHeight: DAY_HEIGHT,
              height: DAY_HEIGHT,
              marginTop: !calendar[index + 1] && 10,
              borderBottom:
                calendar[index + 1] &&
                day.cw !== calendar[index + 1]?.cw &&
                '1px solid #aaa'
            }}
          >
            {calendar[index + 1] ? (
              day.cw !== calendar[index + 1]?.cw ? (
                <span>KW {day.cw}</span>
              ) : undefined
            ) : (
              <span>KW {day.cw}</span>
            )}
          </div>
        ))}
      </div>
      <div className='timeline'>
        {calendar.map((day, index) => {
          if (index !== 0 && day.date.slice(5) === '08-01')
            colorReference.current++;
          return (
            <div title={day.date} key={day.date}>
              <div
                key={day.date}
                id={day.date}
                style={{
                  backgroundColor: COLORS[colorReference.current],
                  minHeight: DAY_HEIGHT,
                  height: DAY_HEIGHT
                }}
              ></div>
              {getTimeLineNumber(day, index)}
            </div>
          );
        })}
      </div>
    </>
  );
}
