import '../styles/Table.css';

import PropTypes from 'prop-types';

export function Table({ thead, children, leftAlign }) {
  return (
    <table className={leftAlign ? 'table left-align' : 'table'}>
      {thead ? (
        <thead>
          <tr>
            {thead.map((value, index) => (
              <th key={`th-${index}`}>{value}</th>
            ))}
          </tr>
        </thead>
      ) : undefined}
      <tbody>{children}</tbody>
    </table>
  );
}

Table.propTypes = {
  thead: PropTypes.array,
  children: PropTypes.array,
  leftAlign: PropTypes.bool
};
