/* eslint-disable react/display-name */
/* eslint-disable unicorn/prevent-abbreviations */
import { forwardRef } from 'react';

import Icon from './Icon';

const IconButton = forwardRef(
  ({ id, icon, disabled, onClick, className, style }, ref) => (
    <button
      id={id}
      className={[className, 'icon-button'].filter(c => c).join(' ')}
      style={style}
      onClick={onClick}
      disabled={disabled}
      // eslint-disable-next-line react/button-has-type
      type='button'
      ref={ref}
    >
      <Icon name={icon} />
    </button>
  )
);

export default IconButton;
