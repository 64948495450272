import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import {
  ApiClient,
  Button,
  CheckAuthorization,
  CheckIfLoggedIn,
  Input
} from '../components';
import USERROLES from '../constants/UserRoles';

export function AssessmentPointCreate({ setBreadcrumbLinks }) {
  let { addToast } = useToasts();
  const navigate = useNavigate();

  const [errors, setErrors] = useState({
    title: '',
    description: '',
    category: '',
    profession: ''
  });
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [professionId, setProfessionId] = useState();
  const [allProfessions, setAllProfessions] = useState([]);
  const [categoryId, setCategoryId] = useState();
  const [allCategories, setAllCategories] = useState([]);
  const [semesters, setSemesters] = useState([]);
  const [apprentices, setApprentices] = useState([]);
  const [allApprentices, setAllApprentices] = useState();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setBreadcrumbLinks([
      {
        id: 1,
        label: 'Beurteilungspunkte anzeigen',
        path: '/assessment_points/overview'
      },
      {
        id: 2,
        label: 'Erstellen',
        path: '/assessment_points/create'
      }
    ]);
    ApiClient.get('assessment_categories')
      .then(({ assessment_categories }) =>
        setAllCategories(assessment_categories)
      )
      .catch(() =>
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
      );
    ApiClient.get('professions')
      .then(({ professions }) => setAllProfessions(professions))
      .catch(() =>
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
      );
  }, [addToast, setBreadcrumbLinks]);

  useEffect(() => {
    ApiClient.get('users')
      .then(({ users }) =>
        setAllApprentices(
          users.filter(
            user =>
              user.role.code === USERROLES.APPRENTICE &&
              user.profession_id == professionId
          )
        )
      )
      .catch(() =>
        addToast('Etwas ist schief gelaufen.', { appearance: 'error' })
      );
  }, [addToast, professionId]);

  const validateInputFields = () => title && professionId;

  function handleSubmitClicked() {
    if (!validateInputFields()) {
      return setErrors({
        ...errors,
        title: !title ? 'Bitte Titel eingeben!' : '',
        profession: !professionId ? 'Bitte Beruf angeben!' : '',
        category: !categoryId ? 'Bitte Kategorie angeben!' : ''
      });
    }

    setLoading(true);
    ApiClient.post('assessment_points', {
      title,
      description,
      profession_id: professionId,
      category_id: categoryId,
      target_semesters: semesters,
      individual_apprentices: apprentices
    })
      .then(() => {
        addToast('Beurteilungspunkt erfolgreich erstellt!', {
          appearance: 'success'
        });
        navigate('/assessment_points/overview');
      })
      .catch(() => {
        addToast('Etwas ist schief gelaufen!', {
          appearance: 'error'
        });
        setLoading(false);
      });
  }

  function generateSemesterOptionsFromProfession() {
    const options = [];
    const semesterCount = allProfessions.find(
      profession => profession.id == professionId
    )?.semesters;
    for (let index = 1; index <= semesterCount; index++) options.push(index);
    return options;
  }

  function onCategoryChange(selection) {
    if (selection.__isNew__) {
      ApiClient.post('assessment_categories', { name: selection.value }).then(
        category => {
          setCategoryId(category.id);
          addToast(`Kategorie ${selection.value} erfolgreich erstellt!`, {
            appearance: 'success'
          });
        }
      );
    } else {
      setCategoryId(selection.value);
    }
  }

  return (
    <CheckIfLoggedIn>
      <CheckAuthorization
        roles={[
          USERROLES.ADMIN,
          USERROLES.APPRENTICE_LEADER,
          USERROLES.INSTRUCTOR
        ]}
        shouldNavigate={true}
        path='/403'
      >
        <div className='create-assessment-point container'>
          <div className='content'>
            <h1 className='title'>Beurteilungspunkt hinzufügen</h1>
            <div className='form'>
              <Input
                error={errors.title}
                placeholder='Titel'
                type='text'
                value={title}
                onChange={event => setTitle(event.target.value)}
                required
              />
            </div>
            <div className='form'>
              <Input
                textarea
                error={errors.description}
                placeholder='Beschreibung'
                value={description}
                onChange={event => setDescription(event.target.value)}
              />
            </div>
            <div className='form'>
              <Input
                select
                error={errors.category}
                placeholder='Kategorie'
                options={allCategories?.map(category => ({
                  label: category.name,
                  value: category.id
                }))}
                onChange={onCategoryChange}
                required
                creatable
                createText='Kategorie'
              />
            </div>
            <div className='form'>
              <Input
                select
                error={errors.profession}
                placeholder='Beruf'
                options={allProfessions?.map(profession => ({
                  label: profession.name,
                  value: profession.id
                }))}
                onChange={event => setProfessionId(event.value)}
                required
              />
            </div>
            <div className='form'>
              <Input
                isMulti
                select
                error=''
                placeholder='Semester'
                options={generateSemesterOptionsFromProfession()?.map(
                  semester => ({ label: semester, value: semester })
                )}
                onChange={event =>
                  setSemesters(event?.map(semester => semester.value))
                }
                selectPlaceholder='Alle'
              />
            </div>
            <div className='form'>
              <Input
                isMulti
                select
                error=''
                placeholder='Lernende'
                options={allApprentices?.map(apprentice => ({
                  label: `${apprentice.firstname} ${apprentice.lastname}`,
                  value: apprentice.id
                }))}
                onChange={event =>
                  setApprentices(event?.map(apprentice => apprentice.value))
                }
                selectPlaceholder='Alle'
              />
            </div>
            <div className='button-group'>
              <Button
                text='Hinzufügen'
                disabled={loading}
                loading={loading}
                className='hero'
                onClick={handleSubmitClicked}
              />
              <Button
                text='Abbrechen'
                disabled={false}
                loading={false}
                className=''
                onClick={() => navigate('/assessment_points/overview')}
              />
            </div>
          </div>
        </div>
      </CheckAuthorization>
    </CheckIfLoggedIn>
  );
}
