import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { ApiClient } from '../components';

export function Logout({ setHideBreadcrumb }) {
  const { addToast } = useToasts();
  const navigate = useNavigate();

  useEffect(() => {
    setHideBreadcrumb(true);
    if (localStorage.getItem('access_token')) {
      ApiClient.post('/auth/logout', {
        refresh_token: localStorage.getItem('refresh_token')
      })
        .then(() => {
          localStorage.removeItem('refresh_token');
          localStorage.removeItem('access_token');
          navigate('/login');
        })
        .catch(() => {
          addToast('Es ist ein Fehler aufgetaucht.', { appearance: 'error' });
        });
    } else {
      navigate('/login');
    }
  }, [navigate, addToast, setHideBreadcrumb]);

  return (
    <div>
      <span>Redirecting...</span>
    </div>
  );
}
