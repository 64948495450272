import './styles/App.css';
import './styles/ToastNotification.css';

import { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';

import {
  CookieBanner,
  Footer,
  Forbidden,
  Header,
  NotFound
} from './components';
import Breadcrumbs from './components/Breadcrumbs/Breadcrumbs';
import {
  ApprenticeGoalOverview,
  Apprentices,
  AssessmentPointCreate,
  AssessmentPointOverview,
  AssessmentPointsSignOverview,
  DailyReportsOverview,
  DepartmentCreate,
  DepartmentEdit,
  DepartmentsOverview,
  DepartmentView,
  EmailSettingEdit,
  EmailSettingOverview,
  EmailTemplateOverview,
  GradeCreate,
  GradeEdit,
  GradesOverview,
  Home,
  LearningGoalCreate,
  LearningGoalOverview,
  Login,
  Logout,
  Multiplan,
  Plan,
  PlanRedirect,
  ProfessionCreate,
  ProfessionsOverview,
  ProfileEdit,
  RatingPointsFillout,
  RatingPointsOverview,
  SemestersOverview,
  SubjectCreate,
  SubjectProfessionAssign,
  SubjectSemesterAssign,
  SubjectsInSemesterOverview,
  SubjectsOverview,
  TriggerAssignOverview,
  UserCreate,
  UsersOverview,
  WeeklyReportOverview
} from './pages';

export default function Router() {
  const [isEditMode, setIsEditMode] = useState(false);
  const [breadcrumbLinks, setBreadcrumbLinks] = useState([]);
  const [hideBreadcrumb, setHideBreadcrumb] = useState(false);

  return (
    <BrowserRouter>
      <ToastProvider
        autoDismiss
        autoDismissTimeout={5000}
        newestOnTop
        transitionDuration={600}
        placement='top-center'
      >
        <CookieBanner />
        <Header isEditMode={isEditMode} />
        <div className='header-spacer' />
        <div className={isEditMode ? '' : 'cyan-stripe'}>
          {!hideBreadcrumb && (
            <Breadcrumbs
              isEditMode={isEditMode}
              home={{ label: 'Home' }}
              options={breadcrumbLinks}
            />
          )}
          <Routes>
            <Route
              path='/'
              element={
                <Apprentices
                  setBreadcrumbLinks={setBreadcrumbLinks}
                  setHideBreadcrumb={setHideBreadcrumb}
                />
              }
              exact
            />
            <Route path='/' element={<Home />} exact />
            <Route
              path='/multiplan'
              element={
                <Multiplan
                  setBreadcrumbLinks={setBreadcrumbLinks}
                  setIsEditMode={setIsEditMode}
                />
              }
              exact
            />
            <Route
              path='/logout'
              element={<Logout setHideBreadcrumb={setHideBreadcrumb} />}
            />
            <Route
              path='/login'
              element={<Login setHideBreadcrumb={setHideBreadcrumb} />}
            />
            <Route
              path='/users/create'
              element={<UserCreate setBreadcrumbLinks={setBreadcrumbLinks} />}
              exact
            />
            <Route
              path='/professions/create'
              element={
                <ProfessionCreate setBreadcrumbLinks={setBreadcrumbLinks} />
              }
            />
            <Route
              path='/subjects/create'
              element={
                <SubjectCreate setBreadcrumbLinks={setBreadcrumbLinks} />
              }
            />
            <Route
              path='/users'
              element={
                <UsersOverview setBreadcrumbLinks={setBreadcrumbLinks} />
              }
            />
            <Route
              path='/departments'
              element={
                <DepartmentsOverview setBreadcrumbLinks={setBreadcrumbLinks} />
              }
              exact
            />
            <Route
              path='/departments/:departmentId'
              element={
                <DepartmentView setBreadcrumbLinks={setBreadcrumbLinks} />
              }
              exact
            />
            <Route
              path='/departments/:departmentId/edit'
              element={
                <DepartmentEdit setBreadcrumbLinks={setBreadcrumbLinks} />
              }
              exact
            />
            <Route
              path='/departments/create'
              element={
                <DepartmentCreate setBreadcrumbLinks={setBreadcrumbLinks} />
              }
              exact
            />
            <Route
              path='/professions/:professionId/subjects'
              element={
                <SubjectProfessionAssign
                  setBreadcrumbLinks={setBreadcrumbLinks}
                />
              }
              exact
            />
            <Route
              path='/users/:userId/semesters/:semesterId/subjects/edit'
              element={
                <SubjectSemesterAssign
                  setBreadcrumbLinks={setBreadcrumbLinks}
                />
              }
              exact
            />
            <Route
              path='/users/:userId/semesters/:semesterId/subjects'
              element={
                <SubjectsInSemesterOverview
                  setBreadcrumbLinks={setBreadcrumbLinks}
                  setHideBreadcrumb={setHideBreadcrumb}
                />
              }
              exact
            />
            <Route
              path='/users/:userId/semesters/:semesterId/subjects/:subjectId/grades/:gradeId/edit'
              element={<GradeEdit setBreadcrumbLinks={setBreadcrumbLinks} />}
              exact
            />
            <Route
              path='/users/:userId/semesters/:semesterId/subjects/:subjectId/grades'
              element={
                <GradesOverview setBreadcrumbLinks={setBreadcrumbLinks} />
              }
              exact
            />
            <Route
              path='/users/:userId/semesters/:semesterId/subjects/:subjectId/grades/create'
              element={<GradeCreate setBreadcrumbLinks={setBreadcrumbLinks} />}
              exact
            />
            <Route
              path='/users/:userId/semesters'
              element={
                <SemestersOverview
                  setBreadcrumbLinks={setBreadcrumbLinks}
                  oldBreadcrumbLinks={breadcrumbLinks}
                />
              }
              exact
            />
            <Route
              path='/professions'
              element={
                <ProfessionsOverview setBreadcrumbLinks={setBreadcrumbLinks} />
              }
              exact
            />
            <Route
              path='/assessment_points/create'
              element={
                <AssessmentPointCreate
                  setBreadcrumbLinks={setBreadcrumbLinks}
                />
              }
              exact
            />
            <Route
              path='/assessment_points/overview'
              element={
                <AssessmentPointOverview
                  setBreadcrumbLinks={setBreadcrumbLinks}
                />
              }
              exact
            />
            <Route
              path='/users/:userId/semesters/:semesterId/assessment_points'
              element={
                <AssessmentPointsSignOverview
                  setBreadcrumbLinks={setBreadcrumbLinks}
                />
              }
              exact
            />
            <Route
              path='/users/:userId/semesters/:semesterId/assessment'
              element={
                <RatingPointsOverview setBreadcrumbLinks={setBreadcrumbLinks} />
              }
              exact
            />
            <Route
              path='/users/:userId/semesters/:semesterId/assessment/rate'
              element={
                <RatingPointsFillout setBreadcrumbLinks={setBreadcrumbLinks} />
              }
              exact
            />
            <Route
              path='/users/:userId/semesters/:semesterId/weekly_reports'
              element={
                <WeeklyReportOverview setBreadcrumbLinks={setBreadcrumbLinks} />
              }
              exact
            />
            <Route
              path='/users/:userId/semesters/:semesterId/weekly_reports/:weeklyReportId/daily_reports'
              element={
                <DailyReportsOverview setBreadcrumbLinks={setBreadcrumbLinks} />
              }
              exact
            />
            <Route
              path='/users/:userId/plan'
              element={<PlanRedirect setBreadcrumbLinks={setBreadcrumbLinks} />}
              exact
            />
            <Route
              path='/users/:userId/plan/:planId'
              element={
                <Plan
                  setBreadcrumbLinks={setBreadcrumbLinks}
                  setIsEditMode={setIsEditMode}
                />
              }
              exact
            />
            <Route
              path='/plan/:planId'
              element={
                <Plan
                  setBreadcrumbLinks={setBreadcrumbLinks}
                  setIsEditMode={setIsEditMode}
                />
              }
              exact
            />
            <Route
              path='/subjects'
              element={
                <SubjectsOverview setBreadcrumbLinks={setBreadcrumbLinks} />
              }
              exact
            />
            <Route
              path='/users/:userId/edit'
              element={<ProfileEdit setBreadcrumbLinks={setBreadcrumbLinks} />}
              exact
            />
            <Route
              path='/trigger_assign'
              element={
                <TriggerAssignOverview
                  setBreadcrumbLinks={setBreadcrumbLinks}
                />
              }
            />
            <Route
              path='/templates'
              element={
                <EmailTemplateOverview
                  setBreadcrumbLinks={setBreadcrumbLinks}
                />
              }
            />
            <Route
              path='/email_settings'
              element={
                <EmailSettingOverview setBreadcrumbLinks={setBreadcrumbLinks} />
              }
            />
            <Route
              path='/email_settings/:settingId'
              element={
                <EmailSettingEdit setBreadcrumbLinks={setBreadcrumbLinks} />
              }
            />
            <Route
              path='professions/:professionId/learning_goals/'
              element={
                <LearningGoalOverview setBreadcrumbLinks={setBreadcrumbLinks} />
              }
            />
            <Route
              path='users/:userId/learning_goals'
              element={
                <ApprenticeGoalOverview
                  setBreadcrumbLinks={setBreadcrumbLinks}
                />
              }
            />
            <Route
              path='professions/:professionId/learning_goals/add'
              element={
                <LearningGoalCreate setBreadcrumbLinks={setBreadcrumbLinks} />
              }
            />
            <Route
              path='/403'
              element={<Forbidden setBreadcrumbLinks={setBreadcrumbLinks} />}
              exact
            />
            <Route
              path='*'
              element={<NotFound setBreadcrumbLinks={setBreadcrumbLinks} />}
            />
          </Routes>
        </div>
        {!isEditMode && <Footer />}
      </ToastProvider>
    </BrowserRouter>
  );
}
