//Cheyenne Sägesser
import '../styles/TriggerAssignOverview.css';

import { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';

import {
  ApiClient,
  CheckAuthorization,
  CheckIfLoggedIn,
  Input,
  LoadingIndicator,
  Row,
  Table
} from '../components';
import UserRoles from '../constants/UserRoles';

export function TriggerAssignOverview({ setBreadcrumbLinks }) {
  const [triggers, setTriggers] = useState();
  const { addToast } = useToasts();
  useEffect(() => {
    setBreadcrumbLinks([{ id: 1, label: 'Trigger', path: '/trigger_assign' }]);
    ApiClient.get('email/triggers/')
      .then(triggers => setTriggers(triggers))
      .catch(() =>
        addToast('Etwas ist schiefgelaufen', { appearance: 'error' })
      );
  }, [addToast, setBreadcrumbLinks, setTriggers]);

  const handleTriggerChange = (triggerId, newActiveValue) => {
    ApiClient.patch(`email/triggers/${triggerId}`, { active: newActiveValue })
      .then(() => {
        addToast('Trigger erfolgreich aktualisiert', { appearance: 'success' });
      })
      .catch(() => {
        addToast('Etwas ist schiefgelaufen', { appearance: 'error' });
      });
  };

  return (
    <CheckIfLoggedIn>
      <CheckAuthorization
        roles={[
          UserRoles.ADMIN,
          UserRoles.APPRENTICE,
          UserRoles.APPRENTICE_LEADER,
          UserRoles.INSTRUCTOR,
          UserRoles.HR,
          UserRoles.TRAINING_OFFICER
        ]}
        shouldNavigate
        path='/403'
      >
        <div className='trigger container'>
          <div className='trigger content'>
            <h1 className='title'>Alle Trigger</h1>
            {triggers ? (
              Array.isArray(triggers) && triggers.length > 0 ? (
                <Table leftAlign>
                  {triggers.map(trigger => (
                    <Row
                      key={trigger.id}
                      data={[
                        trigger.name,
                        trigger.description,
                        <Input
                          key={trigger.id}
                          type='checkbox'
                          checked={trigger.active}
                          onChange={() => {
                            trigger.active = !trigger.active;
                            handleTriggerChange(trigger.id, trigger.active);
                          }}
                        />
                      ]}
                    />
                  ))}
                </Table>
              ) : (
                <h2> Keine Trigger für den Benutzer gefunden </h2>
              )
            ) : (
              <LoadingIndicator height={25} width={25} mode='darker' />
            )}
          </div>
        </div>
      </CheckAuthorization>
    </CheckIfLoggedIn>
  );
}
