import { useEffect, useState } from 'react';
import { IoIosAdd } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import {
  ApiClient,
  CheckAuthorization,
  CheckIfLoggedIn,
  LoadingIndicator,
  Row,
  Table
} from '../components';
import USERROLES from '../constants/UserRoles';

export function DepartmentsOverview({ setBreadcrumbLinks }) {
  const { addToast } = useToasts();
  const [departments, setDepartments] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    setBreadcrumbLinks([
      { id: 1, label: 'Abteilungen anzeigen', path: '/departments' }
    ]);
    ApiClient.get('departments')
      .then(departments => setDepartments(departments))
      .catch(() =>
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
      );
  }, [addToast, setBreadcrumbLinks]);

  return (
    <CheckIfLoggedIn>
      <CheckAuthorization
        roles={[
          USERROLES.ADMIN,
          USERROLES.APPRENTICE_LEADER,
          USERROLES.INSTRUCTOR
        ]}
        shouldNavigate
        path='/404'
      >
        <div className='departmentsOverview container'>
          <div className='content'>
            <h1 className='title'>
              Alle Abteilungen
              <IoIosAdd
                onClick={() => navigate('/departments/create')}
                className='title-icon'
              />
            </h1>
            {departments ? (
              Array.isArray(departments) && departments.length > 0 ? (
                <Table leftAlign>
                  {departments.map(department => (
                    <Row
                      key={department.department_id}
                      data={[department.name]}
                      onClick={() =>
                        navigate(`/departments/${department.department_id}`)
                      }
                    />
                  ))}
                </Table>
              ) : (
                <h2>Keine Abteilungen vorhanden!</h2>
              )
            ) : (
              <LoadingIndicator height={25} width={25} mode='darker' />
            )}
          </div>
        </div>
      </CheckAuthorization>
    </CheckIfLoggedIn>
  );
}
