/* eslint-disable indent */
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View
} from '@react-pdf/renderer';

import SansDem from './fonts/EuH_SansDem.ttf';
import SansLig from './fonts/EuH_SansLig.ttf';
import SansLigIta from './fonts/EuH_SansLigIta.ttf';
import SansReg from './fonts/EuH_SansReg.ttf';
import SansRegIta from './fonts/EuH_SansRegIta.ttf';
import SerifBol from './fonts/EuH_SerifBol.ttf';
import SerifLig from './fonts/EuH_SerifLig.ttf';
import SerifLigIta from './fonts/EuH_SerifLigIta.ttf';
import SerifReg from './fonts/EuH_SerifReg.ttf';
import SerifRegIta from './fonts/EuH_SerifRegIta.ttf';
import ehDesktopLogo from './logos/eh/ehDesktopLogo.png';
import { Sign, SignDate } from './Sign';
import { TableHeader } from './TableHeader';
import { TableRow } from './TableRow';
import { TableUserInfos } from './TableUserInfos';

const styles = StyleSheet.create({
  page: { backgroundColor: '#fff' },
  section: { textAlign: 'left', margin: 30 },
  sectionTable: {
    textAlign: 'left',
    marginLeft: 30,
    marginRight: 30,
    marginBottom: 0,
    marginTop: 0
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    marginBottom: 20
  },
  column: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '90%',
    marginLeft: '5%'
  },
  title: { fontSize: 25, fontFamily: 'EuH' },
  subtitle: { color: '#8FA2AC', flexShrink: 1, fontFamily: 'EuH Serif' },
  inTitle: { fontFamily: 'EuH Serif', fontSize: 12 },
  tableTitle: {
    alignContent: 'center',
    fontFamily: 'EuH Serif',
    fontSize: 14,
    marginTop: '5%',
    marginBottom: 5,
    marginLeft: 30
  },
  text: { fontSize: 10, fontFamily: 'EuH' },
  savings: {
    color: '#53a931',
    fontSize: 19,
    fontWeight: 600,
    fontFamily: 'EuH Serif',
    textAlign: 'right',
    marginRight: '10px'
  },
  inputRow: { display: 'flex', flexDirection: 'row' },
  standardInputs: { display: 'flex', flexDirection: 'column' },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    paddingRight: 40,
    textAlign: 'right',
    color: 'grey'
  },
  sign: {
    display: 'flex',
    flexDirection: 'column'
  }
});

Font.register({
  family: 'EuH',
  fonts: [
    {
      src: SansReg,
      format: 'truetype'
    },
    {
      src: SansRegIta,
      format: 'truetype',
      fontStyle: 'italic'
    },
    {
      src: SansLig,
      format: 'truetype',
      fontWeight: 200
    },
    {
      src: SansLigIta,
      format: 'truetype',
      fontWeight: 200,
      fontStyle: 'italic'
    },
    {
      src: SansDem,
      format: 'truetype',
      fontWeight: 600
    }
  ]
});
Font.register({
  family: 'EuH Serif',
  fonts: [
    {
      src: SerifReg,
      format: 'truetype'
    },
    {
      src: SerifRegIta,
      format: 'truetype',
      fontStyle: 'italic'
    },
    {
      src: SerifLig,
      format: 'truetype',
      fontWeight: 200
    },
    {
      src: SerifLigIta,
      format: 'truetype',
      fontWeight: 200,
      fontStyle: 'italic'
    },
    {
      src: SerifBol,
      format: 'truetype',
      fontWeight: 600
    }
  ]
});

export const EvaluationPDF = properties => {
  return (
    <Document key={properties.categoriesWithRatingPoints.id}>
      <Page>
        <View style={{ marginBottom: 50 }}>
          <View style={[styles.section, styles.header]}>
            <View style={styles.column}>
              <Text style={styles.title}>Semesterbeurteilung</Text>
            </View>
            <Image
              style={{
                paddingTop: 5,
                width: 200
              }}
              src={ehDesktopLogo}
            />
          </View>
          <View
            style={[styles.section, styles.standardInputs, { marginBottom: 0 }]}
          >
            <View>
              <TableUserInfos
                company={properties.company}
                user={properties.user}
                profession={properties.profession}
                apprenticeLeader={properties.apprenticeLeader}
                semester={properties.semester}
              />
            </View>
          </View>
          {properties.categoriesWithRatingPoints.map(
            (catWithRatingPoints, index) => (
              <>
                <View wrap={false} style={styles.sectionTable}>
                  <Text style={styles.tableTitle}>
                    {index + 1 + '. ' + catWithRatingPoints?.name}
                  </Text>
                  <TableHeader />
                  {catWithRatingPoints.rating_points.map(ratingPoints => (
                    <View key={ratingPoints.id} style={styles.inputRow}>
                      <View
                        style={[
                          styles.section,
                          styles.standardInputs,
                          { marginBottom: 0 }
                        ]}
                      >
                        <TableRow ratingPoints={ratingPoints} />
                      </View>
                    </View>
                  ))}
                </View>
              </>
            )
          )}
          <View style={[styles.section, styles.sign, { marginBottom: 0 }]}>
            <View>
              <View wrap={false} style={styles.sectionTable}>
                <View style={styles.inputRow}>
                  <SignDate />
                </View>
              </View>
            </View>
            <View>
              <View style={styles.sectionTable}>
                <View style={styles.inputRow}>
                  <Sign />
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
