import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import {
  ApiClient,
  Button,
  CheckAuthorization,
  CheckIfLoggedIn,
  Input
} from '../components';
import USERROLES from '../constants/UserRoles';
import { isValidDate, isValidNumber } from '../helpers/validator';

export function GradeCreate({ setBreadcrumbLinks }) {
  let { addToast } = useToasts();
  const navigate = useNavigate();
  let { userId, semesterId, subjectId } = useParams();

  useEffect(() => {
    Promise.all([
      ApiClient.get(`users/${userId}`),
      ApiClient.get(`users/${userId}/semesters`),
      ApiClient.get(
        `users/${userId}/semesters/${semesterId}/subjects/${subjectId}`
      )
    ])
      .then(([user, { semesters }, subject]) => {
        const semester = semesters.find(semester => semester.id == semesterId);
        setBreadcrumbLinks([
          {
            id: 1,
            label: `${user?.firstname} ${user?.lastname}`,
            path: `/users/${userId}/semesters`
          },
          {
            id: 2,
            label: 'Alle Semester',
            path: `/users/${userId}/semesters`
          },
          {
            id: 3,
            label: semester?.index,
            path: `/users/${userId}/semesters/${semesterId}/subjects`
          },
          {
            id: 4,
            label: 'Fächer',
            path: `/users/${userId}/semesters/${semesterId}/subjects`
          },
          {
            id: 5,
            label: subject?.subject?.name,
            // eslint-disable-next-line max-len
            path: `/users/${userId}/semesters/${semesterId}/subjects/${subjectId}/grades`
          },
          {
            id: 6,
            label: 'Hinzufügen',
            // eslint-disable-next-line max-len
            path: `/users/${userId}/semesters/${semesterId}/subjects/${subjectId}/grades/create`
          }
        ]);
      })
      .catch(() =>
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
      );
  }, [addToast, semesterId, setBreadcrumbLinks, subjectId, userId]);

  const [error, setError] = useState({
    grade: '',
    weight: '',
    description: '',
    writtenAt: '',
    justification: ''
  });
  const [grade, setGrade] = useState({
    weight: 1,
    description: '',
    justification: ''
  });
  const [loading, setLoading] = useState(false);

  function onInputChange(field, value) {
    setGrade({ ...grade, [field]: value });
    setError({ ...error, [field]: '' });
  }

  const validateInputFields = grade => {
    var checkInput =
      grade.grade && grade.weight && grade.description && grade.written_at;
    return grade.grade < 4 && checkInput ? !!grade.justification : checkInput;
  };

  function handleSubmitClicked() {
    if (!validateInputFields(grade)) {
      return setError({
        ...error,
        grade: !grade.grade ? 'Bitte Note eingeben!' : '',
        weight: !grade.weight ? 'Bitte Gewicht eingeben!' : '',
        description: !grade.description ? 'Bitte Beschreibung eingeben!' : '',
        written_at: !grade.written_at
          ? 'Bitte eingeben, wann die Prüfung geschrieben wurde'
          : '',
        justification: !grade.justification
          ? 'Bitte Rechtfertigung eingeben'
          : ''
      });
    }
    if (!isValidNumber(grade.grade)) {
      return setError({
        ...error,
        grade: 'Bitte geben Sie eine Zahl an!'
      });
    } else if (
      Number.parseFloat(grade.grade) < 1 ||
      Number.parseFloat(grade.grade) > 6
    ) {
      return setError({
        ...error,
        grade: 'Bitte geben Sie eine Zahl zwischen 1 und 6 an!'
      });
    }
    if (!isValidNumber(Number.parseFloat(grade.weight))) {
      return setError({
        ...error,
        weight: 'Bitte geben Sie eine Zahl an!'
      });
    } else if (
      Number.parseFloat(grade.weight) < 0 ||
      Number.parseFloat(grade.weight) > 1
    ) {
      return setError({
        ...error,
        weight: 'Bitte geben Sie eine Zahl zwischen 0 und 1 an!'
      });
    }
    if (grade.description.trim().length > 100) {
      return setError({
        ...error,
        description: 'Die maximale Länge der Beschreibung beträgt 100 Zeichen!'
      });
    }

    if (!isValidDate(grade.written_at)) {
      return setError({
        ...error,
        written_at: 'Bitte geben Sie ein richtiges Datum an!'
      });
    }
    if (grade.justification.trim().length > 100) {
      return setError({
        ...error,
        justification:
          'Die maximale Länge der Rechtfertigung beträgt 100 Zeichen'
      });
    }

    if (Date.now() < new Date(grade.written_at).getTime()) {
      return setError({
        ...error,
        written_at: 'Das Datum darf nicht in der Zukunft liegen!'
      });
    }

    setGrade({
      ...grade,
      description: grade.description.trim()
    });
    setLoading(true);
    ApiClient.post('grades', {
      ...grade,
      subject_id: encodeURIComponent(subjectId)
    })
      .then(() => {
        addToast('Note erfolgreich erstellt!', {
          appearance: 'success'
        });
        navigate(
          // eslint-disable-next-line max-len
          `/users/${userId}/semesters/${semesterId}/subjects/${subjectId}/grades`
        );
      })
      .catch(() => {
        addToast('Etwas ist schief gelaufen!', {
          appearance: 'error'
        });
        setLoading(false);
      });
  }

  return (
    <CheckIfLoggedIn>
      <CheckAuthorization
        roles={[
          USERROLES.ADMIN,
          USERROLES.APPRENTICE_LEADER,
          USERROLES.INSTRUCTOR,
          USERROLES.APPRENTICE
        ]}
        shouldNavigate={false}
      >
        <div className='createGrade container'>
          <div className='content'>
            <h1 className='title'>Note hinzufügen</h1>
            <div className='form'>
              <Input
                error={error.grade}
                placeholder='Note'
                type='number'
                value={grade.grade}
                max={6}
                min={1}
                onChange={event => onInputChange('grade', event.target.value)}
                required
              />
            </div>
            {grade.grade < 4 ? (
              <div className='form'>
                <Input
                  textarea
                  maxLength={100}
                  error={error.justification}
                  placeholder='Rechtfertigung'
                  type='text'
                  value={grade.justification}
                  onChange={event =>
                    onInputChange('justification', event.target.value)
                  }
                  required
                />
              </div>
            ) : (
              ''
            )}
            <div className='form'>
              <Input
                error={error.weight}
                placeholder='Gewichtung'
                type='number'
                value={grade.weight}
                onChange={event => onInputChange('weight', event.target.value)}
                required
              />
            </div>
            <div className='form'>
              <Input
                error={error.description}
                placeholder='Beschreibung'
                type='text'
                value={grade.description}
                maxLength={100}
                onChange={event =>
                  onInputChange('description', event.target.value)
                }
                required
              />
              <div className='form'>
                <Input
                  error={error.written_at}
                  placeholder='Geschrieben am'
                  type='date'
                  value={grade.written_at}
                  onChange={event =>
                    onInputChange('written_at', event.target.value)
                  }
                  required
                />
              </div>
            </div>
            <div className='button-group'>
              <Button
                text='Hinzufügen'
                disabled={loading}
                loading={loading}
                className='hero'
                onClick={handleSubmitClicked}
              />
              <Button
                text='Abbrechen'
                disabled={false}
                loading={false}
                className=''
                onClick={() =>
                  navigate(
                    // eslint-disable-next-line max-len
                    `/users/${userId}/semesters/${semesterId}/subjects/${subjectId}/grades`
                  )
                }
              />
            </div>
          </div>
        </div>
      </CheckAuthorization>
    </CheckIfLoggedIn>
  );
}
