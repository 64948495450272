import { StyleSheet, Text, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    borderBottomColor: '#C3CED5',
    backgroundColor: '#C3CED5',
    borderBottomWidth: 1,
    height: 24,
    textAlign: 'center',
    fontStyle: 'bold',
    marginLeft: 30,
    marginRight: 30
  },
  description: {
    width: '45%',
    fontSize: 12
  },
  rate: {
    width: '8%',
    fontSize: 12
  },
  statement: {
    width: '40%',
    fontSize: 12
  }
});

export const TableHeader = () => (
  <View style={styles.container}>
    <Text style={styles.description}>Beurteilungsmerkmale</Text>
    <Text style={styles.rate}>BB</Text>
    <Text style={styles.rate}>Azubi</Text>
    <Text style={styles.statement}>Begründung</Text>
  </View>
);
