import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import notFound from '../res/not_found.png';

export function NotFound({ setBreadcrumbLinks }) {
  useEffect(() => {
    setBreadcrumbLinks([
      {
        id: 1,
        label: 'Not Found',
        path: '/403'
      }
    ]);
  }, [setBreadcrumbLinks]);

  return (
    <div className='notFound container'>
      <div className='content'>
        <h3>Oops!</h3>
        <div>
          <h2>Es sieht so aus, als ob diese Seite nicht existiert.</h2>
          <p>
            Gehe Sie zurück zur <Link to='/'>Startseite</Link> oder melden Sie
            dieses Problem Ihrem Vorgesetzten.
          </p>
        </div>
        <img src={notFound} alt='Not found' />
      </div>
    </div>
  );
}
