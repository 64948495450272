import '../styles/PopUp.css';

import { Button } from './Button';

export function PopUp({
  title,
  description,
  handleCancel,
  handleSubmit,
  disabled,
  loading,
  submitLabel
}) {
  return (
    <div className='popup-container'>
      <div className='popup-body'>
        <h2>{title}</h2>
        <p>{description}</p>
        <div className='button-group'>
          <Button
            text={submitLabel}
            disabled={disabled}
            loading={loading}
            className='hero'
            onClick={handleSubmit}
          />
          <Button
            text='Abbrechen'
            disabled={false}
            loading={false}
            className=''
            onClick={handleCancel}
          />
        </div>
      </div>
    </div>
  );
}
