/* eslint-disable max-len */
/* eslint-disable indent */
import '../styles/WeeklyReportOverview.css';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { ApiClient, CheckIfLoggedIn, LoadingIndicator } from '../components';
import UserRoles from '../constants/UserRoles';
import { getUserInfo } from '../helpers/auth';

export function DailyReportsOverview({ setBreadcrumbLinks }) {
  const { addToast } = useToasts();
  const [dailyReports, setDailyReports] = useState('');
  const [user, setUser] = useState();
  const [answerStartText, setAnswerStartText] = useState('');
  const [weeklyReport, setWeeklyReport] = useState('');
  const [calendarWeek, setCalendarWeek] = useState('');

  const [loading, setLoading] = useState(false);
  let { userId, semesterId, weeklyReportId } = useParams();

  useEffect(() => {
    ApiClient.get(
      // eslint-disable-next-line max-len
      `/users/${userId}/semesters/${semesterId}/weekly_reports/${weeklyReportId}/daily_reports`
    )
      .then(dailyReports => {
        setDailyReports(dailyReports.daily_reports);
        Promise.all(
          dailyReports.daily_reports.map(dailyReport =>
            ApiClient.get(
              // eslint-disable-next-line max-len
              `/users/${userId}/semesters/${semesterId}/weekly_reports/${weeklyReportId}/daily_reports/${dailyReport.id}/questions`
            )
          )
        )
          .then(answers => {
            setDailyReports(
              dailyReports.daily_reports.map(dailyRep => {
                dailyRep.answers = answers
                  .flat()
                  .filter(answer => answer.daily_report_id === dailyRep.id);
                return dailyRep;
              })
            );
          })
          .catch(() => {
            addToast('Keine Tagesberichte gefunden', {
              appearance: 'error'
            });
            setLoading(false);
          });
      })
      .catch(() => {
        addToast('Etwas ist schief gelaufen!', {
          appearance: 'error'
        });
        setLoading(false);
      });
    Promise.all([
      ApiClient.get(`users/${userId}`),
      ApiClient.get(
        `/users/${userId}/semesters/${semesterId}/weekly_reports/${weeklyReportId}`
      ),
      ApiClient.get(`users/${userId}/semesters`)
    ])
      .then(([user, report, { semesters }]) => {
        const semester = semesters.find(semester => semester.id == semesterId);
        setBreadcrumbLinks([
          {
            id: 1,
            label: `${user?.firstname} ${user?.lastname}`,
            path: `/users/${userId}/semesters`
          },
          {
            id: 2,
            label: 'Alle Semester',
            path: `/users/${userId}/semesters`
          },
          {
            id: 3,
            label: semester?.index,
            path: `/users/${userId}/semesters/${semester.id}/subjects`
          },
          {
            id: 4,
            label: 'Wochenberichte',
            path: `/users/${userId}/semesters/${semester.id}/weekly_reports`
          },
          {
            id: 5,
            label: report?.searchedWeeklyReport?.calendar_week,
            path: `/users/${userId}/semesters/${semester.id}/weekly_reports/${report?.searchedWeeklyReport?.id}/daily_reports`
          }
        ]);

        setUser(user);
        setWeeklyReport(report);
        setCalendarWeek(report.searchedWeeklyReport?.calendar_week);
      })
      .catch(() =>
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
      );
  }, [
    addToast,
    semesterId,
    setBreadcrumbLinks,
    setLoading,
    userId,
    weeklyReport.searchedWeeklyReport?.calendar_week,
    weeklyReportId
  ]);

  function HandleSubmit(answer, dailyReportId) {
    let error = '';
    let answerText = answer.text
      ? answer.text
      : (error = new Error('please enter a valid conclusion'));

    if (!error && answer.text !== answerStartText) {
      ApiClient.patch(
        // eslint-disable-next-line max-len
        `/users/${userId}/semesters/${semesterId}/weekly_reports/${weeklyReportId}/daily_reports/${dailyReportId}/answers/${answer.id}`,
        {
          text: answerText
        }
      )
        .then(() => {
          addToast('Erfolgreich gespeichert!', { appearance: 'success' });
        })
        .catch(() =>
          addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
        );
    }
  }

  function HandleSubmitWeekly() {
    let error = '';
    let conclusion = weeklyReport.searchedWeeklyReport?.conclusion;
    let kw = calendarWeek
      ? calendarWeek
      : (error = new Error('please enter a valid conclusion'));

    if (!error) {
      ApiClient.patch(
        // eslint-disable-next-line max-len
        `/users/${userId}/semesters/${semesterId}/weekly_reports/${weeklyReport.searchedWeeklyReport.id}/edit`,
        {
          calendar_week: kw,
          conclusion: conclusion
        }
      )
        .then(() => {
          addToast('Erfolgreich gespeichert!', { appearance: 'success' });
        })
        .catch(() =>
          addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
        );
    }
  }

  const isDisabled =
    getUserInfo()?.user?.role?.code === UserRoles.TRAINING_OFFICER ||
    getUserInfo()?.user?.role?.code === UserRoles.HR;

  return (
    <CheckIfLoggedIn>
      <div className='dailyReportsOverview subjectOverview container'>
        <div className='content'>
          <h1 className='title'>
            Tagesberichte für {user?.firstname} {user?.lastname} in
            Kalenderwoche {calendarWeek}
          </h1>
          {dailyReports ? (
            <>
              <span className='spanDailyRep'>
                <p className='pQuestion'>Kalenderwoche</p>
                <textarea
                  disabled={isDisabled}
                  className='txtCalendarWeek'
                  onBlur={HandleSubmitWeekly}
                  defaultValue={calendarWeek}
                  onChange={event => setCalendarWeek(event.target.value)}
                />
              </span>
              {Array.isArray(dailyReports) && dailyReports.length > 0 ? (
                dailyReports
                  .sort((a, b) => (a.week_day.id > b.week_day.id ? 1 : -1))
                  .map((dailyReport, dailyReportIndex) => (
                    <details key={dailyReport.id}>
                      <summary>{dailyReport.week_day.name}</summary>
                      {dailyReport.answers?.length >= 0
                        ? dailyReport.answers.map((answer, index) => (
                            <span key={answer.id} className='spanDailyRep'>
                              <p className='pQuestion'>
                                {answer.question.question}
                              </p>
                              <textarea
                                className='txtAnswer'
                                onClick={() => setAnswerStartText(answer.text)}
                                defaultValue={answer.text}
                                disabled={isDisabled}
                                onChange={eingabe => {
                                  const updatedAnswer = {
                                    ...answer,
                                    text: eingabe.target.value
                                  };
                                  const updatedDailyReport = {
                                    ...dailyReport,
                                    answers: dailyReport.answers.map(
                                      (a, index_) =>
                                        index_ === index ? updatedAnswer : a
                                    )
                                  };
                                  // eslint-disable-next-line security/detect-object-injection
                                  dailyReports[dailyReportIndex] =
                                    updatedDailyReport;
                                }}
                                onBlur={() =>
                                  HandleSubmit(answer, dailyReport.id)
                                }
                                id={answer.id}
                              />
                            </span>
                          ))
                        : 'Keine Antwort'}
                    </details>
                  ))
              ) : (
                <h2>Keine Tagesberichte vorhanden!</h2>
              )}
            </>
          ) : loading ? (
            <LoadingIndicator height={25} width={25} mode='darker' />
          ) : (
            <h2>Keine Tagesberichte vorhanden!</h2>
          )}
          {weeklyReport && dailyReports ? (
            <>
              <span className='spanDailyRep'>
                <p className='pQuestion'>Wochenfazit</p>
                <textarea
                  disabled={isDisabled}
                  // eslint-disable-next-line unicorn/prevent-abbreviations
                  onChange={e => {
                    weeklyReport.searchedWeeklyReport.conclusion =
                      e.target.value;
                  }}
                  onBlur={() => HandleSubmitWeekly()}
                  className='txtAnswer'
                  defaultValue={weeklyReport?.searchedWeeklyReport?.conclusion}
                ></textarea>
              </span>
            </>
          ) : (
            ''
          )}
        </div>
      </div>
    </CheckIfLoggedIn>
  );
}
