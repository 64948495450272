/* eslint-disable unicorn/no-null */
import '../styles/Login.css';

import { useEffect, useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { Button, Input } from '../components';
import { ApiClient } from '../components';
import { getUserInfo, isTokenValid } from '../helpers/auth';
import { validateEmail } from '../helpers/validator';

export function Login({ setHideBreadcrumb }) {
  let navigate = useNavigate();
  const { addToast } = useToasts();
  const [user, setUser] = useState({ email: '', password: '' });
  const [error, setError] = useState({ email: '', password: '' });
  const [loginLoading, setLoginLoading] = useState(false);
  const [validToken, isValidToken] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const access_token = localStorage.getItem('access_token');

  useEffect(() => {
    setHideBreadcrumb(true);
    document.title = 'AMP | Login';
    if (isTokenValid(localStorage.getItem('access_token'))) {
      navigate('/', { replace: true });
    } else isValidToken(true);
  }, [access_token, navigate, setHideBreadcrumb]);

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  function handleLogin() {
    if (user.email && user.password) {
      if (validateEmail(user.email)) {
        setLoginLoading(true);
        ApiClient.post('auth/login', {
          email: user.email,
          password: user.password
        })
          .then(tokens => {
            localStorage.setItem('access_token', tokens.access_token);
            localStorage.setItem('refresh_token', tokens.refresh_token);
            ApiClient.get(`users/${getUserInfo().user.id}`)
              .then(user => {
                if (user.latestSemesterId) {
                  navigate(
                    `/users/${getUserInfo().user.id}/semesters/${
                      user.latestSemesterId
                    }/subjects`
                  );
                } else {
                  navigate('/');
                }
              })
              .catch(() => {
                setLoginLoading(false);
                addToast('Etwas ist schief gelaufen!', {
                  appearance: 'error'
                });
              });
          })
          .catch(() => {
            setLoginLoading(false);
            addToast('Email oder Password falsch!', { appearance: 'error' });
          });
      } else {
        setError({
          email: 'Bitte geben Sie eine gültige Email ein!',
          password: ''
        });
      }
    } else {
      setError({
        email: !user.email ? 'Bitte Email angeben!' : '',
        password: !user.password ? 'Bitte Passwort angeben!' : ''
      });
    }
  }

  function onInputChange(field, value) {
    setUser({ ...user, [field]: value });
    setError({ ...error, [field]: '' });
  }

  return validToken ? (
    <div className='login-container'>
      <div className='login-body'>
        <div className='login-content'>
          <h1 className='login-title'>Login</h1>
          <div className='login-form'>
            <Input
              error={error.email}
              placeholder='Email'
              type='email'
              value={user.email}
              onChange={event => onInputChange('email', event.target.value)}
              required
            />
          </div>
          <div className='login-form'>
            <Input
              error={error.password}
              placeholder='Passwort'
              type={passwordShown ? 'text' : 'password'}
              value={user.password}
              onChange={event => onInputChange('password', event.target.value)}
              onEnter={handleLogin}
              required
              icon={
                passwordShown ? (
                  <AiOutlineEyeInvisible
                    className='iconEye'
                    onClick={togglePasswordVisiblity}
                  />
                ) : (
                  <AiOutlineEye
                    className='iconEye'
                    onClick={togglePasswordVisiblity}
                  />
                )
              }
            />
          </div>
          <div className='button-group'>
            <Button
              text='Log in'
              onClick={handleLogin}
              className='hero'
              loading={loginLoading}
              disabled={loginLoading}
            />
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
