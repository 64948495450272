import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import {
  ApiClient,
  Button,
  CheckAuthorization,
  CheckIfLoggedIn,
  Input
} from '../components';
import USERROLES from '../constants/UserRoles';

export function LearningGoalCreate({ setBreadcrumbLinks }) {
  const { professionId } = useParams();
  const navigate = useNavigate();
  const { addToast } = useToasts();

  const [error, setError] = useState({ name: '', description: '' });
  const [users, setUsers] = useState();

  const [learningGoals, setLearningGoals] = useState({
    name: '',
    description: '',
    users: []
  });
  const [departments, setDepartments] = useState();
  const [selectedDepartments, setSelectedDepartments] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setBreadcrumbLinks([
      {
        id: 1,
        label: 'Berufe anzeigen',
        path: '/professions'
      },
      {
        id: 2,
        label: professionId,
        path: `/${professionId}`
      },
      {
        id: 3,
        label: 'Lernziele anzeigen',
        path: '/learning_goals'
      },
      {
        id: 4,
        label: 'Lernziele hinzufügen',
        path: '/add'
      }
    ]);

    document.title = 'AMP | Lernziele erstellen';
  }, [professionId, setBreadcrumbLinks]);

  useEffect(() => {
    ApiClient.get('users/apprentices')
      .then(users => {
        setUsers(users.users);
      })
      .catch(() => addToast('Ein Fehler ist aufgetreten'));

    ApiClient.get('departments')
      .then(departments => setDepartments(departments))
      .catch(() => addToast('Ein Fehler ist aufgetreten'));
  }, [addToast]);

  const validateInputFields = learningGoals => {
    var checkInput = learningGoals.name && learningGoals.description;
    return checkInput;
  };

  function handleSubmitClicked() {
    if (!validateInputFields(learningGoals)) {
      return setError({
        name: !learningGoals.name ? 'Bitte Name eingeben' : '',
        description: !learningGoals.description
          ? 'Bitte Beschreibung eingeben'
          : ''
      });
    }

    setLearningGoals({
      name: learningGoals.name.trim(),
      description: learningGoals.description.trim()
    });

    setLoading(true);
    if (learningGoals) {
      ApiClient.patch(`/professions/${professionId}/learning_goals`, {
        learningGoals,
        selectedDepartments
      })
        .then(() => {
          setLoading(false);
          addToast('Lernziel wurde erstellt ', { appearance: 'success' });
          navigate(`/professions/${professionId}/learning_goals`);
        })
        .catch(() =>
          addToast('Etwas ist schief gelaufen', { appearance: 'error' })
        );
    }
  }

  return (
    <CheckIfLoggedIn>
      <CheckAuthorization
        roles={[USERROLES.ADMIN, USERROLES.APPRENTICE_LEADER]}
        shouldNavigate={true}
      >
        <div className='createProfession container'>
          <div className='content'>
            <h1 className='title'>Lernziel hinzufügen</h1>
            <div className='form'>
              <Input
                error={error.name}
                placeholder='Name'
                type='text'
                value={learningGoals.name}
                onChange={event => {
                  setLearningGoals({
                    ...learningGoals,
                    name: event.target.value
                  });
                }}
                required
              />
            </div>
            <div className='form'>
              <Input
                error={error.description}
                placeholder='Beschreibung'
                type='text'
                value={learningGoals.description}
                onChange={event => {
                  setLearningGoals({
                    ...learningGoals,
                    description: event.target.value
                  });
                }}
                required
              />
            </div>
            <div className='form'>
              <Input
                select
                placeholder='Benutzer zuweisen'
                options={users?.map(user => ({
                  label: user.firstname + ' ' + user.lastname,
                  value: user.id
                }))}
                onChange={selected =>
                  setLearningGoals({
                    ...learningGoals,
                    users: selected.map(select => select.value)
                  })
                }
                isMulti
              />
            </div>
            <div className='form'>
              <Input
                select
                placeholder='Abteilung zuweisen'
                options={departments?.map(department => ({
                  label: department.name,
                  value: department.department_id
                }))}
                onChange={selected =>
                  setSelectedDepartments({
                    ...selectedDepartments,
                    selected: selected.map(select => select.value)
                  })
                }
                isMulti
              />
            </div>
            <div className='button-group'>
              <Button
                text='Hinzufügen'
                disabled={loading}
                loading={loading}
                className='hero'
                onClick={handleSubmitClicked}
              />
              <Button
                text='Abbrechen'
                disabled={false}
                loading={false}
                className=''
                onClick={() =>
                  navigate(`/professions/${professionId}/learning_goals`)
                }
              />
            </div>
          </div>
        </div>
      </CheckAuthorization>
    </CheckIfLoggedIn>
  );
}
