import axios from 'axios';
import jwtDecode from 'jwt-decode';

const baseURL =
  // eslint-disable-next-line no-undef
  process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001/api/v1/';
const request = axios.create({ baseURL, withCredentials: true });
let isUpdating = false;
async function checkAndInjectToken(local_request) {
  if (localStorage.getItem('access_token')) {
    if (
      jwtDecode(localStorage.getItem('access_token')).exp - Date.now() / 1000 <
      0
    ) {
      while (isUpdating) {
        await new Promise(resolve => setTimeout(resolve, 200));
      }
      const refresh_request = axios.create({ baseURL, withCredentials: true });
      isUpdating = true;
      await refresh_request
        .post(baseURL + 'auth/refresh', {
          refresh_token: localStorage.getItem('refresh_token')
        })
        .then(result => result.data)
        .then(({ access_token, refresh_token }) => {
          console.log('Refreshed');
          localStorage.setItem('access_token', access_token);
          localStorage.setItem('refresh_token', refresh_token);
        })
        .catch(() => {
          console.log('Refresh key invalid');
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
          window.location.replace('/');
          Promise.resolve();
        })
        .finally(() => (isUpdating = false));
    }
    local_request.headers['authorization'] = `Bearer ${localStorage.getItem(
      'access_token'
    )}`;
    return local_request;
  }
  return local_request;
}

request.interceptors.request.use(request => checkAndInjectToken(request));

function handleSuccess(response) {
  return response.data;
}

function handleError(error) {
  if (error.response) {
    console.log('Error occured:');
    console.log(error.response);
    return Promise.reject(error.response);
  } else {
    return Promise.reject(error);
  }
}

request.interceptors.response.use(
  response => handleSuccess(response),
  error => handleError(error)
);

export default request;
