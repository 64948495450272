/* eslint-disable indent */
/* eslint-disable unicorn/no-null */
import '../styles/UserCreate.css';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import {
  ApiClient,
  Button,
  CheckAuthorization,
  CheckIfLoggedIn,
  Input
} from '../components';
import USERROLES from '../constants/UserRoles';
import { isValidDate, validateEmail } from '../helpers/validator';

export function UserCreate({ setBreadcrumbLinks }) {
  let { addToast } = useToasts();
  const navigate = useNavigate();

  const [user, setUser] = useState({
    firstname: '',
    lastname: '',
    email: '',
    apprenticeshipStart: null,
    professionId: null,
    userRoleCode: null,
    companyId: null
  });
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [professions, setProfessions] = useState(null);
  const [userRoles, setUserRoles] = useState(null);
  const [allCompanies, setCompanies] = useState(null);

  useEffect(() => {
    document.title = 'AMP | Benutzer erstellen';
    setBreadcrumbLinks([
      { id: 1, label: 'Benutzer anzeigen', path: '/users' },
      { id: 2, label: 'Erstellen', path: '/users/create' }
    ]);
    Promise.all([
      ApiClient.get('users/roles'),
      ApiClient.get('professions'),
      ApiClient.get('companies')
    ])
      .then(responses => {
        setUserRoles(responses[0].user_roles);
        setProfessions(responses[1].professions);
        setCompanies(responses[2].companies);
      })
      .catch(() => {
        addToast('Etwas ist schiefgelaufen!', { appearance: 'error' });
      });
  }, [addToast, setBreadcrumbLinks]);

  function onInputChange(field, value) {
    setUser({ ...user, [field]: value });
    setError({ ...error, [field]: '' });
  }

  const validateInputFields = (user, isApprentice) =>
    isApprentice
      ? user.firstname &&
        user.lastname &&
        user.email &&
        user.userRoleCode &&
        user.apprenticeshipStart &&
        user.professionId &&
        user.companyId
      : user.firstname && user.lastname && user.email && user.userRoleCode;

  function handleSubmitClicked() {
    setUser({
      ...user,
      firstname: user.firstname.trim(),
      lastname: user.lastname.trim(),
      email: user.email.trim()
    });

    if (!validateInputFields(user)) {
      return setError({
        ...error,
        firstname: !user.firstname ? 'Bitte Vorname eingeben!' : '',
        lastname: !user.lastname ? 'Bitte Nachname eingeben!' : '',
        email: !user.email ? 'Bitte Email eingeben!' : '',
        apprenticeshipStart: !user.apprenticeshipStart
          ? 'Bitte Ausbildungsstart eingeben!'
          : '',
        professionId: !user.professionId ? 'Bitte Beruf auswählen!' : '',
        userRoleCode: !user.userRoleCode
          ? 'Bitte Benutzer-Rolle auswählen!'
          : '',
        companyId: !user.companyId ? 'Bitte Firma Auswählen' : ''
      });
    }

    if (!validateEmail(user.email)) {
      return setError({
        ...error,
        email: 'Bitte geben Sie eine gültige Email an!'
      });
    }

    if (
      user.userRoleCode === USERROLES.APPRENTICE &&
      !isValidDate(user.apprenticeshipStart)
    ) {
      return setError({
        ...error,
        apprenticeshipStart:
          'Bitte geben Sie einen gültigen Ausbildungsstart an!'
      });
    }

    if (
      user.userRoleCode === USERROLES.APPRENTICE &&
      !professions.some(profession => profession.id === user.professionId)
    ) {
      return setError({
        ...error,
        professionId: 'Bitte geben Sie einen gültigen Beruf an!'
      });
    }

    if (userRoles.some(userRole => userRole.id === user.userRoleCode)) {
      return setError({
        ...error,
        userRoleCode: 'Bitte geben Sie einen gültige Benutzer-Rolle an!'
      });
    }

    setLoading(true);
    ApiClient.post('users', {
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      user_role_id: userRoles.find(
        userRole => userRole.code === user.userRoleCode
      ).id,
      profession_id: user.professionId,
      apprenticeship_start: user.apprenticeshipStart,
      company_id: user.companyId
    })
      .then(() => {
        addToast(`${user.firstname} erfolgreich hinzugefügt!`, {
          appearance: 'success'
        });
        navigate('/users');
      })
      .catch(() => {
        navigate('/users');
        addToast('Etwas ist schiefgelaufen!', {
          appearance: 'error'
        });
      })
      .finally(() => setLoading(false));
  }

  function onRoleChange(selection) {
    if (selection.__isNew__) {
      ApiClient.post('users/roles', {
        name: selection.value
      }).then(newRole => {
        onInputChange('userRoleCode', newRole.code);
        setUserRoles([...userRoles, newRole]);
        addToast(`Rolle ${selection.value} erfolgreich erstellt!`, {
          appearance: 'success'
        });
      });
    } else {
      onInputChange('userRoleCode', selection.value);
    }
  }

  return (
    <CheckIfLoggedIn>
      <CheckAuthorization
        roles={[
          USERROLES.ADMIN,
          USERROLES.APPRENTICE_LEADER,
          USERROLES.INSTRUCTOR
        ]}
        shouldNavigate={true}
      >
        <div className='createUser container'>
          <div className='content'>
            <h1 className='title'>Benutzer hinzufügen</h1>
            <div className='form'>
              <Input
                error={error.firstname}
                placeholder='Vorname'
                type='text'
                value={user.firstname}
                onChange={event =>
                  onInputChange('firstname', event.target.value)
                }
                required
              />
            </div>
            <div className='form'>
              <Input
                error={error.lastname}
                placeholder='Nachname'
                type='text'
                value={user.lastname}
                onChange={event =>
                  onInputChange('lastname', event.target.value)
                }
                required
              />
            </div>
            <div className='form'>
              <Input
                error={error.email}
                placeholder='Email'
                type='email'
                value={user.email}
                onChange={event => onInputChange('email', event.target.value)}
                required
              />
            </div>
            <div className='form userCreate-role'>
              <Input
                select
                creatable
                createText='Rolle'
                error={error.userRoleCode}
                placeholder='Rolle'
                type='text'
                value={{
                  label:
                    userRoles?.find(role => role.code === user.userRoleCode)
                      ?.name || 'Select...',
                  value: user.userRoleCode
                }}
                onChange={onRoleChange}
                options={userRoles?.map(userRole => ({
                  value: userRole.code,
                  label: userRole.name
                }))}
                required
              />
              <span>z.b. Name: Neue Rolle = code: neue-rolle</span>
            </div>
            <div className='form'>
              <Input
                error={error.companyId}
                placeholder='Firma'
                type='text'
                value={{
                  label:
                    allCompanies?.find(company => company.id === user.companyId)
                      ?.name || 'Select...',
                  value: user.companyId
                }}
                onChange={event => onInputChange('companyId', event.value)}
                select={true}
                options={allCompanies?.map(company => ({
                  value: company.id,
                  label: company.name
                }))}
                required
              />
            </div>
            {user.userRoleCode === USERROLES.APPRENTICE ||
            user.userRoleCode === USERROLES.INSTRUCTOR ? (
              <div className='form'>
                <Input
                  error={error.professionId}
                  placeholder='Beruf'
                  type='text'
                  value={{
                    label:
                      professions?.find(
                        profession => profession.id === user.professionId
                      )?.name || 'Select...',
                    value: user.professionId
                  }}
                  onChange={event => onInputChange('professionId', event.value)}
                  select={true}
                  options={professions?.map(profession => ({
                    value: profession.id,
                    label: profession.name
                  }))}
                  required
                />
              </div>
            ) : null}
            {user.userRoleCode === USERROLES.APPRENTICE ? (
              <>
                <div className='form'>
                  <Input
                    error={error.apprenticeshipStart}
                    placeholder='Ausbildungsbeginn'
                    type='date'
                    value={user.apprenticeshipStart}
                    onChange={event =>
                      onInputChange('apprenticeshipStart', event.target.value)
                    }
                    required
                  />
                </div>
              </>
            ) : null}
            <div className='button-group'>
              <Button
                text='Hinzufügen'
                disabled={loading}
                loading={loading}
                className='hero'
                onClick={handleSubmitClicked}
              />
              <Button
                text='Abbrechen'
                disabled={false}
                loading={false}
                className=''
                onClick={() => navigate('/users')}
              />
            </div>
            <span className='required-fields'>* Erforderliche Felder</span>
          </div>
        </div>
      </CheckAuthorization>
    </CheckIfLoggedIn>
  );
}
