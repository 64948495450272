/* eslint-disable unicorn/no-null */

import '../styles/GradesOverview.css';

import { useEffect, useState } from 'react';
import { FaInfo } from 'react-icons/fa';
import { GoPencil } from 'react-icons/go';
import { IoIosAdd, IoMdClose } from 'react-icons/io';
import { useNavigate, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import {
  ApiClient,
  CheckAuthorization,
  CheckIfLoggedIn,
  LoadingIndicator,
  NotFound,
  Row,
  Table
} from '../components';
import USERROLES from '../constants/UserRoles';

export function GradesOverview({ setBreadcrumbLinks }) {
  let navigate = useNavigate();
  let { userId, semesterId, subjectId } = useParams();

  const { addToast } = useToasts();

  const [subject, setSubject] = useState(null);
  const [grades, setGrades] = useState(null);
  const [showPopup, setShowPopup] = useState(null);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    document.title = 'AMP | Noten übersicht';
    Promise.all([
      ApiClient.get(`users/${userId}`),
      ApiClient.get(`users/${userId}/semesters`),
      ApiClient.get(
        `users/${userId}/semesters/${semesterId}/subjects/${subjectId}`
      )
    ])
      .then(([user, { semesters }, subject]) => {
        const semester = semesters.find(semester => semester.id == semesterId);
        setBreadcrumbLinks([
          {
            id: 1,
            label: `${user?.firstname} ${user?.lastname}`,
            path: `/users/${userId}/semesters`
          },
          {
            id: 2,
            label: 'Alle Semester',
            path: `/users/${userId}/semesters`
          },
          {
            id: 3,
            label: semester?.index,
            path: `/users/${userId}/semesters/${semesterId}/subjects`
          },
          {
            id: 4,
            label: 'Fächer',
            path: `/users/${userId}/semesters/${semesterId}/subjects`
          },
          {
            id: 5,
            label: subject?.subject?.name,
            // eslint-disable-next-line max-len
            path: `/users/${userId}/semesters/${semesterId}/subjects/${subjectId}/grades`
          }
        ]);
        setSubject(subject);
      })
      .catch(error => {
        if (error?.status === 404) setNotFound(true);
        else addToast('Etwas ist schief gelaufen!', { appearance: 'error' });
      });
    ApiClient.get(`grades?subject_id=${subjectId}`)
      .then(grades => setGrades(grades))
      .catch(() =>
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [semesterId, subjectId]);

  return (
    <CheckIfLoggedIn>
      {notFound ? (
        <NotFound setBreadcrumbLinks={setBreadcrumbLinks} />
      ) : (
        <div className='subjectOverview container'>
          {showPopup ? (
            <div className='grade-popup' onClick={() => setShowPopup(false)}>
              <div
                className='popup-content'
                onClick={event => event.stopPropagation()}
              >
                <div className='close-x'>
                  <IoMdClose onClick={() => setShowPopup(false)} />
                </div>
                <div className='details-row'>
                  <div className='detail-info'>
                    <label className='detail-title'>Note</label>
                    <label>{showPopup.grade}</label>
                  </div>
                  <div className='detail-info'>
                    <label className='detail-title'>Gewichtung</label>
                    <label>{showPopup.weight}</label>
                  </div>
                </div>
                <div className='details-row'>
                  <div className='detail-info'>
                    <label className='detail-title'>Geschrieben am</label>
                    <label>
                      {new Date(showPopup.written_at).toLocaleDateString()}
                    </label>
                  </div>
                  <div className='detail-info'>
                    <label className='detail-title'>Hinzugefügt am</label>
                    <label>
                      {new Date(showPopup.added_at).toLocaleDateString()}
                    </label>
                  </div>
                </div>
                <div className='details-row'>
                  <div className='detail-info'>
                    <p className='detail-title'>Beschreibung</p>
                    <p className='popup-description'>{showPopup.description}</p>
                  </div>
                  <div className='detail-info'>
                    <label className='detail-title'>Rechtfertigung</label>
                    <p className='popup-description'>
                      {showPopup.justification}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className='content'>
            {subject ? (
              <>
                <h1 className='title'>
                  {subject.subject?.name}{' '}
                  <CheckAuthorization
                    roles={[
                      USERROLES.ADMIN,
                      USERROLES.APPRENTICE_LEADER,
                      USERROLES.INSTRUCTOR,
                      USERROLES.APPRENTICE
                    ]}
                    shouldNavigate={false}
                  >
                    <IoIosAdd
                      onClick={() =>
                        navigate(
                          // eslint-disable-next-line max-len
                          `/users/${userId}/semesters/${semesterId}/subjects/${subjectId}/grades/create`
                        )
                      }
                      className='title-icon'
                    />
                  </CheckAuthorization>
                </h1>
                {grades ? (
                  Array.isArray(grades) && grades.length > 0 ? (
                    <Table
                      thead={['Note', 'Gewichtung', 'Geschrieben am', '', '']}
                    >
                      {grades.map(grade => (
                        <Row
                          key={grade.id}
                          data={[
                            grade.grade,
                            grade.weight,
                            grade.written_at,
                            <CheckAuthorization
                              roles={[
                                USERROLES.ADMIN,
                                USERROLES.APPRENTICE_LEADER,
                                USERROLES.INSTRUCTOR,
                                USERROLES.APPRENTICE
                              ]}
                              key={grade.id}
                              shouldNavigate={false}
                            >
                              <GoPencil
                                onClick={() => navigate(`${grade.id}/edit`)}
                                style={{ cursor: 'pointer' }}
                              />
                            </CheckAuthorization>,
                            <FaInfo
                              key={grade.description}
                              onClick={() => setShowPopup(grade)}
                              style={{ cursor: 'pointer' }}
                            />
                          ]}
                        />
                      ))}
                    </Table>
                  ) : (
                    <h2>Keine Noten vorhanden!</h2>
                  )
                ) : (
                  <LoadingIndicator height={25} width={25} mode='darker' />
                )}
                {subject?.average ? (
                  <h3 className='grades-average'>
                    Durchschnitt:{' '}
                    {(Math.round(subject?.average * 1000) / 1000).toFixed(3)}
                  </h3>
                ) : null}
              </>
            ) : (
              <LoadingIndicator height={25} width={25} mode='darker' />
            )}
          </div>
        </div>
      )}
    </CheckIfLoggedIn>
  );
}
