import { useEffect, useState } from 'react';
/* import { GoPencil } from 'react-icons/go';*/
import { IoIosAdd } from 'react-icons/io';
import { useNavigate, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import {
  ApiClient,
  CheckAuthorization,
  CheckIfLoggedIn,
  LoadingIndicator,
  Row,
  Table
} from '../components';
import USERROLES from '../constants/UserRoles';

export function LearningGoalOverview({ setBreadcrumbLinks }) {
  const [learningGoals, setLearningGoals] = useState();
  const { addToast } = useToasts();
  let { professionId } = useParams();
  const navigate = useNavigate();

  console.log(professionId);

  useEffect(() => {
    setBreadcrumbLinks([
      {
        id: 1,
        label: 'Berufe anzeigen',
        path: '/professions'
      },
      {
        id: 2,
        label: professionId,
        path: '/professions'
      },
      {
        id: 3,
        label: 'Lernziele anzeigen',
        path: '/learning_goals'
      }
    ]);

    ApiClient.get(`professions/${professionId}/learning_goals`)
      .then(learningGoals => {
        setLearningGoals(learningGoals);
      })
      .catch(() =>
        addToast('Etwas ist schief gelaufen', { appearance: 'error' })
      );
  }, [addToast, professionId, setBreadcrumbLinks]);

  return (
    <CheckIfLoggedIn>
      <div className='learningGoalsOverview container'>
        <div className='content'>
          <h1 className='title'>
            Alle Lernziele
            <CheckAuthorization
              roles={[
                USERROLES.ADMIN,
                USERROLES.APPRENTICE_LEADER,
                USERROLES.INSTRUCTOR
              ]}
              shouldNavigate={true}
              path='/403'
            >
              <span className='icons'>
                {/* <GoPencil onClick={() => navigate('edit')} /> */}
                <IoIosAdd onClick={() => navigate('add')} />
              </span>
            </CheckAuthorization>
          </h1>

          <Table leftAlign>
            <Row key='thead' data={['Name', 'Beschreibung']} />
            {learningGoals ? (
              Array.isArray(learningGoals) && learningGoals.length > 0 ? (
                learningGoals
                  .sort(function (a, b) {
                    return (
                      (a.name === '') - (b.name === '') ||
                      +(a.name > b.name) ||
                      -(a.name < b.name)
                    );
                  })
                  .map(learningGoal => (
                    <Row
                      key='tbody'
                      data={[learningGoal.name, learningGoal.description]}
                    />
                  ))
              ) : (
                <h2>Keine Lernziele vorhanden</h2>
              )
            ) : (
              <LoadingIndicator height={25} width={25} mode='darker' />
            )}
          </Table>
        </div>
      </div>
    </CheckIfLoggedIn>
  );
}
