import IconButton from '../IconButton';

export default function BreadcrumbHomeIcon({ option, active, onClick }) {
  return (
    <li className='breadcrumb-icon'>
      <IconButton
        className={`${active ? '' : 'breadcrumb-icon-active'}`}
        icon='home'
        onClick={() => {
          onClick(option);
        }}
      />
    </li>
  );
}
