import { StyleSheet, Text, View } from '@react-pdf/renderer';

const borderColor = '#C3CED5';
const styles = StyleSheet.create({
  row: {
    marginTop: -30,
    flexDirection: 'row',
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 'auto',
    width: 'auto',
    fontStyle: 'bold'
  },
  description: {
    width: '45%',
    fontSize: 12,
    textAlign: 'left',
    padding: 5
  },
  rate: {
    width: '8%',
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    fontSize: 12,
    textAlign: 'center',
    padding: 5
  },
  rate2: {
    width: '8%',
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderRightColor: borderColor,
    fontSize: 12,
    textAlign: 'center',
    padding: 5
  },
  statement: {
    width: '40%',
    textAlign: 'left',
    fontSize: 12,
    padding: 5
  }
});

export const TableRow = ({ ratingPoints }) => {
  return (
    <View style={styles.row} key='1'>
      <Text style={styles.description}>
        {ratingPoints.title + '\r\n' + ratingPoints.description}
      </Text>
      <Text style={styles.rate}>{ratingPoints.instructor_rating?.rating}</Text>
      <Text style={styles.rate2}>{ratingPoints.self_rating?.rating}</Text>
      <Text style={styles.statement}>
        {ratingPoints.instructor_rating?.remark
          ? ratingPoints.instructor_rating?.remark
          : ' '}
      </Text>
    </View>
  );
};
