/* eslint-disable unicorn/filename-case */

export function containsInstructorUnsigned(categoriesWithRatingPoints) {
  return ratingPointsIncludes(
    categoriesWithRatingPoints,
    'instructor_signed',
    0
  );
}
export function containsSelfUnsigned(categoriesWithRatingPoints) {
  return ratingPointsIncludes(categoriesWithRatingPoints, 'self_signed', 0);
}

export function ratingPointsIncludes(
  categoriesWithRatingPoints,
  property,
  expectedIncludes
) {
  return categoriesWithRatingPoints
    ?.flatMap(category =>
      // eslint-disable-next-line security/detect-object-injection
      category.rating_points.map(ratingPoint => ratingPoint[property])
    )
    .includes(expectedIncludes);
}

export function categorizeRatingPoints(categories, ratingPoints) {
  const ratingPointsSorted = ratingPoints.sort(
    (a, b) => a.category_id - b.category_id
  );
  let categoriesFiltered = categories.filter(category =>
    ratingPointsSorted
      .map(ratingPoint => ratingPoint.category_id)
      .includes(category.id)
  );
  for (const category of categoriesFiltered) {
    const ratingPointsOfCategory = ratingPointsSorted.filter(
      ratingPoint => ratingPoint.category_id === category.id
    );
    if (ratingPointsOfCategory.length > 0) {
      category['rating_points'] = ratingPointsOfCategory;
    }
  }
  return categoriesFiltered;
}
