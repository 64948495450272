import '../styles/AssessmentPointsOverview.css';

import { useEffect, useState } from 'react';
import { IoIosAdd } from 'react-icons/io';
import { useNavigate, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import {
  ApiClient,
  Button,
  CheckAuthorization,
  CheckIfLoggedIn,
  LoadingIndicator,
  Row,
  // Row,
  Table
} from '../components';
import USERROLES from '../constants/UserRoles';

export function AssessmentPointsSignOverview({ setBreadcrumbLinks }) {
  const { userId, semesterId } = useParams();
  const { addToast } = useToasts();
  const [assessmentPoints, setAssessmentPoints] = useState();
  const [assessmentGenerationLoading, setAssessmentGenerationLoading] =
    useState(false);
  const [semester, setSemester] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    Promise.all([
      ApiClient.get(`users/${userId}/semesters/${semesterId}`),
      ApiClient.get(
        `users/${userId}/semesters/${semesterId}/assessment_points`
      ),
      ApiClient.get(`users/${userId}`)
    ])
      .then(([semester, { assessment_points }, user]) => {
        setBreadcrumbLinks([
          {
            id: 1,
            label: `${user?.firstname} ${user?.lastname}`,
            path: `/users/${userId}/semesters`
          },
          {
            id: 2,
            label: 'Alle Semester',
            path: `/users/${userId}/semesters`
          },
          {
            id: 3,
            label: semester?.index,
            path: `/users/${userId}/semesters/${semester.id}/subjects`
          },
          {
            id: 4,
            label: 'Beurteilungspunkte',
            path: `/users/${userId}/semesters/${semester.id}/assessment_points`
          }
        ]);

        setSemester(semester);

        let final = [];

        // eslint-disable-next-line unicorn/no-array-for-each
        assessment_points.forEach(point => {
          if (final.some(p => p.category === point.category.name)) {
            final
              .find(p => p.category === point.category.name)
              .assessment_points.push(point);
          } else {
            final.push({
              category: point.category.name,
              assessment_points: []
            });
            final
              .find(p => p.category === point.category.name)
              .assessment_points.push(point);
          }
        });

        setAssessmentPoints([...final]);
      })
      .catch(() =>
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
      );
  }, [addToast, semesterId, setBreadcrumbLinks, userId]);

  useEffect(() => {
    if (semester?.ratings_fixed) {
      navigate(`/users/${userId}/semesters/${semesterId}/assessment`);
    }
  }, [navigate, semester, semesterId, userId]);

  function generateAssessment() {
    setAssessmentGenerationLoading(true);
    ApiClient.patch(`users/${userId}/semesters/${semesterId}`, {
      ratings_fixed: true
    })
      .then(() =>
        navigate(`/users/${userId}/semesters/${semesterId}/assessment`)
      )
      .catch(() =>
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
      )
      .finally(() => setAssessmentGenerationLoading(false));
  }

  return (
    <CheckIfLoggedIn>
      <div className='subjectOverview container'>
        <div className='content'>
          <CheckAuthorization
            roles={[
              USERROLES.ADMIN,
              USERROLES.APPRENTICE_LEADER,
              USERROLES.INSTRUCTOR
            ]}
          >
            {semester?.ratings_fixed ? undefined : (
              <div className='right-align'>
                <Button
                  onClick={generateAssessment}
                  loading={assessmentGenerationLoading}
                  disabled={assessmentGenerationLoading}
                  text='Beurteilung fixieren'
                />
              </div>
            )}
          </CheckAuthorization>
          <h1 className='title'>
            Alle Beurteilungspunkte für{' '}
            {semester?.user?.firstname + ' ' + semester?.user?.lastname} in
            Semester {semester?.index}
            <CheckAuthorization
              roles={[
                USERROLES.ADMIN,
                USERROLES.APPRENTICE_LEADER,
                USERROLES.INSTRUCTOR
              ]}
            >
              <IoIosAdd
                onClick={() => navigate('/assessment_points/create')}
                className='title-icon'
              />
            </CheckAuthorization>
          </h1>
          {assessmentPoints ? (
            Array.isArray(assessmentPoints) && assessmentPoints.length > 0 ? (
              <Table thead={['Titel', 'Beschreibung']} leftAlign>
                {assessmentPoints.map(({ category, assessment_points }) => (
                  <>
                    <Row
                      key={category}
                      className='category-title-overview'
                      data={[category, '']}
                    />
                    {assessment_points.map(assessmentPoint => (
                      <Row
                        key={assessmentPoint.id}
                        data={[
                          assessmentPoint.title,
                          assessmentPoint.description
                        ]}
                      />
                    ))}
                  </>
                ))}
              </Table>
            ) : (
              <h2>Keine Beurteilungspunkte vorhanden!</h2>
            )
          ) : (
            <LoadingIndicator height={25} width={25} mode='darker' />
          )}
        </div>
      </div>
    </CheckIfLoggedIn>
  );
}
