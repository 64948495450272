/* eslint-disable react/display-name */
import { forwardRef } from 'react';

const Clickable = forwardRef(
  ({ target, onClick, disabled, children, style, className }, reference) => {
    if (target && !disabled) {
      // eslint-disable-next-line unicorn/prevent-abbreviations
      const handleOnClick = e => {
        if (onClick) {
          onClick();
          e.preventDefault();
        }
      };
      return (
        <a
          className={[className, 'clickable'].filter(c => c).join(' ')}
          href={target}
          style={style}
          ref={reference}
          onClick={handleOnClick}
        >
          {children}
        </a>
      );
    }
    if (onClick) {
      return (
        <button
          className={[className, 'clickable'].filter(c => c).join(' ')}
          type='button'
          onClick={onClick}
          style={style}
          ref={reference}
          disabled={disabled}
        >
          {children}
        </button>
      );
    }
    return children;
  }
);

export default Clickable;
