/* eslint-disable indent */
import { useEffect, useState } from 'react';
import { IoIosAdd } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import {
  ApiClient,
  CheckAuthorization,
  CheckIfLoggedIn,
  Input,
  LoadingIndicator,
  Row,
  Table
} from '../components';
import USERROLES from '../constants/UserRoles';

export function SubjectsOverview({ setBreadcrumbLinks }) {
  const { addToast } = useToasts();
  const [subjects, setSemesters] = useState();
  const [inputSubject, setInputSubject] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    setBreadcrumbLinks([
      {
        id: 1,
        label: 'Fächer anzeigen',
        path: '/subjects'
      }
    ]);
    ApiClient.get('subjects')
      .then(({ subjects }) => setSemesters(subjects))
      .catch(() =>
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
      );
  }, [addToast, setBreadcrumbLinks]);

  return (
    <CheckIfLoggedIn>
      <CheckAuthorization
        roles={[
          USERROLES.ADMIN,
          USERROLES.APPRENTICE_LEADER,
          USERROLES.INSTRUCTOR
        ]}
        shouldNavigate
        path='/404'
      >
        <div className='subjectOverview container'>
          <div className='content'>
            <h1 className='title'>
              Alle Fächer
              <IoIosAdd
                onClick={() => navigate('create')}
                className='title-icon'
              />
            </h1>
            <Table leftAlign>
              <Row
                key={'thead'}
                data={[
                  <Input
                    key='name'
                    type='text'
                    id='inputText'
                    name='inputText'
                    placeholder='Fach'
                    value={inputSubject}
                    onChange={event => setInputSubject(event.target.value)}
                  />
                ]}
              />
            </Table>
            {subjects ? (
              Array.isArray(subjects) && subjects.length > 0 ? (
                <Table leftAlign>
                  {subjects
                    .filter(subject =>
                      inputSubject
                        ? subject?.name
                            .toLowerCase()
                            .includes(inputSubject?.toLowerCase())
                        : subject
                    )
                    .map(subject => (
                      <Row key={subject.id} data={[subject.name]} />
                    ))}
                </Table>
              ) : (
                <h2>Keine Fächer vorhanden!</h2>
              )
            ) : (
              <LoadingIndicator height={25} width={25} mode='darker' />
            )}
          </div>
        </div>
      </CheckAuthorization>
    </CheckIfLoggedIn>
  );
}
