import '../styles/Apprentices.css';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import {
  ApiClient,
  CheckAuthorization,
  CheckIfLoggedIn,
  Input,
  LoadingIndicator,
  Row,
  Table
} from '../components';
import USERROLES from '../constants/UserRoles';

export function ApprenticeGoalOverview({ setBreadcrumbLinks }) {
  const { userId } = useParams();
  const [learningGoals, setLearningGoals] = useState();
  const [user, setUser] = useState();
  const { addToast } = useToasts();

  useEffect(() => {
    setBreadcrumbLinks([
      {
        id: 1,
        label: 'Lernziele anzeigen',
        path: '/learning_goals'
      }
    ]);

    Promise.all([
      ApiClient.get(`users/${userId}/learning_goals`),
      ApiClient.get(`users/${userId}`)
    ])
      .then(([learningGoals, user]) => {
        setUser(user);
        setLearningGoals(learningGoals);
      })
      .catch(() =>
        addToast('Ein Fehler ist aufgetreten', { message: 'error' })
      );
  }, [addToast, setBreadcrumbLinks, userId, setLearningGoals]);

  const handleCheckboxClicked = (goalId, checked) => {
    console.log(goalId);
    ApiClient.patch(`/users/${userId}/learning_goals/${goalId}/status`, {
      goalId,
      checked
    })
      .then(() =>
        addToast('Trigger erfolgreich aktualisiert', { appearance: 'success' })
      )
      .catch(() =>
        addToast('Etwas ist schiefgelaufen', { appearance: 'error' })
      );
  };

  return (
    <CheckIfLoggedIn>
      <CheckAuthorization
        roles={[
          USERROLES.ADMIN,
          USERROLES.APPRENTICE_LEADER,
          USERROLES.INSTRUCTOR,
          USERROLES.APPRENTICE
        ]}
        shouldNavigate={false}
      />
      <div className='apprenticeGoal container'>
        <div className='content'>
          {/*Handlungskompetenzen / Actionskills */}
          {/*  <h1 className='title'>
            Alle Handlungskompetenzen für {user?.firstname} {user?.lastname}
          </h1>
          {learningGoals && Array.isArray(learningGoals) ? (
            <>
              <h3>
                Handlungskompetenz a3: Informationen zu ICT-Lösungen und zu
                Innovationen recherchieren
              </h3>
              <p>
                Informatikerinnen und Informatiker suchen gezielt Informationen
                <br />
              </p>
              <Table leftAlign>
                {learningGoals?.map(goal => (
                  <Row
                    key={goal.id}
                    data={[
                      goal.learningGoalVersion.goal_name,
                      goal.learningGoalVersion.description,
                      <Input
                        key={'checkbox'}
                        type='checkbox'
                        checked={goal.status}
                        onChange={() => {
                          goal.status = !goal.status;
                          console.log(learningGoals);
                          handleCheckboxClicked(goal.id, goal.status);
                        }}
                      />
                    ]}
                  />
                ))}
              </Table>
            </>
          ) : (
            <LoadingIndicator height={25} width={25} mode='darker' />
          )} */}

          <h1 className='title'>
            Alle Lernziele für {user?.firstname} {user?.lastname}
          </h1>
          {learningGoals && Array.isArray(learningGoals) ? (
            <Table leftAlign>
              <Row key='thead' data={['Name', 'Beschreibung', 'Erfüllt?']} />
              {learningGoals?.map(goal => (
                <Row
                  key={goal.id}
                  data={[
                    goal.learningGoalVersion.goal_name,
                    goal.learningGoalVersion.description,
                    <Input
                      key={goal.id}
                      type='checkbox'
                      checked={goal.status}
                      onChange={() => {
                        goal.status = !goal.status;
                        console.log(learningGoals);
                        handleCheckboxClicked(goal.id, goal.status);
                      }}
                    />
                  ]}
                />
              ))}
            </Table>
          ) : (
            <LoadingIndicator height={25} width={25} mode='darker' />
          )}
        </div>
      </div>
    </CheckIfLoggedIn>
  );
}
